import { ChangeEvent, FormEvent, useState, useMemo, useEffect } from 'react'
import { v4 as uuid } from 'uuid'
import { TiFolderAdd } from 'react-icons/ti'
import { HiOutlineAnnotation } from 'react-icons/hi'
import { AiOutlinePlus } from 'react-icons/ai'
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd'

import {
  TReport,
  TReportFilter,
  TReportFilterData,
  TReportConditionData,
  TReportFormData,
  TReportBodyData,
  TReportFilterDisplay
} from 'services/report.interface'
import { REPORT_FILTER_TYPE } from 'constants/report.constants'
import {
  FETCH_STATUS,
  Backdrop,
  Spinner,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from 'components'
import useReport from 'hooks/useReport'
import FilterCondition from './FilterCondition'
import {
  generateEmptyFilter,
  generateEmptyCondition,
  updateConditionList,
  updateFilterList,
  reorderConditionList
} from '../report.utils'

interface MutationNewReportModalProps {
  isOpen: boolean
  data?: TReportFilterDisplay
  onMutated: (reportFilter: TReportFilter, reports: TReport[]) => void
  onClose: () => void
}

const defaultFormData: TReportFormData = {
  name: '',
  description: '',
  filterList: []
}

function MutationNewReportModal({
  isOpen,
  data,
  onMutated,
  onClose
}: MutationNewReportModalProps) {
  const [createReportFilterStatus, setCreateReportFilterStatus] = useState(
    FETCH_STATUS.SUCCESS
  )
  const [formData, setFormData] = useState<TReportFormData>(defaultFormData)

  const { createReportFilter } = useReport()

  const isValidForm = useMemo(() => {
    if (!formData.name) return false
    let isValid = true
    let hasCondition = false
    formData.filterList.forEach((filter) => {
      filter.conditions.forEach((condition) => {
        hasCondition = true
        if (!condition.field || !condition.operator || !condition.value) {
          isValid = false
        }
      })
    })
    return isValid && hasCondition
  }, [formData])

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    setFormData({ ...formData, [name]: value })
  }

  const handleAddFilter = (type: TReportFilterData['type']) => {
    const isExisted = formData.filterList.some((filter) => filter.type === type)
    if (isExisted) return

    const filters = [
      ...formData.filterList,
      { ...generateEmptyFilter(type), type }
    ]
    setFormData({ ...formData, filterList: filters })
  }

  const handleAddCondition = (filter: TReportFilterData) => {
    if (!filter) return
    const conditions = [
      ...filter.conditions,
      { ...generateEmptyCondition(filter.type) }
    ]
    const filters = updateFilterList(formData.filterList, {
      ...filter,
      conditions
    })
    setFormData({ ...formData, filterList: filters })
  }

  const handleEditCondition = (
    filter: TReportFilterData,
    editedCondition: TReportConditionData
  ) => {
    if (!filter) return
    const conditions = updateConditionList(filter.conditions, editedCondition)
    const filters = updateFilterList(formData.filterList, {
      ...filter,
      conditions
    })
    setFormData({ ...formData, filterList: filters })
  }

  const handleDeleteCondtion = (
    filter: TReportFilterData,
    deletedCondition: TReportConditionData
  ) => {
    if (!filter) return
    const conditions = filter.conditions.filter(
      ({ id }) => id !== deletedCondition.id
    )
    const filters = updateFilterList(formData.filterList, {
      ...filter,
      conditions
    })
    setFormData({ ...formData, filterList: filters })
  }

  const handleDragEnd = (filterId: string, result: DropResult) => {
    const filter = formData.filterList.find((filter) => filter.id === filterId)
    if (!filter) return

    const conditions = reorderConditionList(filter.conditions, result)
    const filters = updateFilterList(formData.filterList, {
      ...filter,
      conditions
    })
    setFormData({ ...formData, filterList: filters })
  }

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const payload: TReportBodyData = {
      name: formData.name,
      description: formData.description,
      filters: []
    }
    formData.filterList.forEach((filter) => {
      filter.conditions.forEach((condition) => {
        const payloadFilter = {
          field: condition.field,
          value: condition.value,
          operator: condition.operator
        }

        if (filter.type !== REPORT_FILTER_TYPE.DEFAULT) {
          payloadFilter.field = `${filter.type}.${condition.field}`
        }
        payload.filters.push(payloadFilter)
      })
    })
    createReportFilter(payload, setCreateReportFilterStatus).then((reports) => {
      const { name, description } = formData
      onMutated({ name, description, filters: payload.filters }, reports)
      onClose()
    })
  }

  // map existing data to [formData]
  useEffect(() => {
    if (!data) return
    const filterList: TReportFilterData[] = []
    const defaultConditions: TReportConditionData[] = []
    const labelConditions: TReportConditionData[] = []
    const annotationConditions: TReportConditionData[] = []

    data.filters?.forEach((filter) => {
      if (filter.field.startsWith('label.')) {
        labelConditions.push({
          ...filter,
          id: uuid(),
          field: filter.field?.replace('label.', '') || ''
        })
      } else if (filter.field.startsWith('annotation.')) {
        annotationConditions.push({
          ...filter,
          id: uuid(),
          field: filter.field?.replace('annotation.', '') || ''
        })
      } else {
        defaultConditions.push({ ...filter, id: uuid() })
      }
    })

    if (defaultConditions.length) {
      filterList.push({
        ...generateEmptyFilter('default'),
        conditions: defaultConditions
      })
    }

    if (labelConditions.length) {
      filterList.push({
        ...generateEmptyFilter('label'),
        conditions: labelConditions
      })
    }

    if (annotationConditions.length) {
      filterList.push({
        ...generateEmptyFilter('annotation'),
        conditions: annotationConditions
      })
    }

    setFormData({
      name: data.name,
      description: data.description || '',
      filterList
    })
  }, [data])

  return (
    <Modal isOpen={isOpen} onClose={onClose} disableBackdropClick>
      <ModalHeader onClose={onClose}>
        {data ? 'Update' : 'Create'} new report
      </ModalHeader>
      <form onSubmit={handleSubmit}>
        <ModalBody className="px-0 py-0 w-[80vw]">
          <div className="flex gap-6 px-5 pb-4 border-b">
            <Input
              type="text"
              name="name"
              label="Name"
              value={formData.name}
              onChange={handleChange}
            />
            <Input
              type="text"
              name="description"
              label="Description"
              value={formData.description}
              onChange={handleChange}
            />
          </div>

          <div className="h-[calc(100vh-380px)] overflow-y-auto">
            <div className="flex flex-col gap-6 p-5">
              {formData.filterList.map((filter) => (
                <div
                  key={filter.id}
                  className="relative flex flex-col gap-2 p-4 pt-2 border border-dark-grey rounded-md"
                >
                  {filter.type !== REPORT_FILTER_TYPE.DEFAULT && (
                    <div className="absolute -top-3.5 left-3 bg-white px-2 max-w-[calc(100%-24px)]">
                      <span>
                        {filter.type === REPORT_FILTER_TYPE.LABEL
                          ? 'Labels'
                          : 'Annotations'}
                      </span>
                    </div>
                  )}
                  <DragDropContext
                    onDragEnd={(result) => handleDragEnd(filter.id, result)}
                  >
                    <Droppable droppableId={filter.id}>
                      {({ innerRef, droppableProps, placeholder }) => (
                        <div ref={innerRef} {...droppableProps}>
                          {filter.conditions.map((condition, index) => (
                            <FilterCondition
                              key={condition.id}
                              index={index}
                              type={filter.type}
                              condition={condition}
                              onChange={(newCondition) => {
                                handleEditCondition(filter, newCondition)
                              }}
                              onDelete={(newCondition) => {
                                handleDeleteCondtion(filter, newCondition)
                              }}
                            />
                          ))}
                          {placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>

                  <div className="flex justify-between gap-2 text-primary-text-color">
                    <button
                      type="button"
                      className="flex gap-1 items-center cursor-pointer hover:bg-primary-color hover:bg-opacity-10 rounded-lg px-2 py-1"
                      onClick={() => handleAddCondition(filter)}
                    >
                      <AiOutlinePlus />
                      <span>Add</span>
                      <span>
                        {filter.type === REPORT_FILTER_TYPE.DEFAULT
                          ? 'condition'
                          : filter.type}
                      </span>
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="flex flex-col sm:flex-row gap-4 px-5 py-3 text-primary-text-color">
            <button
              type="button"
              className="flex gap-1 items-center cursor-pointer hover:bg-primary-color hover:bg-opacity-10 disabled:bg-transparent disabled:text-gray-300 disabled:cursor-not-allowed rounded-lg px-2 py-1"
              disabled={formData.filterList.some(
                (filter) => filter.type === REPORT_FILTER_TYPE.DEFAULT
              )}
              onClick={() => handleAddFilter(REPORT_FILTER_TYPE.DEFAULT)}
            >
              <TiFolderAdd />
              <span>Add condition filter</span>
            </button>

            <button
              type="button"
              className="flex gap-1 items-center cursor-pointer hover:bg-primary-color hover:bg-opacity-10 disabled:bg-transparent disabled:text-gray-300 disabled:cursor-not-allowed rounded-lg px-2 py-1"
              disabled={formData.filterList.some(
                (filter) => filter.type === REPORT_FILTER_TYPE.LABEL
              )}
              onClick={() => handleAddFilter(REPORT_FILTER_TYPE.LABEL)}
            >
              <TiFolderAdd />
              <span>Add label filter</span>
            </button>

            <button
              type="button"
              className="flex gap-1 items-center cursor-pointer hover:bg-primary-color hover:bg-opacity-10 disabled:bg-transparent disabled:text-gray-300 disabled:cursor-not-allowed rounded-lg px-2 py-1"
              disabled={formData.filterList.some(
                (filter) => filter.type === REPORT_FILTER_TYPE.ANNOTATION
              )}
              onClick={() => handleAddFilter(REPORT_FILTER_TYPE.ANNOTATION)}
            >
              <HiOutlineAnnotation />
              <span>Add annotation filter</span>
            </button>
          </div>
        </ModalBody>
        <ModalFooter className="gap-4">
          <button
            type="button"
            className="rounded-lg hover:bg-red-500 hover:bg-opacity-10 py-1 px-4 text-red-500"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            type="submit"
            disabled={!isValidForm}
            className="bg-primary-color hover:bg-blue-500 disabled:bg-gray-300 text-white rounded-md py-1 px-4"
          >
            {data ? 'Update' : 'Create'}
          </button>
        </ModalFooter>
      </form>

      <Backdrop isLoading={createReportFilterStatus.isLoading}>
        <Spinner size={40} />
      </Backdrop>
    </Modal>
  )
}

export default MutationNewReportModal
