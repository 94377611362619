import { TProjectEntityNames } from 'services/project.interface'

export const PROJECT_ENTITY_NAMES: Record<
  'PROJECT' | 'APPLICATION' | 'COMPONENT',
  TProjectEntityNames
> = {
  PROJECT: 'project',
  APPLICATION: 'application',
  COMPONENT: 'component'
}
