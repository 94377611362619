import { ReactNode } from 'react'
import { mapClasses } from 'utils'

interface ModalFooterProps {
  className?: string
  children: ReactNode
}

function ModalFooter({ className, children }: ModalFooterProps) {
  return (
    <div
      className={mapClasses('px-5 py-3 flex justify-end bg-gray-100', className)}
    >
      {children}
    </div>
  )
}

export default ModalFooter
