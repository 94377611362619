import { v4 as uuid } from 'uuid'
import { DropResult } from 'react-beautiful-dnd'

import {
  TReportFilterData,
  TReportConditionData
} from 'services/report.interface'
import {
  FIELD_OPTIONS,
  OPERATOR_OPTIONS,
  REPORT_FILTER_TYPE
} from 'constants/report.constants'

export const updateConditionList = (
  conditionList: TReportConditionData[],
  updatedCondition: TReportConditionData
) => {
  const conditionIndex = conditionList.findIndex(
    ({ id }) => id === updatedCondition.id
  )
  if (conditionIndex < 0) return conditionList

  return [
    ...conditionList.slice(0, conditionIndex),
    updatedCondition,
    ...conditionList.slice(conditionIndex + 1)
  ]
}

export const updateFilterList = (
  filterList: TReportFilterData[],
  updatedFilter: TReportFilterData
) => {
  const filterIndex = filterList.findIndex(
    (filter) => filter.id === updatedFilter.id
  )
  const conditionLength = updatedFilter.conditions.length
  if (!conditionLength && updatedFilter.type !== REPORT_FILTER_TYPE.DEFAULT) {
    // delete filter when this filter has no condition
    return [
      ...filterList.slice(0, filterIndex),
      ...filterList.slice(filterIndex + 1)
    ]
  }
  return [
    ...filterList.slice(0, filterIndex),
    { ...updatedFilter },
    ...filterList.slice(filterIndex + 1)
  ]
}

export const reorderConditionList = (
  conditionList: TReportConditionData[],
  { source, destination }: DropResult
) => {
  const sourceCondition = conditionList.find(
    (_, index) => index === source.index
  )
  if (!destination || !sourceCondition) return conditionList

  const filteredConditionList = conditionList.filter(
    (_, index) => index !== source.index
  )
  return [
    ...filteredConditionList.slice(0, destination.index),
    sourceCondition,
    ...filteredConditionList.slice(destination.index)
  ]
}

export const generateEmptyCondition = (
  type: TReportFilterData['type']
): TReportConditionData => ({
  id: uuid(),
  field: type === REPORT_FILTER_TYPE.DEFAULT ? FIELD_OPTIONS[0].value : '',
  operator: OPERATOR_OPTIONS[0].value,
  value: ''
})

export const generateEmptyFilter = (
  type: TReportFilterData['type'] = REPORT_FILTER_TYPE.DEFAULT
): TReportFilterData => ({
  id: type,
  type,
  conditions: [generateEmptyCondition(type)]
})
