import { ChangeEvent, FormEvent, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import _ from 'lodash'

import { TProjectFormData } from 'services/project.interface'
import {
  GraphCardWrapper,
  SkeletonWrapper,
  FETCH_STATUS,
  Input,
  GraphCardGroup,
  GraphCard,
  Backdrop,
  Spinner
} from 'components'
import useProject from 'hooks/useProject'
import { mapClasses } from 'utils'

function ProjectMutation() {
  const navigate = useNavigate()
  const { initialLoading, fetchProjects, createProject } = useProject()

  const [mutationProjectStatus, setMutationProjectStatus] = useState(
    FETCH_STATUS.SUCCESS
  )
  const [formData, setFormData] = useState<TProjectFormData>({
    name: '',
    cluster: '',
    description: ''
  })

  const isDisabled = useMemo(() => {
    return !formData.name || mutationProjectStatus.isLoading
  }, [formData])

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    setFormData(_.set(_.cloneDeep(formData), name, value))
  }

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setMutationProjectStatus({ isLoading: true, isError: false })

    await createProject(formData)
    await fetchProjects()

    setMutationProjectStatus({ isLoading: false, isError: false })
    navigate(-1)
  }

  return (
    <form className="grow flex flex-col" onSubmit={handleSubmit}>
      <GraphCardWrapper>
        <SkeletonWrapper
          isLoading={initialLoading}
          count={2}
          inline
          height={40}
          className="w-48"
          containerClassName="flex justify-between"
        >
          <div className="flex xs:flex-col desktopMini:flex-row items-start justify-between w-full">
            <p className="text-xl font-medium xs:pb-3">Create new project</p>
            <div className="flex gap-4 xs:justify-end xs:self-end desktopMini:justify-between">
              <button
                disabled={isDisabled}
                type="submit"
                className={mapClasses(
                  'text-white rounded-md py-2.5 px-7 ml-2',
                  isDisabled ? 'bg-gray-200' : 'bg-primary-color'
                )}
              >
                Create
              </button>
              <button
                type="button"
                onClick={() => navigate(-1)}
                className="bg-red-500 text-white rounded-md py-2.5 px-7"
              >
                Cancel
              </button>
            </div>
          </div>
        </SkeletonWrapper>

        <GraphCardGroup>
          <GraphCard isLoading={initialLoading} height={164}>
            <div className="grid grid-cols-2 gap-x-8 gap-y-2">
              <Input
                type="text"
                name="name"
                label="Name*"
                value={formData.name}
                onChange={handleChange}
              />
              <Input
                type="text"
                name="cluster"
                label="Cluster"
                value={formData.cluster}
                onChange={handleChange}
              />
              <Input
                type="text"
                name="description"
                label="Description"
                value={formData.description}
                onChange={handleChange}
              />
            </div>
          </GraphCard>
        </GraphCardGroup>
      </GraphCardWrapper>

      <Backdrop isLoading={mutationProjectStatus.isLoading}>
        <Spinner size={40} />
      </Backdrop>
    </form>
  )
}

export default ProjectMutation
