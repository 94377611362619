import { SpinnerLoader } from 'components';
import useProject from 'hooks/useProject';
import { TCurrency } from 'services/cost.interface';
import { formatCost } from 'utils';

interface TableInfoCardProps {
  currency: TCurrency
  listOfChosentags: string[]
  cost: number
  path: string[]
  id: string
}
function TableInfoCard({
  currency,
  listOfChosentags,
  cost,
  path,
  id
}: TableInfoCardProps) {
  const {
    fetchProjectsStatus,
  } = useProject()

  function toSentence(listOfChosentags: string[]) {
    let str = 'Aggregate cost by:'
    listOfChosentags.forEach((el: string, idx: number) => {
      if (idx === listOfChosentags.length - 1) str += ` and ${el}`
      else if (idx === 0) str += ` ${el}`
      else str += `, ${el}`
    })
    return str
  }
  function selection(path: string[], listOfChosentags: string[]) {
    return path.map((el: string, idx: number) => {
      return <div>Selected {listOfChosentags[idx]}: {el}</div>
    })
  }

  return (
    <div className="h-full w-full overflow-auto px-2">
      <div className="h-full px-2" style={{ display: 'flex', justifyItems: 'center' }}>
        <div
          className="relative h-full grow basis-0 w-full
          border border-dark-grey rounded-md"
        >
          <div data-tour={`tableInfoCardsLeftSideOf${id}`} className="flex flex-col justify-center items-center h-full">
            <SpinnerLoader isLoading={fetchProjectsStatus.isLoading}>
              <div style={{ height: '50px' }}>
                {toSentence(listOfChosentags)}
              </div>
              <div style={{ minHeight: '50px' }}>
                {selection(path, listOfChosentags)}
              </div>
            </SpinnerLoader>
            {/* <p className="text-m font-medium text-gray-800"></p> */}
          </div>
        </div>
        <div
          data-tour={`tableInfoCardsRightSideOf${id}`}
          className="relative grow shrink basis-0 min-w-0 w-full flex flex-col
          border border-dark-grey rounded-md"
        >
          <div className="grow flex flex-col justify-center items-center">
            <SpinnerLoader isLoading={fetchProjectsStatus.isLoading}>
              <p className="relative p-3 top-1 left-3 text-m font-semibold text-gray-800">
                Accumulated monthly cost: {formatCost(cost, { currency })}
              </p>
            </SpinnerLoader>
          </div>
        </div>
      </div>
    </div>
  )
}
export default TableInfoCard
