import { useCallback, useEffect, useMemo, useState } from 'react'

import { TCurrency } from 'services/cost.interface'
import { TProject } from 'services/project.interface'
import { TReport, TReportFilterDisplay } from 'services/report.interface'
import {
  FETCH_STATUS,
  FetchStatus,
  GraphCard,
  GraphCardContent,
  Table,
  TTableData
} from 'components'
import useProject from 'hooks/useProject'
import useReport from 'hooks/useReport'
import { flatProjectEntities } from 'utils'
import { REPORT_TABLE_COLUMNS } from './constants'

interface ReportTableCardProps {
  initialLoading: boolean
  reportFilter: TReportFilterDisplay
  reports: TReport[]
  onReportChange: (reports: TReport[]) => void
  currency: TCurrency
}

function ReportTableCard({
  initialLoading,
  reportFilter,
  reports,
  onReportChange,
  currency
}: ReportTableCardProps) {
  const { projectList, fetchProjectsStatus, setProjectList, fetchProjects } =
    useProject()
  const { getReportsByFilter } = useReport()

  const [fetchStatus, setFetchStatus] = useState(FETCH_STATUS.SUCCESS)

  const formatOptions = useMemo(() => ({ currency }), [currency])

  const isAll = useMemo(() => reportFilter.name === 'All', [reportFilter.name])

  const reportDetailTableData = useMemo<TTableData[]>(() => {
    const flattenProjectEntities = flatProjectEntities(reports)

    return flattenProjectEntities.map(
      ({ project, application, component }) => ({
        project: project?.name,
        application: application?.name,
        component: component?.name,
        dailyCost: component?.cost?.dailyCost,
        weeklyCost: component?.cost?.weeklyCost,
        monthlyCost: component?.cost?.monthlyCost
      })
    )
  }, [reports])

  const fetchReportByFilter = useCallback(async () => {
    const { name, filters, _isFetched } = reportFilter || {}
    if (_isFetched) return
    onReportChange([])
    if (!filters) return

    const newReports = await getReportsByFilter({ name, filters }, setFetchStatus)
    if (isAll) setProjectList(newReports as TProject[])
    onReportChange(newReports)
  }, [isAll, reportFilter, getReportsByFilter, setProjectList])

  useEffect(() => {
    if (isAll && reportFilter?._isFetched) {
      onReportChange(projectList as TReport[])
    }
  }, [isAll, projectList, reportFilter])

  useEffect(() => {
    if (!reportFilter?.filters) return
    fetchReportByFilter()
  }, [reportFilter?.filters, fetchReportByFilter])

  return (
    <GraphCard isOutline={false} isLoading={initialLoading}>
      {!!reportFilter.name && (
        <div className="w-full flex items-center text-lg font-medium text-gray-800">
          <span>{reportFilter.name}</span>
          {!!reportFilter.description && <span className="mx-1">-</span>}
          {!!reportFilter.description && (
            <span>{reportFilter.description}</span>
          )}
        </div>
      )}
      <GraphCardContent className="overflow-x-auto">
        <FetchStatus
          status={
            isAll && reportFilter._isFetched ? fetchProjectsStatus : fetchStatus
          }
          retry={isAll ? fetchProjects : fetchReportByFilter}
        >
          <Table
            columns={REPORT_TABLE_COLUMNS}
            data={reportDetailTableData}
            formatOptions={formatOptions}
          />
        </FetchStatus>
      </GraphCardContent>
    </GraphCard>
  )
}

export default ReportTableCard
