import { useMemo } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { BsTable } from 'react-icons/bs'
// import { MdEdit } from 'react-icons/md'

import { TCurrency } from 'services/cost.interface'
import {
  //  ButtonIcon,
  FetchStatus,
  GraphCard,
  GraphCardContent,
  TTableColumn,
  Table
} from 'components'
import useProject from 'hooks/useProject'
import { APPLICATION_TABLE_COLUMNS } from './constants'

interface ApplicationTableCardProps {
  currency: TCurrency
}

function ApplicationTableCard({ currency }: ApplicationTableCardProps) {
  const { projectId } = useParams()
  const navigate = useNavigate()
  const {
    initialLoading,
    fetchProjectsStatus,
    applicationList,
    fetchProjects
  } = useProject()

  const formatOptions = useMemo(() => ({ currency }), [currency])

  const columns = useMemo<TTableColumn[]>(
    () => [
      ...APPLICATION_TABLE_COLUMNS,
      /* {
        name: '',
        field: 'name',
        width: 60,
        sortable: false,
        filterable: false,
        render: (value: string) => (
          <ButtonIcon
            className="p-1.5"
            onClick={(event) => {
              navigate(`/projects/${projectId}/application-edit/${value}`)
              event.stopPropagation()
            }}
          >
            <MdEdit size={16} />
          </ButtonIcon>
        )
      } */
    ],
    []
  )

  const tableData = useMemo(() => {
    if (!applicationList.length) return []
    return applicationList.map((application) => ({
      name: application?.name,
      component: application?.components?.length,
      daily: application?.cost?.dailyCost,
      weekly: application?.cost?.weeklyCost,
      monthly: application?.cost?.monthlyCost,
      link: `/projects/${projectId}/applications/${application?.name}`
    }))
  }, [applicationList])

  return (
    <GraphCard isLoading={initialLoading}>
      <div className="w-full flex items-center">
        <BsTable size={24} />
        <span className="text-lg font-medium text-gray-800 px-4">
          Applications
        </span>
      </div>
      <GraphCardContent className="overflow-x-auto">
        <FetchStatus status={fetchProjectsStatus} retry={fetchProjects}>
          <Table
            columns={columns}
            data={tableData}
            formatOptions={formatOptions}
            onRowClick={(row) => navigate(row.link || '')}
          />
        </FetchStatus>
      </GraphCardContent>
    </GraphCard>
  )
}

export default ApplicationTableCard
