import { TEntityCost } from 'services/commons.interface'
import { TBarChartData } from 'services/chart.interface'

import { CHART_COLORS } from 'constants/color.constants'
import env from 'env'
import { roundNumber } from './formatNumber'

export const mapBarChartData = (
  datalist: {
    name: string
    cost?: TEntityCost
  }[],
  key: 'dailyCost' | 'weeklyCost' | 'monthlyCost',
) => {
  const limit = parseInt(env.REACT_APP_MAX_CHART_ITEMS, 10)
  let colorIndex = 0
  const chartData: TBarChartData[] = []
  let count = 0
  let value = 0
  datalist.forEach((dataItem, index) => {
    if (dataItem.cost?.[key]) {
      if (count < limit) {
        count++
        chartData.push({
          name: dataItem.name,
          value: roundNumber(dataItem.cost[key] || 0),
          color: CHART_COLORS[colorIndex]
        })
        if (index === CHART_COLORS.length - 1) {
          colorIndex = 0
        } else {
          colorIndex++
        }
      } else {
        value += roundNumber(dataItem.cost[key] || 0)
      }
    }
  })
  if (value > 0) {
    colorIndex++
    chartData.push({
      name: 'Other...',
      value: roundNumber(value || 0),
      color: CHART_COLORS[colorIndex]
    })
  }
  return chartData
}
