import { newTFocusGroup, TResource } from 'services/costhistory.interface'
import { TTableColumn } from 'components'
import { PROJECT_TABLE_COLUMNS } from 'pages/projects/constants'
import { COMPONENT_TABLE_COLUMNS as RESOURSE_TABLE } from 'pages/application-details/constants'
import { COMPONENT_TABLE_COLUMNS as RESOURSE_DETAILS } from 'pages/component-details-v2/constants'
import { TCurrency } from 'services/cost.interface'
import { query } from '../project-widget/widgetHelpFunctions'

export type tableDataEl = { label: string, value: any }

function createTableDataEl(label: string, value: any): tableDataEl {
  return { label, value }
}
// ***************************************************************************
// Here the data received that we want to show on the table widget is parsed
// parsed to a version that we can show on the table.
// This is based on the depth of the path, this means that we go through each
// each child until we get to the end of the path. A path can never go deeper
// than the deepest resource.
// the tableData code is somewhat complex so I will explain it in a couple of steps:

// Step 1: If the path is not empty we traverse through the data, each time choosing
// a piece of data's children to go deeper.
// If that piece of data does not have children we initialize the resourceData.
// If the user also chooses to traverse a resource we are at the end of a path
// and we have to show that data

// Step 2: This part is specific to when we reached the end of a path of data ie.
// a resource was chosen.
// It will then prepare for an api call to the chart data.

// Step 3: This case will prepare the data in the case of that we are still traversing
// thorugh the children of the data, we have to do this since resources and and children
// contain different data.

// Step 4: Same as above but when we display all the resources of a child.
// ***************************************************************************

export function tableDataPrepFunction(
  id: string,
  data: newTFocusGroup[],
  path: string[],
  tutorialCallback: () => void,
  setMonthlyCost: (num: number) => void,
  setColumns: (table: TTableColumn[]) => void,
  setResourceDetailsProcessed: (bool: boolean) => void,
  setResourcelayout: (bool: boolean) => void,
  setQueryKey: (str: string) => void,
  setQueryRequest: (queryReq: query) => void,
  currency: TCurrency
) {
  tutorialCallback()
  if (!data.length) return []
  let tempData = data[0]
  let resourceData: TResource[] = []
  let resourseDetails: tableDataEl[] = []
  // Step 1:
  if (path.length !== 0) {
    path.forEach((el) => {
      if (tempData[el] !== undefined && resourceData.length === 0) {
        if (Object.getOwnPropertyNames(tempData[el].children).length !== 0) {
          tempData = tempData[el].children
        } else {
          let theCost = 0
          tempData[el].resources.forEach((el) => {
            theCost += el.monthlyCost
          })
          setMonthlyCost(theCost)
          resourceData = tempData[el].resources
        }
      } else if (resourceData.length !== 0) {
        const idx = resourceData.findIndex((component) => { return component.resourceId === el })
        if (idx !== -1) {
          const component = resourceData[idx]
          setColumns(RESOURSE_DETAILS)
          resourseDetails = [
            createTableDataEl('Resource Id', component.resourceId),
            createTableDataEl('Daily Cost', `${currency.symbol}${Number(component.dailyCost.toFixed(2))}`),
            createTableDataEl('Weekly Cost', `${currency.symbol}${Number(component.weeklyCost.toFixed(2))}`),
            createTableDataEl('Monthly Cost', `${currency.symbol}${Number(component.monthlyCost.toFixed(2))}`),
            createTableDataEl('Billing Account Id', component.billingAccountId),
            createTableDataEl('Service Name', component.serviceName),
            createTableDataEl('Provider', component.provider),
            createTableDataEl('Billing Account Name', component.billingAccountName),
          ]
          resourseDetails.sort((a, b) => {
            return a.label.localeCompare(b.label)
          })
        }
      }
    })
  }
  // Step 2:
  if (resourseDetails.length !== 0) {
    setResourceDetailsProcessed(false)
    setResourcelayout(true)
    setQueryKey(`${resourseDetails.find((el) => el.label === 'Provider')?.value}${id}`)
    setQueryRequest({
      name: 'Query test',
      description: 'Historical cost of a resource by id',
      filters: [
        {
          field: 'provider',
          value: resourseDetails.find((el) => el.label === 'Provider')?.value,
          operator: 'EQUALS'
        },
        {
          field: 'resourceId',
          value: resourseDetails.find((el) => el.label === 'Resource Id')?.value,
          operator: 'EQUALS'
        }
      ],
      options: {
        saveQuery: false,
        accumulate: false,
        window: 'lastmonth',
        step: '1d',
        aggregate: 'resourceId'
      }
    })
    return resourseDetails
  }
  // Step 3:
  if (resourceData.length === 0) {
    setColumns(PROJECT_TABLE_COLUMNS)
    let theCost = 0
    Object.getOwnPropertyNames(tempData).forEach((el) => {
      theCost += tempData[el].monthlyCost
    })
    setMonthlyCost(theCost)
    return Object.getOwnPropertyNames(tempData).map((el) => ({
      name: el,
      daily: tempData[el].dailyCost,
      weekly: tempData[el].weeklyCost,
      monthly: tempData[el].monthlyCost,
      path

    })).sort((a, b) => b.monthly - a.monthly)
  }
  // Step 4:
  setColumns(RESOURSE_TABLE)
  return resourceData.map((el) => ({
    name: el.resourceId,
    daily: el.dailyCost,
    weekly: el.weeklyCost,
    monthly: el.monthlyCost,
    billingAccountId: el.billingAccountId,
    serviceName: el.serviceName,
    provider: el.provider,
    billingAccountName: el.billingAccountName,
    path

  })).sort((a, b) => b.monthly - a.monthly)
}
