import { useMemo } from 'react'

import {
  GraphCard,
  Table,
  TTableColumn,
  GraphCardContent,
  TTableData,
  Spinner,
  GraphCardWrapper,
  GraphCardGroup
} from 'components'
import { formatCost, formatNumber } from 'utils'
import { TCurrency } from 'services/cost.interface'

interface FocusTableCardProps {
  data: TTableData[]
  currency?: TCurrency
  isLoading?: boolean
}

function FocusTableCard({ data, currency, isLoading }: FocusTableCardProps) {
  const formatOptions = useMemo(() => ({ currency }), [currency])
  const colWidth = 100
  const columns: TTableColumn[] = [
    { name: 'timestamp', field: 'timestamp', width: colWidth },
    { name: 'resourceId', field: 'resourceId', width: colWidth },
    { name: 'provider', field: 'provider', width: colWidth },
    { name: 'serviceName', field: 'serviceName', width: colWidth },
    // { name: 'invoiceIssuer', field: 'invoiceIssuer', width: 300 },
    // { name: 'resourceName', field: 'resourceName', width: 300 },
    // { name: 'billingAccountName', field: 'billingAccountName', width: 300 },
    { name: 'Account Id', field: 'billingAccountId', width: colWidth },
    {
      name: 'Usage Qty',
      field: 'totalUsageQuantity',
      width: colWidth,
      format: formatNumber
    },
    {
      name: 'Effective Cost',
      field: 'totalEffectiveCost',
      width: colWidth,
      format: formatCost
    },
    {
      name: 'Pricing Unit',
      field: 'totalPricingUnit',
      width: colWidth,
      format: formatNumber
    },
    {
      name: 'Billed Cost',
      field: 'totalBilledCost',
      width: colWidth,
      format: formatCost
    },
    {
      name: 'List Cost',
      field: 'totalListCost',
      width: colWidth,
      format: formatCost
    },
    { name: 'Pricing Qty', field: 'totalPricingQuantity', width: colWidth },
    {
      name: 'List Unit Price',
      field: 'totalListUnitPrice',
      width: colWidth,
      format: formatCost
    }
  ]

  if (isLoading) {
    return (
      <GraphCardWrapper>
        <GraphCardGroup
          className="flex justify-center items-center h-full py-20"
          compact
          spacing={20}
        >
          <Spinner size={40} />
        </GraphCardGroup>
      </GraphCardWrapper>
    )
  }

  return (
    <GraphCard>
      <GraphCardContent className="overflow-x-none">
        <Table columns={columns} data={data} formatOptions={formatOptions} />
      </GraphCardContent>
    </GraphCard>
  )
}

export default FocusTableCard
