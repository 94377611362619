// import { useNavigate } from 'react-router-dom'

import { GraphCardGroup, GraphCardWrapper, SkeletonWrapper } from 'components'
import { useEffect, useState } from 'react'
import useAuthentication from 'hooks/useAuthentication'
import useProject from 'hooks/useProject'
import ProjectTableCard from './ProjectTableCard'

function Projects() {
  // const navigate = useNavigate()
  const { currency } = useAuthentication()
  const { initialLoading, fetchProjectsStatus, projectList } = useProject()
  const [doneSorting, setDoneSorting] = useState<boolean>(false)
  useEffect(() => {
    if (fetchProjectsStatus.isLoading) {
      setDoneSorting(false)
    }
  }, [fetchProjectsStatus])

  useEffect(() => {
    if (projectList.length !== 0) {
      projectList.sort((a, b) => b.cost!.monthlyCost! - a.cost!.monthlyCost!)
      setDoneSorting(true)
    }
  }, [projectList])

  return (
    <GraphCardWrapper>
      <SkeletonWrapper
        isLoading={initialLoading}
        count={2}
        inline
        height={40}
        className="w-48"
        containerClassName="flex justify-between"
      >
        <div className="flex items-center justify-between">
          <span className="text-xl font-medium">Projects</span>
          {/* <button
            type="button"
            onClick={() => navigate('/projects/dome/application-add')}
            className="bg-primary-color text-white rounded-md py-2.5 px-7"
          >
            Create Application
            </button> */}
        </div>
      </SkeletonWrapper>

      <GraphCardGroup grow>
        {(doneSorting &&
          <ProjectTableCard currency={currency} />
        )}
      </GraphCardGroup>
    </GraphCardWrapper>
  )
}

export default Projects
