import { TProject } from 'services/project.interface'
import { TApplication } from 'services/application.interface'
import { TComponent } from 'services/component.interface'

interface TFlattenProjectEntity {
  project?: TProject
  application?: TApplication
  component?: TComponent
}

export const flatProjectEntities = (projectList: TProject[]) => {
  const result: TFlattenProjectEntity[] = []

  projectList.forEach((project) => {
    const row: TFlattenProjectEntity = { project }

    project.applications?.forEach((application) => {
      row.application = application

      application.components?.forEach((component) => {
        row.component = component
        result.push({ ...row })
      })

      if (!application.components?.length) {
        result.push({ ...row })
      }
    })

    if (!project.applications?.length) {
      result.push({ ...row })
    }
  })

  return result
}
