import {
  useEffect,
  useState
} from 'react'
import { MdAddToPhotos } from 'react-icons/md';
import {
  GraphCardWrapper,
  GraphCardGroup,
  SkeletonWrapper,
  SpinnerLoader
} from 'components'
import useProject from 'hooks/useProject'

import './index.scss'
import { DragDropComponent } from 'features/projects/project-widget/widgetDragFunctionality';
import { axios } from 'utils';
import { v4 as uuid } from 'uuid'
import { useQuery } from 'react-query';
import { WidgetData } from 'features/projects/project-widget/widgetStatev2';
import { useTour } from '@reactour/tour';
import { TutorialContainer } from 'features/projects/project-tutorial';
import { tourConfig } from './tour';

function Home() {
  // const [toTutorial, setTutorial] = useState<boolean>(false)
  const { setSteps } = useTour()
  const [tutorialUpdater, setTutorialUpdater] = useState<boolean>(false)
  const [queryKey, setQueryKey] = useState<string>(uuid())
  const { initialLoading } = useProject()
  const [reload, setReload] = useState(1);
  const [show, setShow] = useState(false);
  const [labels, setLabels] = useState<[]>([])
  const [widgetObject, setWidgetObject] = useState<WidgetData>(new WidgetData())
  const [dataProcessed, setDataProcessed] = useState<boolean>(false)
  const [widgetsLoaded, setWidgetsLoaded] = useState<boolean>(false)
  const [widgetsNeedUpdating, setWidgetsNeedUpdating] = useState<boolean>(false)
  const [fieldsProcessed, setFieldsProcessed] = useState<boolean>(false)
  const [fields, setFields] = useState<string[]>([])
  const changeTutorialUpdater = () => {
    setTutorialUpdater(true)
  }
  const toggleShow = () => {
    setShow(!show)
  }
  const toggleShowOff = () => {
    setShow(false)
  }

  const AddLabeledDataChart = (tag: string) => {
    widgetObject.createWidget(tag)
    setReload(reload + 1)
  }
  const updateCallback = () => {
    setWidgetsNeedUpdating(true)
    setQueryKey(uuid())
  }
  const { error } = useQuery({
    queryKey: [queryKey],
    queryFn: async () => {
      try {
        if (!dataProcessed) {
          await axios
            .get('/labels')
            .then((res) => {
              setLabels(res.data)
              setDataProcessed(true)
            })
            .catch((error) => {
              throw new Error(`Error code ${error.status}`)
            })
        }
        if (!widgetsLoaded) {
          // const queryRequest = {
          //   key: 'widget',
          //   eMail: email,
          //   pathToSetting: 'body.widgets',
          // }
          console.log('called')
          console.log('called')
          await axios
            .get('/store/widgets')
            .then((res) => {
              // setLabels(res.data.labels)\
              setWidgetsLoaded(true)
              const temp = new WidgetData()
              temp.setCallback(() => { updateCallback() })
              temp.setWidgets(JSON.parse(res.data.storeValue).widgets)
              setWidgetObject(temp)
              // console.log(res.data.settingValue)
              // widgetList = res.data.settingValue
            })
            .catch((error) => {
              throw new Error(`Error code ${error.status}`)
            })
        }
        if (widgetsNeedUpdating) {
          // const queryRequest = {
          //   key: 'widget',
          //   eMail: email,
          //   pathToSetting: 'body.widgets',
          //   value: widgetObject.widgets
          // }
          await axios
            .put('/store/widgets', { widgets: widgetObject.widgets })
            .then(() => {
              setWidgetsNeedUpdating(false)
              widgetObject.resourceUpdateFinished()
            })
            .catch((error) => {
              throw new Error(`Error code ${error.status}`)
            })
        }
        if (!fieldsProcessed) {
          await axios
            .get('/fields')
            .then((res) => {
              let arr: string[] = res.data
              arr = arr.map((str) => {
                const splittesString = str.split('_')
                let res = ''
                splittesString.forEach((part) => {
                  if (res === '') {
                    res = part.charAt(0).toUpperCase() + part.slice(1)
                  } else {
                    // const withCapitalLetter = part.charAt(0).toUpperCase() + part.slice(1)
                    res = `${res} ${part}`
                  }
                })
                return res
              })
              setFields(arr)
              setFieldsProcessed(true)
            })
            .catch((error) => {
              throw new Error(`Error code ${error.status}`)
            })
        }
      } catch (error) {
        throw new Error(`Error code ${error}`)
      }
    }
  })

  useEffect(() => {
    if (setSteps) {
      setSteps(
        tourConfig(
          [toggleShow, toggleShowOff, changeTutorialUpdater]
        )
      )
    }
    // setIsOpen(true)
    // setTutorial(false)
  }, [widgetObject])

  window.addEventListener('mouseup', () => {
    if (widgetObject.widgetChanged) {
      widgetObject.setWidgetChanged(false)
      setWidgetsNeedUpdating(true)
      setQueryKey(uuid())
    }
  })

  // useEffect(() => {
  //   if (widgetObject.widgetChanged) {
  //     console.log('here')
  //     setWidgetsNeedUpdating(true)
  //     setQueryKey(uuid())
  //   }
  // }, [widgetObject.widgetChanged])

  if (error) return <div>An error has occurred</div>
  return (
    <div data-tour="theHomePage">
      <TutorialContainer
        tag="Homepage"
        updater={tutorialUpdater}
        tourConfig={
          tourConfig(
            [toggleShow, toggleShowOff, changeTutorialUpdater]
          )
        }
      >
        <GraphCardWrapper>
          <SkeletonWrapper isLoading={initialLoading} height={20} width={200}>
            <div className="flex items-center justify-between">
              <span className="text-xl font-medium">Dashboard</span>
            </div>
          </SkeletonWrapper>

          <GraphCardGroup>
            <div className="pl-3 phone:px-3 flex flex-col items-center cursor-pointer">
              <button
                data-tour="addWidgetButton"
                className="bg-sb-button-color absolute left-5 rounded-lg hover:bg-blue-500 text-white w-flex"
                style={{ height: 30, width: 30 }}
                type="button"
                title="Add labeled data chart"
                onClick={() => {
                  toggleShow()
                }}
              >
                <MdAddToPhotos style={{ marginLeft: 5 }} size={20}>
                  Add widget
                </MdAddToPhotos>
              </button>

              <div
                data-tour="addWidgetButtonPopUp"
                className={`dropdown-body w-48 absolute border-2 border-gray-200 bg-icon-color rounded ${show && 'dropdown-open'} top-60 left-5`}
                style={{ zIndex: 999 }}
              >
                <div
                  className="border-b border-gray-100 hover:bg-gray-50 py-2 px-2"
                  onClick={() => {
                    toggleShow()
                    AddLabeledDataChart('projectTable')
                  }}
                >
                  Aggregate cost by widget
                </div>
                <div
                  className="border-b border-gray-100 hover:bg-gray-50 py-2 px-2"
                  onClick={() => {
                    toggleShow()
                    AddLabeledDataChart('cumulativeCost')
                  }}
                >
                  Group cost by widget
                </div>
                {/* <div
                  className="border-b border-gray-100 hover:bg-gray-50 py-2 px-2"
                  onClick={() => {
                    toggleShow()
                    AddLabeledDataChart('optiminzationWidget')
                  }}
                >
                  Optiminzation Widget
                </div> */}
              </div>
            </div>
          </GraphCardGroup>
          <SpinnerLoader isLoading={!dataProcessed
            || !fieldsProcessed
            || !widgetsLoaded}
          >
            <div className="py-5">
              {(dataProcessed && fieldsProcessed && widgetsLoaded) && (
                <DragDropComponent labels={labels} fields={fields} widgetObject={widgetObject} />
              )}
            </div>
          </SpinnerLoader>
        </GraphCardWrapper>
      </TutorialContainer>
    </div>
  )
}

export default Home
