import { TQueryResponseHeader } from './commons.interface'
import { TReportOperator, TValueOptions } from './report.interface'

export type TFocus = {
  resourceId: string
  dailyCost: number
  weeklyCost: number
  monthlyCost: number
  totalEffectiveCost: string
  billingAccountId: string
  invoiceIssuer: string
  resourceName: string
  totalBilledCost: string
  serviceName: string
  totalListCost: string
  totalPricingQuantity: string
  totalListUnitPrice: string
  provider: string
  billingAccountName: string
  totalUsageQuantity: string
  totalPricingUnit: number
  timestamp: Date
  resourceType: string
}
export type TResource = {
  resourceId: string
  dailyCost: number
  weeklyCost: number
  monthlyCost: number
  billingAccountId: string
  serviceName: string
  provider: string
  billingAccountName: string
}
export type newTFocus = {
  dailyCost: number
  weeklyCost: number
  monthlyCost: number
  totalEfficiency: number
  resources: TFocus[]
  children: {
    [key: string]: newTFocus
  }
}

export type TFocusGroup = {
  [key: string]: TFocus
}
export type newTFocusGroup = {
  [key: string]: newTFocus
}

export type TCostHistoryQueryResponse = TQueryResponseHeader & {
  request: TQueryResponseHeader
  data: TFocusGroup[]
}

export type Plot = {
  x: number
  y: number
}

export type PlotDate = {
  x: number
  y: number
}
export type TPlotGroup = {
  [key: string]: Plot | PlotDate
}

export type TPlotSeries = {
  label: string,
  color: string,
  data: PlotDate[]
}

export type TSeriesGroup = {
  [key: string]: TFocus
}

export type TSeries = {
  label: string,
  data: TFocus[]
}

export const focusFieldOptions: TValueOptions[] = [
  { label: 'Total Usage Quantity', value: 'totalUsageQuantity' },
  { label: 'Total Effective Cost', value: 'totalEffectiveCost' },
  { label: 'total Pricing Quantity', value: 'totalPricingQuantity' },
  { label: 'Total Billed Cost', value: 'totalBilledCost' },
  { label: 'Total List Cost', value: 'totalListCost' },
  { label: 'Total List Unit Price', value: 'totalListUnitPrice' }
]

export const stepPeriodOptions: TValueOptions[] = [
  { label: 'Hour', value: 'h' },
  { label: 'Day', value: 'd' },
]
export const LabeledStepOptions: TValueOptions[] = [
  { label: 'Hourly', value: '1h' },
  { label: 'Daily', value: '1d' }
]
export const LabelsOptions: TValueOptions[] = [
  { label: 'application', value: 'application' },
  { label: 'project', value: 'project' }
]
export const LogicalOperators: {label:string, value:TReportOperator}[] = [
  { label: 'equal to', value: 'EQUALS' },
  { label: 'not equal to', value: 'NOTEQUALS' },
  { label: 'contains', value: 'CONTAINS' },
  { label: 'starts with', value: 'STARTSWITH' },
  { label: 'ends with', value: 'ENDSWITH' },
  { label: 'greater than', value: 'GREATERTHAN' },
  { label: 'less than', value: 'LESSTHAN' }
]
export const chartValues: {label:string, value:string}[] = [
  { label: 'provider', value: 'provider' },
  { label: 'resourceId', value: 'resourceId' },
  { label: 'invoiceIssuer', value: 'invoiceIssuer' },
  { label: 'serviceName', value: 'serviceName' }
]
