// import { useState } from 'react'
// import { useNavigate, useParams } from 'react-router-dom'
// import { BiDotsHorizontalRounded } from 'react-icons/bi'

import {
  SpinnerLoader,
  GraphCardWrapper,
  GraphCardGroup,
  GraphCard,
  GraphCardContent
} from 'components'
import { useEffect, useState } from 'react'
import {
  ProjectAllocationCostCard,
  ProjectBreadcrumb,
  ProjectCumulativeCostCard
} from 'features'
import useAuthentication from 'hooks/useAuthentication'
import useProject from 'hooks/useProject'
// import { SPACING } from 'constants/sizes.constants'
import { formatCost } from 'utils'
// import ApplicationGraphView from './application-graph-view'
import ComponentTableCard from './ComponentTableCard'

import './index.scss'

function ApplicationDetails() {
  // const navigate = useNavigate()
  // const { projectId, applicationId } = useParams()
  const { currency } = useAuthentication()
  const { initialLoading, fetchProjectsStatus, application, componentList } =
    useProject()
  const [doneSorting, setDoneSorting] = useState<boolean>(false)
  // const [tab, setTab] = useState<'overview' | 'graph' | 'workflow'>('overview')
  // const [tab] = useState<'overview' | 'graph' | 'workflow'>('overview')
  // const [dropdownDownOpen, setDropdownDownOpen] = useState(false)
  useEffect(() => {
    if (fetchProjectsStatus.isLoading) {
      setDoneSorting(false)
    }
  }, [fetchProjectsStatus])

  useEffect(() => {
    if (componentList.length !== 0) {
      componentList.sort((a, b) => b.cost!.monthlyCost! - a.cost!.monthlyCost!)
      setDoneSorting(true)
    }
  }, [componentList])

  return (
    <GraphCardWrapper>
      <ProjectBreadcrumb />

      <GraphCardGroup>
        <GraphCard isLoading={initialLoading} isHoverable height={86}>
          <div className="grow flex flex-col justify-center items-center">
            <SpinnerLoader isLoading={fetchProjectsStatus.isLoading}>
              <p className="text-3xl font-semibold text-gray-800">
                {componentList.length}
              </p>
            </SpinnerLoader>
            <p className="text-base font-medium text-gray-800">Components</p>
          </div>
        </GraphCard>
        <GraphCard isLoading={initialLoading} isHoverable height={86}>
          <div className="grow flex flex-col justify-center items-center">
            <SpinnerLoader isLoading={fetchProjectsStatus.isLoading}>
              <p className="text-3xl font-semibold text-gray-800">
                {formatCost(application?.cost?.monthlyCost || 0, { currency })}
              </p>
            </SpinnerLoader>
            <p className="text-base font-medium text-gray-800">Monthly Cost</p>
          </div>
        </GraphCard>
        <GraphCard isHide />
      </GraphCardGroup>

      <GraphCardGroup>
        <GraphCardContent minHeight={700} className="flex-col">
          <GraphCardGroup grow>
            {doneSorting && (
              <ComponentTableCard currency={currency} />
            )}
          </GraphCardGroup>
        </GraphCardContent>
      </GraphCardGroup>
      {doneSorting && (
        <GraphCardGroup grow>
          <GraphCard isLoading={initialLoading}>
            <ProjectAllocationCostCard type="component" currency={currency} />
          </GraphCard>
          <GraphCard isLoading={initialLoading}>
            <ProjectCumulativeCostCard type="component" currency={currency} />
          </GraphCard>
        </GraphCardGroup>
      )}
    </GraphCardWrapper>
  )
}

export default ApplicationDetails
