import { v4 as uuid } from 'uuid'

export type labeledDataWidget = {
  label: string,
  type: string,
  path: string[],
  stepDuration: string,
  window: string
}
export type tableWidget = {
  aggregate: string[],
  path: string[],
  selector: string
}
export type widgetState = {
  id: string,
  key: string,
  x: number,
  y: number,
  w: number,
  h: number,
  options: { label: string, func: () => void }[]
  widgetTypeData: labeledDataWidget | tableWidget | undefined
}
type widgetdata = { tag: string, state: widgetState }

export class WidgetData {
  widgets: widgetdata[] = []

  widgetChanged = false

  eMail = ''

  // eslint-disable-next-line class-methods-use-this
  updateCallback = () => { console.log('not received yet') }

  setWidgets(newList: widgetdata[]) {
    this.widgets = newList
  }

  setWidgetChanged(bool: boolean) {
    this.widgetChanged = bool
  }

  setEMail(newstr: string) {
    this.eMail = newstr
  }

  setCallback(newCallBack: () => void) {
    this.updateCallback = newCallBack
  }

  // ***************************************************************************
  // Update the localstorage
  // ***************************************************************************
  updateResource() {
    this.setWidgetChanged(true)
  }

  resourceUpdateFinished() {
    this.setWidgetChanged(false)
  }

  // ***************************************************************************
  // Add to the list.
  // ***************************************************************************
  addWidgetList(tag: string, state: widgetState) {
    this.widgets.push({ tag, state })
    this.updateCallback()
  }

  // ***************************************************************************
  // Delete from the list
  // ***************************************************************************
  deleteFromWidgetList(id: string) {
    const index = this.widgets.findIndex((el) => el.state.id === id)
    this.widgets.splice(index, 1)
    this.updateCallback()
  }

  // ***************************************************************************
  // Change position of 2 widgets.
  // ***************************************************************************
  swapWidgets(id: string, newIdx: number) {
    const widget = this.widgets.find((el) => el.state.id === id)
    if (widget !== undefined) {
      this.deleteFromWidgetList(id)
      this.widgets.splice(newIdx, 0, widget);
      this.updateResource()
    }
  }

  // ***************************************************************************
  // Create a new widgetstate object for a widget.
  // ***************************************************************************
  createWidget(tag: string) {
    const idKey = uuid()
    let widgetTypeData: labeledDataWidget | tableWidget | undefined;
    if (tag === 'labeledDataChart' || tag === 'cumulativeCost') {
      widgetTypeData = {
        label: '',
        type: '',
        path: [],
        // operator: 'NOTEQUALS',
        // apiFilterValue: '',
        window: 'lastmonth',
        stepDuration: '1d',
        // valueChosen: 'totalBilledCost',
        // data: [],
        // chartValue: 'serviceName'
      }
    } else if (tag === 'projectTable') {
      widgetTypeData = {
        aggregate: [],
        path: [],
        selector: 'fields'

      }
    }
    const newWidget: widgetState = {
      id: idKey,
      key: idKey,
      x: 0,
      y: 0,
      w: 6,
      h: 15,
      options: [],
      widgetTypeData
    }
    this.addWidgetList(tag, newWidget)
  }

  // ***************************************************************************
  // Following are some functions that allow the mutation of the data inside
  // of a widgetstate, it just needs the id.
  // ***************************************************************************

  // ***************************************************************************
  // All functions for the widgets in general
  // ***************************************************************************

  editXYWH(id: string, x: number, y: number, w: number, h: number) {
    const widget = this.widgets.find((el) => el.state.id === id)
    widget!.state.x = x
    widget!.state.y = y
    widget!.state.w = w
    widget!.state.h = h
    this.updateResource()
  }

  getOptions(id: string) {
    const widget = this.widgets.find((el) => el.state.id === id)
    return widget!.state.options
  }

  editOptions(id: string, label: string, func: () => void) {
    const widget = this.widgets.find((el) => el.state.id === id)
    widget!.state.options.push({ label, func })
  }

  resetOptions(id: string) {
    const widget = this.widgets.find((el) => el.state.id === id)
    widget!.state.options = []
  }

  // ***************************************************************************
  // All functions for the charts
  // ***************************************************************************
  editLabel(id: string, label: string) {
    console.log('we are starting the editing of the label')
    const widget = this.widgets.find((el) => el.state.id === id)
    if (widget) {
      if (widget?.tag === 'labeledDataChart' || widget?.tag === 'cumulativeCost') {
        console.log('we are editing the label')
        const data: labeledDataWidget = widget!.state.widgetTypeData as labeledDataWidget
        data.label = label
      }
    }
    this.updateCallback()
  }

  getLabel(id: string) {
    const widget = this.widgets.find((el) => el.state.id === id)
    if (widget) {
      if (widget.tag === 'labeledDataChart' || widget?.tag === 'cumulativeCost') {
        const data: labeledDataWidget = widget!.state.widgetTypeData as labeledDataWidget
        return data.label
      } return ''
    } return ''
  }

  getType(id: string) {
    const widget = this.widgets.find((el) => el.state.id === id)
    if (widget) {
      if (widget.tag === 'labeledDataChart' || widget?.tag === 'cumulativeCost') {
        const data: labeledDataWidget = widget!.state.widgetTypeData as labeledDataWidget
        return data.type
      } return ''
    } return ''
  }

  setType(id: string, type: string) {
    const widget = this.widgets.find((el) => el.state.id === id)
    if (widget) {
      if (widget.tag === 'labeledDataChart' || widget?.tag === 'cumulativeCost') {
        const data: labeledDataWidget = widget!.state.widgetTypeData as labeledDataWidget
        data.type = type
      }
    }
    this.updateCallback()
  }

  getChartPath(id: string) {
    const widget = this.widgets.find((el) => el.state.id === id)
    if (widget) {
      if (widget.tag === 'labeledDataChart' || widget?.tag === 'cumulativeCost') {
        const data: labeledDataWidget = widget!.state.widgetTypeData as labeledDataWidget
        return data.path
      } return []
    } return []
  }

  setChartPath(id: string, path: string[]) {
    const widget = this.widgets.find((el) => el.state.id === id)
    if (widget) {
      if (widget.tag === 'labeledDataChart' || widget?.tag === 'cumulativeCost') {
        const data: labeledDataWidget = widget!.state.widgetTypeData as labeledDataWidget
        data.path = path
      }
    }
    this.updateCallback()
  }

  editWindow(id: string, window: string) {
    const widget = this.widgets.find((el) => el.state.id === id)
    if (widget) {
      if (widget?.tag === 'labeledDataChart' || widget?.tag === 'cumulativeCost') {
        const data: labeledDataWidget = widget!.state.widgetTypeData as labeledDataWidget
        data.window = window
      }
    }
    this.updateCallback()
  }

  getWindow(id: string): string {
    const widget = this.widgets.find((el) => el.state.id === id)
    if (widget) {
      if (widget.tag === 'labeledDataChart' || widget?.tag === 'cumulativeCost') {
        const data: labeledDataWidget = widget!.state.widgetTypeData as labeledDataWidget
        return data.window
      } return ''
    } return ''
  }

  editStepDuration(id: string, stepDuration: string) {
    const widget = this.widgets.find((el) => el.state.id === id)
    if (widget) {
      if (widget?.tag === 'labeledDataChart' || widget?.tag === 'cumulativeCost') {
        const data: labeledDataWidget = widget!.state.widgetTypeData as labeledDataWidget
        data.stepDuration = stepDuration
      }
    }
    this.updateCallback()
  }

  getStepDuration(id: string) {
    const widget = this.widgets.find((el) => el.state.id === id)
    if (widget) {
      if (widget.tag === 'labeledDataChart' || widget?.tag === 'cumulativeCost') {
        const data: labeledDataWidget = widget!.state.widgetTypeData as labeledDataWidget
        return data.stepDuration
      } return ''
    } return ''
  }

  // ***************************************************************************
  // All functions for the Table widgets
  // ***************************************************************************

  getTableDataAggregate(id: string): string[] {
    const widget = this.widgets.find((el) => el.state.id === id)

    if (widget) {
      if (widget.tag === 'projectTable') {
        const data: tableWidget = widget!.state.widgetTypeData as tableWidget
        return data.aggregate
      } return []
    } return []
  }

  getTableDataPath(id: string): string[] {
    const widget = this.widgets.find((el) => el.state.id === id)

    if (widget) {
      if (widget.tag === 'projectTable') {
        const data: tableWidget = widget!.state.widgetTypeData as tableWidget
        return data.path
      } return []
    } return []
  }

  getTableDataSelector(id: string): string {
    const widget = this.widgets.find((el) => el.state.id === id)
    if (widget) {
      if (widget.tag === 'projectTable') {
        const data: tableWidget = widget!.state.widgetTypeData as tableWidget
        return data.selector
      } return 'fields'
    } return 'fields'
  }

  setTableDataAfterNewCall(id: string, aggregate: string[], selector: string) {
    const widget = this.widgets.find((el) => el.state.id === id)
    if (widget) {
      if (widget.tag === 'projectTable') {
        const data: tableWidget = widget!.state.widgetTypeData as tableWidget
        data.aggregate = aggregate
        data.selector = selector
      }
    }
    this.updateCallback()
  }

  setTableDataWhenWalkingThroughData(id: string, path: string[]) {
    const widget = this.widgets.find((el) => el.state.id === id)
    if (widget) {
      if (widget.tag === 'projectTable') {
        const data: tableWidget = widget!.state.widgetTypeData as tableWidget
        data.path = path
      }
    }
    this.updateCallback()
  }
}
