import { useEffect, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { HiOutlineTrash } from 'react-icons/hi'
import { Input, RegExInput, Select } from 'components'
import { axios, mapClasses } from 'utils'
import { v4 as uuid } from 'uuid'
import {
  TLabelRuleOperator
} from 'services/label.rule.interface'
import { createFieldLabel, isString, loadAutoCompleteIcon } from '../helperFunctions';
import {
  LABEL_RULE_ELEMENT_TYPE,
  LABEL_RULES_OPERATOR_STRING_OPTIONS,
  LABEL_RULES_OPERATOR_NUMERIC_OPTIONS
} from '../label.rule.constants'
import { effectTypeWithId, ruleTypeWithId } from '../queryHelperFunctions'

interface ItemDataEditComponentProps {
  editItem: ruleTypeWithId | effectTypeWithId
  type: string
  useOption?: boolean
  labelList: string[]
  fieldsValues: string[]
  styleString1: string,
  styleString2: string,
  styleString3: string,
  onChange?: (rule: ruleTypeWithId | effectTypeWithId) => void
  onDelete?: (rule: ruleTypeWithId | effectTypeWithId) => void
}

const re = '^[a-z0-9][a-z0-9.-_]*[a-z0-9]$'

function ItemDataEditComponent({
  editItem,
  type,
  useOption,
  labelList,
  fieldsValues,
  styleString1,
  styleString2,
  styleString3,
  onChange,
  onDelete
}: ItemDataEditComponentProps) {
  const operatorStringOptions = useMemo(() => {
    return [...LABEL_RULES_OPERATOR_STRING_OPTIONS]
  }, [])

  const operatorNumericOptions = useMemo(() => {
    return [...LABEL_RULES_OPERATOR_NUMERIC_OPTIONS]
  }, [])

  const focusOptions = useMemo(() => {
    const result: { label: string, value: string }[] = []
    fieldsValues.forEach((el) => {
      result.push({ label: createFieldLabel(el), value: el })
    })
    return result
  }, [fieldsValues])

  const [values, setValues] = useState<string[]>([])
  const [processed, setProcessed] = useState<boolean>(false)
  const [queryKey, setQueryKey] = useState<string>('')

  const { error } = useQuery({
    queryKey: [`query?${queryKey}`],
    queryFn: async () => {
      try {
        if (!processed && editItem.key !== '') {
          const query = type === LABEL_RULE_ELEMENT_TYPE.EFFECTS ? `/labels/${editItem.key}` : `/fields/${editItem.key}`
          await axios
            .get(query)
            .then((res) => {
              setValues(res.data)
              setProcessed(true)
            })
            .catch((error) => {
              console.log('error')
              console.log(editItem.key)
              console.log(error.response)
              if (error.response.data) {
                setProcessed(true)
              }
              // throw new Error(`Error code ${error.status}`)
            })
        }
      } catch (error) {
        throw new Error(`Error code ${error}`)
      }
    }
  })
  function loadNames() {
    return (
      <datalist id={`names${editItem.id}`}>
        {labelList.map((el, k) => { return <option key={`names-${k}`} value={el} /> })}
      </datalist>
    )
  }

  function loadValues() {
    return (
      <datalist id={`values${editItem.id}`}>
        {values.map((el, k) => { return <option key={`names-${k}`} value={el} /> })}
      </datalist>
    )
  }

  useEffect(() => {
    const isALabel = labelList.findIndex((el) => el === editItem.key) !== -1
      || fieldsValues.findIndex((el) => el === editItem.key) !== -1
    if (isALabel) {
      if (type === 'rules') {
        if (isString(editItem.key)) {
          setProcessed(false)
          setValues([])
          setQueryKey(uuid())
        } else setValues([])
      } else {
        setProcessed(false)
        setValues([])
        setQueryKey(uuid())
      }
    }
  }, [editItem.key, editItem.value])

  if (error) return <div>An error has occurred</div>
  return (
    <div className={styleString1}>
      <div
        className={mapClasses(
          styleString2
        )}
      >
        <div className={`grow flex flex-col sm:flex-row gap-3 ${styleString3}`}>
          {useOption && (
            <Select
              size="md"
              options={focusOptions.sort((a, b) => {
                return a.label.localeCompare(b.label)
              })}
              value={editItem.key}
              onChange={(event) => {
                setProcessed(false)
                if (onChange) onChange({ ...editItem, key: event.target.value })
              }}
            />
          )}
          {!useOption && (
            <RegExInput
              autoComplete="off"
              list={`names${editItem.id}`}
              size="md"
              type="text"
              name="key"
              pattern={re}
              errorMessage="LabelRule name is invalid. It must consist of lower case alphanumeric characters, \'-\' or \'.\' and must start and and with and alphanumeric characters"
              placeholder={
                type === LABEL_RULE_ELEMENT_TYPE.RULES
                  ? 'key...'
                  : 'name... '
              }
              value={editItem.key}
              onChange={(event) => {
                if (onChange) onChange({ ...editItem, key: event.target.value })
              }}
            />
          )}
          {loadNames()}
          {type === LABEL_RULE_ELEMENT_TYPE.RULES && (
            <Select
              size="md"
              options={
                isString(editItem.key)
                  ? operatorStringOptions
                  : operatorNumericOptions
              }
              value={(editItem as ruleTypeWithId).operand}
              onChange={(event) => {
                const { value } = event.target as HTMLSelectElement
                if (onChange) {
                  onChange({
                    ...editItem,
                    operand: value as TLabelRuleOperator
                  })
                }
              }}
            />
          )}
          <Input
            list={`values${editItem.id}`}
            size="md"
            type="text"
            placeholder="value..."
            value={editItem.value}
            onChange={(event) => {
              if (onChange) onChange({ ...editItem, value: event.target.value })
            }}
          />
          {loadValues()}
          {loadAutoCompleteIcon(type, 'rules', editItem.key)}
        </div>
        <div
          className="flex items-center border-l border-inherit p-2 cursor-pointer hover:bg-red-500 hover:bg-opacity-10 text-gray-500 hover:text-red-500"
          onClick={() => {
            if (onDelete) onDelete(editItem)
          }}
        >
          <HiOutlineTrash size={18} />
        </div>
      </div>
    </div>
  )
}

export default ItemDataEditComponent
