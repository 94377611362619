import { useMemo } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { BsTable } from 'react-icons/bs'

import { TCurrency } from 'services/cost.interface'
import { FetchStatus, GraphCard, GraphCardContent, Table } from 'components'
import useProject from 'hooks/useProject'
import { COMPONENT_TABLE_COLUMNS } from './constants'

interface ComponentTableCardProps {
  currency: TCurrency
}

function ComponentTableCard({ currency }: ComponentTableCardProps) {
  const { projectId, applicationId } = useParams()
  const navigate = useNavigate()
  const { initialLoading, fetchProjectsStatus, componentList, fetchProjects } =
    useProject()

  const formatOptions = useMemo(() => ({ currency }), [currency])

  const tableData = useMemo(() => {
    if (!componentList.length) return []
    return componentList.map((component) => ({
      name: component.name,
      provider: component.properties?.provider,
      serviceName: component.properties?.serviceName,
      daily: component.cost?.dailyCost,
      weekly: component.cost?.weeklyCost,
      monthly: component.cost?.monthlyCost,
      link: `/projects/${projectId}/applications/${applicationId}/components/${encodeURIComponent(component?.name)}`
    }))
  }, [componentList])

  return (
    <GraphCard isOutline isLoading={initialLoading}>
      <div className="w-full flex items-center">
        <BsTable size={24} />
        <span className="text-lg font-medium text-gray-800 px-4">
          Components
        </span>
      </div>
      <GraphCardContent className="overflow-x-auto">
        <FetchStatus status={fetchProjectsStatus} retry={fetchProjects}>
          <Table
            columns={COMPONENT_TABLE_COLUMNS}
            data={tableData}
            formatOptions={formatOptions}
            onRowClick={(row) => navigate(row.link || '')}
          />
        </FetchStatus>
      </GraphCardContent>
    </GraphCard>
  )
}

export default ComponentTableCard
