import {
  // useEffect,
  useMemo,
  // useState
} from 'react'

import { TCurrency } from 'services/cost.interface'
import { TProjectEntityNames } from 'services/project.interface'
import {
  BarChartComponent,
  FetchStatus,
  GraphCardContent
} from 'components'
import useProject from 'hooks/useProject'
import { mapBarChartData } from 'utils'
import { PROJECT_ENTITY_NAMES } from 'constants/project.constants'

interface ProjectCumulativeCostCardProps {
  type: TProjectEntityNames
  currency: TCurrency
}

export function ProjectCumulativeCostCard({
  type,
  currency
}: ProjectCumulativeCostCardProps) {
  const {
    fetchProjectsStatus,
    projectList,
    applicationList,
    componentList,
    fetchProjects
  } = useProject()
  // const [draggableHeight, setDraggableHeight] = useState<number>(341)

  // useEffect(() => {
  //   if (window.innerWidth >= 1250) {
  //     setDraggableHeight(343)
  //   } else {
  //     setDraggableHeight(420)
  //   }
  // })

  const chartData = useMemo(() => {
    if (type === PROJECT_ENTITY_NAMES.PROJECT) {
      return mapBarChartData(projectList, 'weeklyCost')
    }
    if (type === PROJECT_ENTITY_NAMES.APPLICATION) {
      return mapBarChartData(applicationList, 'weeklyCost')
    }
    if (type === PROJECT_ENTITY_NAMES.COMPONENT) {
      return mapBarChartData(componentList, 'weeklyCost')
    }
    return []
  }, [type, projectList, applicationList, componentList])

  return (
    <div>
      <span className="text-lg font-medium text-gray-800 ">
        Cumulative cost for last 7 days by {type}
      </span>
      <GraphCardContent minHeight={400} className="overflow-x-auto">
        <FetchStatus status={fetchProjectsStatus} retry={fetchProjects}>
          <BarChartComponent data={chartData} currency={currency} />
        </FetchStatus>
      </GraphCardContent>
    </div>
  )
}
