import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { BsTable } from 'react-icons/bs'

import { TCurrency } from 'services/cost.interface'
import { FetchStatus, GraphCardContent, Table } from 'components'
import useProject from 'hooks/useProject'
import { PROJECT_TABLE_COLUMNS } from './constants'
import TableInfoCard from './TableInfoCards'

interface ProjectTableCardProps {
  currency: TCurrency
}

function ProjectTableCard({ currency }: ProjectTableCardProps) {
  const navigate = useNavigate()
  const {
    fetchProjectsStatus,
    projectList,
    fetchProjects
  } = useProject()

  const formatOptions = useMemo(() => ({ currency }), [currency])

  const tableData = useMemo(() => {
    if (!projectList.length) return []
    return projectList.map((project) => ({
      name: project.name,
      apps: project.applications?.length,
      daily: project.cost?.dailyCost,
      weekly: project.cost?.weeklyCost,
      monthly: project.cost?.monthlyCost,
      link: `/projects/${project.name}`
    }))
  }, [projectList])

  return (
    <div>
      <div className="w-full flex items-center">
        <BsTable size={20} />
        <span className="text-lg font-medium text-gray-800 px-4">Projects</span>
        <TableInfoCard currency={currency} />
      </div>
      <GraphCardContent minHeight={376} className="overflow-x-auto">
        <FetchStatus status={fetchProjectsStatus} retry={fetchProjects}>
          <Table
            columns={PROJECT_TABLE_COLUMNS}
            data={tableData}
            formatOptions={formatOptions}
            onRowClick={(row) => navigate(row.link || '')}
          />
        </FetchStatus>
      </GraphCardContent>
    </div>
  )
}

export default ProjectTableCard
