import { TTableColumn } from 'components'
import { formatCost, formatNilValue } from 'utils'

export const REPORT_FILTER_TABLE_COLUMNS: TTableColumn[] = [
  {
    name: 'Report',
    field: 'name',
    width: '33%',
    format: formatNilValue
  },
  {
    name: 'Date range',
    field: 'dateRange',
    width: '33%',
    format: formatNilValue
  },
  {
    name: 'Description',
    field: 'description',
    width: '33%',
    format: formatNilValue
  }
]

export const REPORT_TABLE_COLUMNS: TTableColumn[] = [
  { name: 'Project', field: 'project', width: 200 },
  {
    name: 'Application',
    field: 'application',
    width: 200,
    format: formatNilValue
  },
  {
    name: 'Component',
    field: 'component',
    width: 200,
    format: formatNilValue
  },
  {
    name: 'dailyCost',
    field: 'dailyCost',
    width: 200,
    format: formatCost
  },
  {
    name: 'weeklyCost',
    field: 'weeklyCost',
    width: 200,
    format: formatCost
  },
  {
    name: 'monthlyCost',
    field: 'monthlyCost',
    width: 200,
    format: formatCost
  }
]
