import {
  SpinnerLoader,
  GraphCardWrapper,
  GraphCardGroup,
  GraphCard,
  BarChartComponent,
  FetchStatus,
  GraphCardContent,
  Table,
} from 'components'
import { ProjectBreadcrumb } from 'features'
import useAuthentication from 'hooks/useAuthentication'
import useProject from 'hooks/useProject'
import { axios, formatCost, IsComputeUrlValid } from 'utils'
import { useEffect, useMemo, useState } from 'react'
import { TQueryRequest } from 'services/commons.interface'
import { useQuery } from 'react-query'
import { TCostHistoryQueryResponse, TFocusGroup } from 'services/costhistory.interface'
import { TBarChartData } from 'services/chart.interface'
import { useNavigate } from 'react-router-dom'
import { COMPONENT_TABLE_COLUMNS } from './constants'

function ComponentDetails() {
  const {
    fetchProjects
  } = useProject()
  const { currency } = useAuthentication()
  const { initialLoading, fetchProjectsStatus, component } = useProject()
  const [queryRequest, setQueryRequest] = useState<TQueryRequest>()
  const [queryKey, setQueryKey] = useState<string>('')
  const [dataProcessed, setDataProcessed] = useState<boolean>(false)
  const [focusData, setFocusData] = useState<TFocusGroup[]>([])
  const [dataLoaded, setDataLoaded] = useState<boolean>(false)
  let componentProperties: any[] = []
  if (component!.properties! !== undefined) {
    componentProperties = Object.entries(component!.properties!)
  }
  let componentCosts: [string, number][] = []
  if (component!.cost! !== undefined) {
    componentCosts = Object.entries(component!.cost!)
  }
  const formatOptions = useMemo(() => ({ currency }), [currency])
  const navigate = useNavigate()
  useEffect(() => {
    if (!dataProcessed) {
      setQueryRequest({
        name: 'Query test',
        description: 'Historical cost of a resource by id',
        filters: [
          {
            field: 'provider',
            value: component?.properties?.provider,
            operator: 'EQUALS'
          },
          {
            field: 'resource_id',
            value: component?.properties?.resourceId,
            operator: 'EQUALS'
          }
        ],
        options: {
          saveQuery: false,
          accumulate: false,
          window: 'lastmonth',
          step: '1d',
          aggregate: ''
        }
      })
      setQueryKey(JSON.stringify(queryRequest))
    }
  })
  const { error } = useQuery({
    queryKey: [`query?${queryKey}`],
    queryFn: async ({ queryKey }) => {
      try {
        if (IsComputeUrlValid(queryKey[0]) && !dataProcessed) {
          setDataProcessed(true)

          await axios
            .post<TCostHistoryQueryResponse>('/query', queryRequest)
            .then((res) => {
              setFocusData(res.data.data)
            })
            .catch((error) => {
              throw new Error(`Error code ${error.status}`)
            })
        }
      } catch (error) {
        throw new Error(`Error code ${error}`)
      }
    }
  })
  function newRDetails(name: string, value: number): TBarChartData {
    return { name, value, color: '#8884d8' }
  }
  const data: TBarChartData[] = []
  focusData.forEach((el) => {
    let resourceId = ''
    if (component!.properties!.resourceId !== undefined) {
      resourceId = component!.properties!.resourceId
    }
    const date: Date = el[resourceId].timestamp
    data.push(newRDetails(date.toString(), Number(el[resourceId].totalBilledCost)))
  })
  type tableDataEl = { label: string, value: any }
  function createTableDataEl(theLabel: string, theValue: any): tableDataEl {
    return { label: theLabel, value: theValue }
  }
  const tableData: tableDataEl[] = useMemo(() => {
    const temp = componentProperties.concat(componentCosts).map((el) => {
      let label = ''
      let value = ''
      const [el1, el2] = el
      el1.split(/(?=[A-Z])/).forEach((el: string) => {
        const word = el.charAt(0).toUpperCase() + el.slice(1)
        label = label.concat(word.concat(' '))
      })
      if (Number.isNaN(Number(el2))) {
        value = el2
      } else if (label.split(' ')[1] === 'Cost') {
        value = `${currency.symbol} ${Number(el2).toFixed(2)}`
      } else {
        value = el2
      }
      return createTableDataEl(label, value)
    })
    return temp
  }, [componentProperties])

  if (data.length !== 0 && !dataLoaded) {
    setDataLoaded(true)
  }
  if (error) return <div>An error has occurred</div>
  return (
    <GraphCardWrapper>
      <ProjectBreadcrumb />

      <GraphCardGroup>
        <GraphCard isLoading={initialLoading} isHoverable height={86}>
          <div className="grow flex flex-col justify-center items-center">
            <SpinnerLoader isLoading={fetchProjectsStatus.isLoading}>
              <p className="text-3xl font-semibold text-gray-800">
                {formatCost(component?.cost?.monthlyCost || 0, { currency })}
              </p>
            </SpinnerLoader>
            <p className="text-base font-medium text-gray-800">Monthly Cost</p>
          </div>
        </GraphCard>
        <GraphCard isHide />
        <GraphCard isHide />
      </GraphCardGroup>
      <GraphCardGroup>
        <GraphCard>
          <div className="shrink flex-row justify-between">
            <GraphCardContent className="overflow-x-auto">
              <FetchStatus status={fetchProjectsStatus} retry={fetchProjects}>
                <Table
                  columns={COMPONENT_TABLE_COLUMNS}
                  data={tableData}
                  formatOptions={formatOptions}
                  onRowClick={(row) => navigate(row.link || '')}
                />
              </FetchStatus>
            </GraphCardContent>
          </div>
        </GraphCard>
      </GraphCardGroup>
      <GraphCard>
        <span className="text-lg font-medium text-gray-800">
          Cost of last month
        </span>
        <SpinnerLoader isLoading={!dataLoaded}>
          <GraphCardContent>
            <FetchStatus status={fetchProjectsStatus} retry={fetchProjects}>
              <BarChartComponent data={data} currency={currency} />
            </FetchStatus>
          </GraphCardContent>
        </SpinnerLoader>
      </GraphCard>
    </GraphCardWrapper>
  )
}

export default ComponentDetails
