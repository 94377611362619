import { TReportOperator } from 'services/report.interface';
// import ProjectTableCard from 'pages/projects/ProjectTableCard';
import { TCurrency } from 'services/cost.interface';
import { v4 as uuid } from 'uuid'
import { effectData } from 'pages/label-rules/mutation-new-label-rule-model/hierarchyHelperFunctions';
import { LabeledDataCard } from '../project-labeled-data-widget';
import { TableWidget } from '../project-table-widget';
import { OptiminzationWidget } from '../project-optimization';
import { WidgetData } from './widgetStatev2';

type widgetType = { name: string, type: JSX.Element }

export function returnElementByTag(
  tag: string,
  id: string,
  currency: TCurrency,
  labelRuleDetailsList: effectData[],
  fields: string[],
  openEditCallBack: () => void,
  closeEditCallBack: () => void,
  deleteWidget: () => void,
  widgetObject: WidgetData,
  labels: string[] = []
): JSX.Element {
  // ***************************************************************************
  // All the widgets that are currently able to be created.
  // Normally adding an entry here, adding an option to choose it in the Home,
  // and the object self would be enough to add a new widget.
  // When creating the actual object be aware that everything need to be in
  // an initial div
  // ***************************************************************************
  const widgetTypes: widgetType[] = [
    { name: 'projectTable', type: <TableWidget id={id} currency={currency} labelRuleDetailsList={labelRuleDetailsList} fields={fields} editCallBack={openEditCallBack} closeCallBack={closeEditCallBack} deleteWidget={deleteWidget} widgetObject={widgetObject} /> },
    {
      name: 'cumulativeCost',
      type: <LabeledDataCard id={id} labels={labels} fields={fields} initialType="accumulateTrueChart" editCallBack={openEditCallBack} closeCallBack={closeEditCallBack} deleteWidget={deleteWidget} widgetObject={widgetObject} />
    },
    {
      name: 'labeledDataChart',
      type: <LabeledDataCard id={id} labels={labels} fields={fields} initialType="accumulateFalseChart" editCallBack={openEditCallBack} closeCallBack={closeEditCallBack} deleteWidget={deleteWidget} widgetObject={widgetObject} />
    },
    {
      name: 'optiminzationWidget',
      type: <OptiminzationWidget
        id={id}
        currency={currency}
        editCallBack={openEditCallBack}
        closeCallBack={closeEditCallBack}
        deleteWidget={deleteWidget}
      />
    }
  ]
  const type = widgetTypes.find((el) => el.name === tag)?.type
  if (type !== undefined) {
    return type
  } return <div />
}

// ***************************************************************************
// Create the API call needed to get the chart data for the labeled data widget.
// ***************************************************************************

export type query = {
  name: string
  description?: string
  options: {
    saveQuery: boolean
    accumulate: boolean
    aggregate: string
    window?: string
    step?: string
  },
  filters?: {
    field?: string
    value?: string | number
    operator?: TReportOperator
  }[]
}

export function createWidgetAPILabelCall(
  label: string,
  window: string,
  step: string
): query {
  const filter: {
    field?: string | undefined;
    value?: string | number | undefined;
    operator?: TReportOperator | undefined
  }[] = [{
    field: `label.${label}`,
    value: '',
    operator: 'CONTAINS'
  }]
  return {
    name: uuid(),
    description: '',
    options: {
      saveQuery: false,
      accumulate: false,
      aggregate: `label.${label}`,
      window,
      step,
    },
    filters: filter
  }
}

export function createWidgetAPIFieldCall(
  field: string,
  window: string,
  step: string
): query {
  const filter: {
    field?: string | undefined;
    value?: string | number | undefined;
    operator?: TReportOperator | undefined
  }[] = []
  return {
    name: uuid(),
    description: '',
    options: {
      saveQuery: false,
      accumulate: false,
      aggregate: `${field}`,
      window,
      step,
    },
    filters: filter
  }
}
export function createWidgetAggregatedAPILabelCall(
  label: string
): query {
  const filter: {
    field?: string | undefined;
    value?: string | number | undefined;
    operator?: TReportOperator | undefined
  }[] = [{
    field: `label.${label}`,
    value: '',
    operator: 'CONTAINS'
  }]
  return {
    name: uuid(),
    description: '',
    options: {
      saveQuery: false,
      accumulate: true,
      aggregate: `label.${label}`
    },
    filters: filter
  }
}
export function createWidgetAggregatedAPIFieldCall(
  field: string
): query {
  const filter: {
    field?: string | undefined;
    value?: string | number | undefined;
    operator?: TReportOperator | undefined
  }[] = []
  return {
    name: uuid(),
    description: '',
    options: {
      saveQuery: false,
      accumulate: true,
      aggregate: `${field}`
    },
    filters: filter
  }
}

// ***************************************************************************
// Look for the amount of days the current month has already went through
// ***************************************************************************
export function calculateDaysPerMonth(nrOfMonths: number) {
  const d = new Date();
  const month = d.getMonth() + 1
  const year = d.getFullYear()
  const tmpList = []
  for (let ctr = nrOfMonths - 1; ctr > 0; ctr--) {
    let newMonth = month - ctr
    let newYear = year
    if (newMonth < 0) {
      newYear--
      newMonth = 12 + newMonth
    }
    const newDate = new Date(newYear, newMonth, 0)
    const window = { startDate: new Date(`${newDate.getFullYear()}-${newDate.getMonth()} - 1`), endDate: new Date(`${newDate.getFullYear()}-${newDate.getMonth()}-${newDate.getDate()}`) }
    tmpList.push(window)
  }
  const day = d.getDate()
  const window = { startDate: new Date(`${year}-${month - 1}-1`), endDate: new Date(`${year}-${month - 1}-${day}`) }
  tmpList.push(window)
  return tmpList
}

// ***************************************************************************
// Some data types.
// ***************************************************************************
export type Item = {
  [key: string]: any;
  timestamp: Date;
}

export type projectAndTheirApps = {
  label: string,
  [key: string]: any
}

export type dataNameAndColor = {
  name: string;
  color: string;
}
