import { GraphCard, SpinnerLoader } from 'components';
import useProject from 'hooks/useProject';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { TCurrency } from 'services/cost.interface';
import { formatCost } from 'utils';

interface TableInfoCardProps {
  currency: TCurrency
}
function TableInfoCard({ currency }: TableInfoCardProps) {
  const {
    fetchProjectsStatus,
    projectList,
  } = useProject()

  const totalMonthlyCost = useMemo(() => {
    const sumCost = projectList
      .map(({ cost }) => cost?.monthlyCost || 0)
      .reduce((sum, monthlyCost) => sum + monthlyCost, 0)
    return sumCost
  }, [projectList])

  return (
    <div>
      <div style={{ display: 'flex', justifyItems: 'center' }}>
        <GraphCard
          isHoverable
          height={50}
          className="relative"
        >
          <div className="grow flex flex-col justify-center items-center">
            <SpinnerLoader isLoading={fetchProjectsStatus.isLoading}>
              <p className="absolute top-1 text-m font-semibold text-gray-800">
                {projectList.length} Projects
              </p>
            </SpinnerLoader>
            {/* <p className="text-m font-medium text-gray-800"></p> */}
          </div>
          <Link to="/projects" className="absolute right-4 bottom-1">
            <p className="w-full underline text-primary-text-color text-xs cursor-pointer">
              Go to projects
            </p>
          </Link>
        </GraphCard>
        <GraphCard
          isHoverable
          height={50}
          className="relative"
        >
          <div className="grow flex flex-col justify-center items-center" style={{ width: '300px' }}>
            <SpinnerLoader isLoading={fetchProjectsStatus.isLoading}>
              <p className="absolute top-1 left-3 text-m font-semibold text-gray-800">
                {formatCost(totalMonthlyCost, { currency })} Monthly Cost
              </p>
            </SpinnerLoader>
          </div>
        </GraphCard>
      </div>
      <GraphCard isHide />
    </div>
  )
}
export default TableInfoCard
