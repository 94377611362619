import { useEffect, useMemo, useState } from 'react'
import { MdDragIndicator } from 'react-icons/md'
import { HiOutlineTrash } from 'react-icons/hi'
import { Draggable } from 'react-beautiful-dnd'

import {
  FETCH_STATUS,
  Input,
  Select,
  AutoSelect,
  FetchStatus
} from 'components'
import useReport from 'hooks/useReport'
import { mapClasses } from 'utils'
import {
  TReportConditionData,
  TReportFilterData,
  TReportOperator
} from 'services/report.interface'
import {
  FIELD_OPTIONS,
  NUMBER_OPERATOR_OPTIONS,
  OPERATOR_OPTIONS,
  REPORT_FILTER_TYPE,
  TEXT_OPERATOR_OPTIONS
} from 'constants/report.constants'

interface FilterConditionProps {
  index: number
  type: TReportFilterData['type']
  condition: TReportConditionData
  onChange: (condition: TReportConditionData) => void
  onDelete: (condition: TReportConditionData) => void
}

function FilterCondition({
  index,
  type,
  condition,
  onChange,
  onDelete
}: FilterConditionProps) {
  const { labels, fetchLabels } = useReport()

  const [fetchLabelsStatus, setFetchLabelsStatus] = useState(
    FETCH_STATUS.SUCCESS
  )
  const [labelOptions, setLabelOptions] = useState<{ value: string }[]>([])

  const defaultLabelOptions = useMemo(
    () => labels.map((label) => ({ value: label })),
    [labels]
  )

  const selectedFieldOption = useMemo(
    () => FIELD_OPTIONS.find((opt) => opt.value === condition.field),
    [condition.field]
  )

  const operatorOptions = useMemo(() => {
    if (selectedFieldOption?.type === 'number') {
      return [...OPERATOR_OPTIONS, ...NUMBER_OPERATOR_OPTIONS]
    }
    return [...OPERATOR_OPTIONS, ...TEXT_OPERATOR_OPTIONS]
  }, [type, selectedFieldOption])

  const handleAutoSelectInputChange = (value: string) => {
    const isValidValue = defaultLabelOptions.find((option) => {
      const searchValue = value.toLowerCase()
      return option.value.toLowerCase().includes(searchValue)
    })

    if (isValidValue) {
      setLabelOptions(defaultLabelOptions)
    } else {
      // add non existing label to label options
      setLabelOptions([...defaultLabelOptions, { value }])
    }
  }

  useEffect(() => {
    setLabelOptions(defaultLabelOptions)
  }, [defaultLabelOptions])

  useEffect(() => {
    if (type !== REPORT_FILTER_TYPE.LABEL) return
    fetchLabels(undefined, setFetchLabelsStatus)
  }, [type, fetchLabels])

  return (
    <Draggable draggableId={condition.id} index={index}>
      {({ innerRef, draggableProps, dragHandleProps }, { isDragging }) => {
        const newDraggableProps = {
          ...draggableProps,
          style: { ...draggableProps.style, left: 'auto', top: 'auto' }
        }
        if (isDragging) {
          const { top, height } = draggableProps.style as {
            top: number
            height: number
          }
          // 16 is padding from class "py-2"
          newDraggableProps.style.top = `${top - height + 16}px`
        }
        return (
          <div ref={innerRef} {...newDraggableProps} className="py-2">
            <div
              className={mapClasses(
                'flex bg-white border border-dark-grey rounded-md',
                isDragging && 'border-primary-color'
              )}
            >
              <div
                className={mapClasses(
                  'flex items-center p-2',
                  'hover:bg-primary-color hover:bg-opacity-10 border-r border-inherit text-gray-500 hover:text-primary-text-color',
                  isDragging && 'bg-primary-color bg-opacity-10 text-primary-text-color'
                )}
                {...dragHandleProps}
              >
                <MdDragIndicator size={18} />
              </div>
              <div className="grow flex flex-col sm:flex-row gap-3 p-3">
                {type === REPORT_FILTER_TYPE.DEFAULT && (
                  <Select
                    size="md"
                    options={FIELD_OPTIONS}
                    value={condition.field}
                    onChange={(event) => {
                      const { value } = event.target as HTMLSelectElement
                      onChange({ ...condition, field: value })
                    }}
                  />
                )}
                {type === REPORT_FILTER_TYPE.LABEL && (
                  <div className="flex justify-center items-center w-full">
                    <FetchStatus
                      height={16}
                      direction="row"
                      size="small"
                      hideMessage
                      status={fetchLabelsStatus}
                      retry={() => fetchLabels(undefined, setFetchLabelsStatus)}
                    >
                      <AutoSelect
                        options={labelOptions}
                        getOptionLabel={({ value }) => value}
                        value={{ value: condition.field }}
                        onInputChange={handleAutoSelectInputChange}
                        onChange={(option) => {
                          onChange({ ...condition, field: option?.value || '' })
                        }}
                      />
                    </FetchStatus>
                  </div>
                )}
                {type === REPORT_FILTER_TYPE.ANNOTATION && (
                  <Input
                    size="md"
                    type={selectedFieldOption?.type}
                    placeholder={`${type}...`}
                    value={condition.field}
                    onChange={(event) => {
                      onChange({ ...condition, field: event.target.value })
                    }}
                  />
                )}

                <Select
                  size="md"
                  options={operatorOptions}
                  value={condition.operator}
                  onChange={(event) => {
                    const { value } = event.target as HTMLSelectElement
                    onChange({ ...condition, operator: value as TReportOperator })
                  }}
                />
                <Input
                  size="md"
                  type={selectedFieldOption?.type}
                  placeholder="value..."
                  value={condition.value}
                  onChange={(event) => {
                    onChange({ ...condition, value: event.target.value })
                  }}
                />
              </div>
              <div
                className="flex items-center border-l border-inherit p-2 cursor-pointer hover:bg-red-500 hover:bg-opacity-10 text-gray-500 hover:text-red-500"
                onClick={() => onDelete(condition)}
              >
                <HiOutlineTrash size={18} />
              </div>
            </div>
          </div>
        )
      }}
    </Draggable>
  )
}

export default FilterCondition
