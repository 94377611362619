import { BrowserRouter as Router } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import '@mantine/core/styles.css'
import 'react-toastify/dist/ReactToastify.css'
import { ReactKeycloakProvider } from '@react-keycloak/web'
import { createTheme, MantineProvider } from '@mantine/core'
import keycloak from 'components/key-cloak'
import { AuthenticationContextProvider } from 'context/AuthenticationContext'
import AppRouter from './config/router'

function App() {
  const theme = createTheme({
    /** Put your mantine theme override here */
  })

  return (
    <Router>
      <ReactKeycloakProvider
        authClient={keycloak}
        onTokens={({ token }) => {
          // dispatch(setToken(token));
          localStorage.setItem('access_token', token || '')
        }}
      >
        <AuthenticationContextProvider>
          <MantineProvider theme={theme}>
            <AppRouter />
            <ToastContainer
              closeButton={false}
              className="!w-auto !min-w-[100] !max-w-[320px]"
              toastClassName="!min-h-[auto] !rounded-xl !py-2 !mb-0 !mt-2"
              bodyClassName="!m-0 !py-1 !pr-3 !text-sm !text-gray-700"
              progressClassName="h-[3px]"
            />
          </MantineProvider>
        </AuthenticationContextProvider>
      </ReactKeycloakProvider>
    </Router>
  )
}

export default App
