import { TTableColumn } from 'components'
import { formatNilValue } from 'utils'

export const COMPONENT_TABLE_COLUMNS: TTableColumn[] = [{
  name: 'Reource information type',
  field: 'label',
  width: 200,
  format: formatNilValue
},
{
  name: 'value',
  field: 'value',
  width: 200,
  format: formatNilValue
}]
