// import { useNavigate } from 'react-router-dom'

import {
  SpinnerLoader,
  GraphCardWrapper,
  GraphCardGroup,
  GraphCard,
  SkeletonWrapper
} from 'components'
import { useEffect, useState } from 'react'
import { ProjectAllocationCostCard, ProjectCumulativeCostCard } from 'features'
import useAuthentication from 'hooks/useAuthentication'
import useProject from 'hooks/useProject'
import { formatCost } from 'utils'
import ApplicationTableCard from './ApplicationTableCard'

function ProjectDetails() {
  const { currency } = useAuthentication()
  // const navigate = useNavigate()
  const { initialLoading, project, applicationList, fetchProjectsStatus } =
    useProject()

  const [doneSorting, setDoneSorting] = useState<boolean>(false)

  useEffect(() => {
    if (fetchProjectsStatus.isLoading) {
      setDoneSorting(false)
    }
  }, [fetchProjectsStatus])

  useEffect(() => {
    if (applicationList.length !== 0) {
      applicationList.sort((a, b) => b.cost!.monthlyCost! - a.cost!.monthlyCost!)
      setDoneSorting(true)
    }
  })
  return (
    <GraphCardWrapper>
      <SkeletonWrapper
        isLoading={initialLoading}
        count={2}
        inline
        height={40}
        className="w-48"
        containerClassName="flex justify-between"
      >
        <div className="flex xs:flex-col desktopMini:flex-row items-start justify-between w-full">
          <p className="text-xl font-medium xs:pb-3">{project?.name}</p>
          <div className="flex xs:justify-end xs:self-end desktopMini:justify-between">
            {/* <button
              type="button"
              className="bg-primary-color text-white rounded-md py-2.5 px-7 ml-2"
              onClick={() => {
                navigate(`/projects/${project?.name}/application-add`)
              }}
            >
              Create Application
            </button> */}
          </div>
        </div>
      </SkeletonWrapper>

      <GraphCardGroup>
        <GraphCard isLoading={initialLoading} isHoverable height={86}>
          <div className="grow flex flex-col justify-center items-center">
            <SpinnerLoader isLoading={fetchProjectsStatus.isLoading}>
              <p className="text-3xl font-semibold text-gray-800">
                {applicationList.length}
              </p>
            </SpinnerLoader>
            <p className="text-base font-medium text-gray-800">Applications</p>
          </div>
        </GraphCard>
        <GraphCard isLoading={initialLoading} isHoverable height={86}>
          <div className="grow flex flex-col justify-center items-center">
            <SpinnerLoader isLoading={fetchProjectsStatus.isLoading}>
              <p className="text-3xl font-semibold text-gray-800">
                {formatCost(project?.cost?.monthlyCost || 0, { currency })}
              </p>
            </SpinnerLoader>
            <p className="text-base font-medium text-gray-800">Monthly Cost</p>
          </div>
        </GraphCard>
        <GraphCard isHide />
      </GraphCardGroup>

      <GraphCardGroup grow>
        {doneSorting && (
          <ApplicationTableCard currency={currency} />
        )}
      </GraphCardGroup>
      {doneSorting && (
        <GraphCardGroup grow>
          <GraphCard isLoading={initialLoading}>
            <ProjectAllocationCostCard type="application" currency={currency} />
          </GraphCard>
          <GraphCard isLoading={initialLoading}>
            <ProjectCumulativeCostCard type="application" currency={currency} />
          </GraphCard>
        </GraphCardGroup>
      )}
    </GraphCardWrapper>
  )
}

export default ProjectDetails
