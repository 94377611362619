type EnvType = {
  REACT_APP_API_BASE_URL: string
  REACT_APP_GOOGLE_CLIENT_ID: string
  REACT_APP_KEY_CLOAK_URL: string
  REACT_APP_KEY_CLOAK_REALM: string
  REACT_APP_KEY_CLOAK_CLIENT_ID: string
  REACT_APP_KEY_CLOAK_LOGIN_USE_KEYCLOAK: boolean
  REACT_APP_MAX_CHART_ITEMS: string
  REACT_APP_MANAGER_API_URL: string
}

declare global {
  interface Window {
    env: EnvType
  }
}

const env: EnvType = { ...process.env, ...window.env }
// console.log(env)
export default env
