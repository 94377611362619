import { BarChart, ChartTooltip } from '@mantine/charts';
// import { dataNameAndColor } from 'features/projects/project-labeled-data-card';
import { dataNameAndColor, projectAndTheirApps } from 'features/projects/project-widget/widgetHelpFunctions';
import { TCurrency } from 'services/cost.interface';

interface LabeledDataProps {
  data: projectAndTheirApps[],
  series: dataNameAndColor[],
  height: number,
  width: number,
  currency: TCurrency

}

function LabeledDataComponent({
  data,
  series,
  height,
  width,
  currency
}: LabeledDataProps) {
  function makeTooltip(label: any, payload: any) {
    const newPayload = payload.sort((a: { value: number; }, b: { value: number; }) => {
      return b.value - a.value
    })
    return (
      <ChartTooltip
        label={label}
        payload={
          newPayload.map((el: {
            unit: string; value: number;
          }) => {
            const newEl = el
            newEl.unit = currency.symbol
            return newEl
          })
        }
      />
    )
  }
  return (
    <div className="w-full h-full">
      <BarChart
        h={height}
        w={width}
        data={data}
        dataKey="label"
        type="stacked"
        series={series}
        style={{ transitionDuration: '0ms' }}
        tooltipProps={{
          content: ({ label, payload }) => makeTooltip(label, payload),
        }}
        withLegend
        legendProps={{ verticalAlign: 'bottom', height: 50, layout: 'horizontal', align: 'left' }}
      />
    </div>
  )
}

export default LabeledDataComponent
