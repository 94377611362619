import { ReactElement, useState, useEffect } from 'react'
import { GiHamburgerMenu } from 'react-icons/gi'

import useAuthentication from 'hooks/useAuthentication'
import { ProjectReloadButton, Sidebar, TopBar } from 'components'

function MainLayout({ children }: { children: ReactElement }) {
  const { isAuthenticated } = useAuthentication()

  const [toggleIconStatus, setToggleIconStatus] = useState<boolean>(true)

  const resize = () => {
    if (window.innerWidth <= 992) {
      setToggleIconStatus(false)
    } else if (!toggleIconStatus) {
      setToggleIconStatus(true)
    }
  }

  useEffect(() => {
    resize()
    window.addEventListener('resize', () => {
      resize()
    })
  }, [])

  const handleToggleSidebar = (action: boolean) => {
    setToggleIconStatus(action)
    if (window.innerWidth <= 992) {
      setToggleIconStatus(false)
    }
  }

  return (
    <div data-tour="fullApp">
      <div className="flex desktopMini:flex-row h-screen">
        {isAuthenticated && (
          <div data-tour="sidebar" className="sidebar">
            <Sidebar sidebarStatus={toggleIconStatus} />
          </div>
        )}
        <main className="flex flex-1 flex-col overflow-x-auto relative">
          {isAuthenticated && (
            <div data-tour="topbar" className="flex justify-between items-center border-b-2 border-gray-200 bg-top-bar-color">
              <div className="toggle_icon p-4 xs:top-0 desktopMini:top-4">
                <GiHamburgerMenu
                  className="cursor-pointer text-icon-color"
                  size={22}
                  onClick={() => handleToggleSidebar(!toggleIconStatus)}
                />
              </div>
              <div className="toggle_icon p-4 xs:top-0 desktopMini:top-4">
                <ProjectReloadButton />
              </div>
              <TopBar />
            </div>
          )}
          {children}
        </main>
      </div>
    </div>
  )
}

export default MainLayout
