/* eslint-disable max-len */
import { ChangeEvent, FormEvent, useState, useMemo, useEffect } from 'react'

import {
  TReport,
  TReportFilter,
  TReportBodyData,
  TReportFilterDisplay
} from 'services/report.interface'
// import { REPORT_FILTER_TYPE } from 'constants/report.constants'
import {
  FETCH_STATUS,
  Backdrop,
  Spinner,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from 'components'
import useReport from 'hooks/useReport'
import useAuthentication from 'hooks/useAuthentication'
import ReportEditor from '../ReportEditor'

interface MutationNewReportModalProps {
  isOpen: boolean
  data?: TReportFilterDisplay
  onMutated: (reportFilter: TReportFilter, reports: TReport[]) => void
  onClose: () => void
}

function MutationNewReportModal({
  isOpen,
  data,
  onMutated,
  onClose
}: MutationNewReportModalProps) {
  const { currency } = useAuthentication()
  const { createReportFilter } = useReport()

  // ************
  // Setup states
  // ************
  const [createReportFilterStatus, setCreateReportFilterStatus] = useState(
    FETCH_STATUS.SUCCESS
  )
  const [reportDisplayData, setReportDisplayData] = useState<
    TReportFilterDisplay | undefined
  >() // the data that brought in from the grid
  const [payload, setPayload] = useState<TReportBodyData>() // store the payload that is used to save to api
  const [reportName, setReportName] = useState<string>('') // Used to trigger query of filters
  const [reportDescription, setReportDescription] = useState<string>('') // Used to trigger query of filters
  const [reportId, setReportId] = useState<string>('') // Used to trigger query of filters

  // *********************************************
  // Function to handle when the report is changed
  // *********************************************
  const handleOnChanged = (payload: TReportBodyData) => {
    // *******************
    // Save the Report payload
    // *******************
    setPayload(payload)
  }

  // ***************************************
  // Used to save state if the form is valid
  // ***************************************
  const isValidForm = useMemo(() => {
    if (!reportName) return false // check if has report name
    // if (payload?.chart?.filter === '' || undefined) return false // check if has chart filter
    let isValid = true
    let hasCondition = false
    // *******************************************************
    // Check if we have any partially completed report filters
    // *******************************************************

    if (payload?.filters && payload?.filters.length > 0) {
      payload?.filters?.forEach((filter) => {
        hasCondition = true
        if (!filter.field || !filter.operator || !filter.value) {
          isValid = false
        }
      })
    } else {
      isValid = true
      hasCondition = true
    }
    return isValid && hasCondition
  }, [reportName, payload])

  // ***********************************
  // Handle name and description changes
  // ***********************************
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    if (name === 'name') {
      setReportName(value)
    } else if (name === 'description') {
      setReportDescription(value)
    }
  }

  // ******************
  // Handle form submit
  // ******************
  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (!payload) return
    // ************************
    // Post the data to the API
    // ************************
    createReportFilter(payload, setCreateReportFilterStatus).then((reports) => {
      const { name, description } = payload
      onMutated({ id: reportId, name, description, filters: payload.filters }, reports)
      onClose()
    })
  }

  // **********************************************
  // Effect to setup the states when data is passed
  // **********************************************
  useEffect(() => {
    if (!data) return
    setReportName(data.name)
    setReportDescription(data.description || '')
    setReportDisplayData(data)
    setReportId(data.id)
  }, [data])

  return (
    <Modal isOpen={isOpen} onClose={onClose} disableBackdropClick>
      <ModalHeader onClose={onClose}>
        {data ? 'Update' : 'Create'} new report
      </ModalHeader>
      <form onSubmit={handleSubmit}>
        <ModalBody className="px-0 py-0 w-[80vw]">
          <div className="flex gap-6 px-5 pb-4 border-b">
            <Input
              type="text"
              name="name"
              label="Name"
              value={reportName}
              onChange={handleChange}
            />
            <Input
              type="text"
              name="description"
              label="Description"
              value={reportDescription}
              onChange={handleChange}
            />
          </div>
          <ReportEditor
            reportParam={reportDisplayData}
            currency={currency}
            nameParam={reportName}
            descriptionParam={reportDescription}
            onChanged={handleOnChanged}
          />
        </ModalBody>
        <ModalFooter className="gap-4">
          <button
            type="button"
            className="rounded-lg hover:bg-red-500 hover:bg-opacity-10 py-1 px-4 text-red-500"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            type="submit"
            disabled={!isValidForm}
            className="bg-primary-color hover:bg-blue-500 disabled:bg-gray-300 text-white rounded-md py-1 px-4"
          >
            {data ? 'Update' : 'Create'}
          </button>
        </ModalFooter>
      </form>

      <Backdrop isLoading={createReportFilterStatus.isLoading}>
        <Spinner size={40} />
      </Backdrop>
    </Modal>
  )
}

export default MutationNewReportModal
