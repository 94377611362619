import { useState, useEffect, useMemo } from 'react'
import { TiFolderAdd } from 'react-icons/ti'
import { AiOutlinePlus } from 'react-icons/ai'
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd'
import {
  cleanIntToString,
  convertReportFilterDataToCondition,
  convertReportFilterDisplayToData
} from 'utils/dataConverter'
import {
  DateRangePickerComponent,
  GraphCard,
  RegExInput,
  Select
} from 'components'
import { useQuery } from 'react-query'
import { axios } from 'utils'
import {
  TReportFilterData,
  TReportConditionData,
  TReportBodyData,
  TReportFilterDisplay
} from 'services/report.interface'
import { TQueryRequest } from 'services/commons.interface'
import {
  focusFieldOptions,
  stepPeriodOptions
} from 'services/costhistory.interface'
import { REPORT_FILTER_TYPE } from 'constants/report.constants'
import FilterCondition from './FilterCondition'
import {
  generateEmptyFilter,
  generateEmptyCondition,
  updateConditionList,
  updateFilterList,
  reorderConditionList
} from '../report.utils'

interface FilterEditorProps {
  reportParam?: TReportFilterDisplay
  chartTypeParam: 'line' | 'area' | 'bar'
  nameParam: string
  descriptionParam: string
  onFilter?: (
    queryRequest: TQueryRequest,
    field: string,
  ) => void
  onMutated?: (payload: TReportBodyData) => void
}

function FilterEditor({
  reportParam,
  chartTypeParam,
  nameParam,
  descriptionParam,
  onFilter,
  onMutated
}: FilterEditorProps) {
  // ************
  // Setup States
  // ************
  const timeWindow: string[] = reportParam?.chart?.window.split(',') ?? []
  const [startDate, setStartDate] = useState<Date>(new Date())
  const [endDate, setEndDate] = useState<Date>(new Date())
  const [dateInitialised, setDateInitialised] = useState<boolean>(false)
  const [stepInitialised, setStepInitialised] = useState<boolean>(false)

  if (timeWindow.length !== 0 && !dateInitialised) {
    const newStartDate = new Date(timeWindow[0])
    const newEndDate = new Date(timeWindow[1])
    setStartDate(newStartDate)
    setEndDate(newEndDate)
    if (newStartDate.getTime() === startDate.getTime()
      && newEndDate.getTime() === endDate.getTime()) {
      setDateInitialised(true)
    }
  }
  const [, setQueryKey] = useState<string>('') // Used to trigger query of filters
  const [, setReportFilterDisplay] =
    useState<TReportFilterDisplay>() // Used to trigger query of filters
  // ***********************
  // Setup the report states
  // ***********************
  const [reportFilter, setReportFilter] =
    useState<TReportFilterData[]>([]) // store the selected chart filter options
  const [stepUnit, setStepUnit] = useState<string>('h')
  const [stepDuration, setStepDuration] = useState<string>('1')
  const [chartType, setChartType] = useState<'line' | 'area' | 'bar'>(
    chartTypeParam || 'line'
  )
  const [name, setName] = useState<string>(nameParam)
  const [description, setDescription] = useState<string>(descriptionParam)
  const [focusField, setFocusField] = useState<string>('totalBilledCost')

  const [labelList, setLabelList] = useState<[]>([])
  const [fields, setFields] = useState<[]>([])
  const [values, setValues] = useState<[]>([])
  const [initialFieldValues, setInitialFieldValues] = useState<[]>([])
  const [initialFieldValuesProcessed, setInitialFieldValuesProcessed] = useState<boolean>(false)
  const [currentLabelViewed, setCurrentLabelViewed] = useState<string>('')
  const [currentFieldViewed, setCurrentFieldViewed] = useState<string>('resource_id')
  const [editedCondition, setEditedCondition] = useState<TReportConditionData>()
  const [currentCallback, setCurrentCallback] = useState<() => void>(() => () => { console.log() })

  const [labelsListProcessed, setLabelsListProcessed] = useState<boolean>(false)
  const [labelsProcessed, setLabelsProcessed] = useState<boolean>(true)
  const [fieldsProcessed, setFieldsProcessed] = useState<boolean>(false)
  const [fieldsValuesProcessed, setFieldsValuesProcessed] = useState<boolean>(false)

  const { error } = useQuery({
    queryKey: [`query?${currentLabelViewed}${currentFieldViewed}`],
    queryFn: async () => {
      try {
        if (!labelsListProcessed) {
          await axios
            .get('/labels')
            .then((res) => {
              setLabelList(res.data)
              setLabelsListProcessed(true)
            })
            .catch((error) => {
              throw new Error(`Error code ${error.status}`)
            })
        }
        if (!labelsProcessed) {
          await axios
            .get(`/labels/${currentLabelViewed}`)
            .then((res) => {
              setValues(res.data)
              setLabelsProcessed(true)
            })
            .catch((error) => {
              throw new Error(`Error code ${error.status}`)
            })
        }
        if (!fieldsProcessed) {
          await axios
            .get('/fields')
            .then((res) => {
              setFields(res.data.sort())
              setFieldsProcessed(true)
            })
            .catch((error) => {
              throw new Error(`Error code ${error.status}`)
            })
        }
        if (!fieldsValuesProcessed) {
          await axios
            .get(`/fields/${currentFieldViewed}`)
            .then((res) => {
              setValues(res.data)
              setFieldsValuesProcessed(true)
              if (!initialFieldValuesProcessed) {
                setInitialFieldValues(res.data)
                setInitialFieldValuesProcessed(true)
              }
            })
            .catch((error) => {
              throw new Error(`Error code ${error.status}`)
            })
        }
      } catch (error) {
        throw new Error(`Error code ${error}`)
      }
    }
  })

  useEffect(() => {
    if (reportParam !== undefined && !stepInitialised) {
      if (reportParam!.chart!.step !== undefined) {
        const el = reportParam!.chart!.step
        const idx = el.length - 1
        const duration = el.substring(0, idx)
        const unit = el.substring(idx, idx + 1)
        setStepDuration(duration)
        setStepUnit(unit)
      }
      if (reportParam!.chart!.chartValue! !== undefined) {
        setFocusField(reportParam!.chart!.chartValue!)
      }
      setStepInitialised(true)
    }
  })
  const re = '^[0-9]*$'

  // ****************
  // Build the payload
  // *****************
  const payload = useMemo(() => {
    // *******************
    // Build the Body Data
    // *******************
    const payload: TReportBodyData = {
      name,
      description,
      filters: []
    }
    // *********************
    // Build the filter data
    // *********************
    reportFilter.forEach((filter) => {
      filter.conditions.forEach((condition) => {
        const payloadFilter = {
          field: condition.field,
          value: condition.value,
          operator: condition.operator
        }
        if (filter.type !== REPORT_FILTER_TYPE.DEFAULT) {
          payloadFilter.field = `${filter.type}.${condition.field}`
        }
        payload.filters.push(payloadFilter)
      })
    })
    // *******************
    // Build the chart Data
    // *******************
    payload.chart = {
      window: `${startDate?.toISOString().replaceAll('.000Z', 'Z')},${endDate
        ?.toISOString()
        .replaceAll('.000Z', 'Z')}`,
      step: `${stepDuration}${stepUnit}`,
      accumulate: true,
      aggregate: 'resourceId',
      chartType,
      chartValue: focusField || ''
    }
    return payload
  }, [
    name,
    description,
    startDate,
    endDate,
    stepDuration,
    stepUnit,
    chartType,
    reportFilter
  ])

  const filterPayload = useMemo(() => {
    // ********************************
    // Build the payload for the query
    // *******************
    const payload: TQueryRequest = {
      name,
      description,
      filters: convertReportFilterDataToCondition(reportFilter),
      options: {
        saveQuery: false,
        accumulate: false,
        window: `${startDate?.toISOString().replaceAll('.000Z', 'Z')},${endDate
          ?.toISOString()
          .replaceAll('.000Z', 'Z')}`,
        step: `${stepDuration}${stepUnit}`,
        aggregate: 'resourceId'
      }
    }
    return payload
  }, [
    name,
    description,
    startDate,
    endDate,
    stepDuration,
    stepUnit,
    chartType,
    reportFilter
  ])

  // ******************************
  // Setup the states on first load
  // ******************************
  useEffect(() => {
    if (!reportParam) return
    setReportFilterDisplay(reportParam)
    setReportFilter(convertReportFilterDisplayToData(reportParam))
  }, [reportParam])

  // *************************************
  // This function handles adding a filter
  // *************************************
  const handleAddFilter = (type: TReportFilterData['type']) => {
    const isExisted = reportFilter.some((filter) => filter.type === type)
    if (isExisted) return
    const filters = [...reportFilter, { ...generateEmptyFilter(type), type }]
    setReportFilter(filters)
  }

  // *********************************************************
  // This function handles triggering the query for the filter
  // *********************************************************
  const handleReportFilter = () => {
    setQueryKey(
      JSON.stringify(convertReportFilterDataToCondition(reportFilter))
    )
  }

  const handleFilter = () => {
    if (onFilter) {
      onFilter(filterPayload, focusField)
    }
  }
  // ****************************************
  // This function handles adding a condition
  // ****************************************
  const handleAddCondition = (filter: TReportFilterData) => {
    if (!filter) return
    const conditions = [
      ...filter.conditions,
      { ...generateEmptyCondition(filter.type) }
    ]
    const filters = updateFilterList(reportFilter, {
      ...filter,
      conditions
    })
    setReportFilter(filters)
  }

  // *****************************************
  // This function handles editing a condition
  // *****************************************
  const handleEditCondition = (
    filter: TReportFilterData,
    editedCondition: TReportConditionData,
    isText: boolean
  ) => {
    if (!filter && reportFilter) return
    const conditions = updateConditionList(filter.conditions, editedCondition)
    const filters = updateFilterList(reportFilter, {
      ...filter,
      conditions
    })
    setReportFilter(filters)
    if (!isText) {
      handleReportFilter()
    }
  }

  // ******************************************
  // This function handles deleting a condition
  // ******************************************
  const handleDeleteCondtion = (
    filter: TReportFilterData,
    deletedCondition: TReportConditionData
  ) => {
    if (!filter) return
    const conditions = filter.conditions.filter(
      ({ id }) => id !== deletedCondition.id
    )
    const filters = updateFilterList(reportFilter, {
      ...filter,
      conditions
    })
    setReportFilter(filters)
    // setFormData({ ...formData, filterList: filters })
  }

  // **************************
  // This function handles drop
  // **************************
  const handleDragEnd = (filterId: string, result: DropResult) => {
    const filter = reportFilter.find((filter) => filter.id === filterId)
    if (!filter) return

    const conditions = reorderConditionList(filter.conditions, result)
    const filters = updateFilterList(reportFilter, {
      ...filter,
      conditions
    })
    setReportFilter(filters)
    // setFormData({ ...formData, filterList: filters })
  }

  // ****************************
  // Handle when the date changes
  // ****************************
  const onDateChange = (
    dates: { startDate?: Date; endDate?: Date; key?: string }[]
  ) => {
    if (dates[0].startDate) setStartDate(dates[0].startDate)
    if (dates[0].endDate) setEndDate(dates[0].endDate)
  }

  useEffect(() => {
    setName(nameParam)
    setDescription(descriptionParam)
    setChartType(chartTypeParam)
  }, [nameParam, descriptionParam, chartTypeParam])

  // *************************************************************
  // Effect to Pass back the new chart and report filter to parent
  // *************************************************************
  useEffect(() => {
    if (onMutated) {
      onMutated(payload)
    }
  }, [payload])

  useEffect(() => {
    if (editedCondition && currentCallback && values.length !== 0) {
      editedCondition.values = values
      currentCallback()
    }
  }, [values])
  // ****************************
  // Query to fetch resource data
  // ****************************
  /* useQuery({
    queryKey: [queryKey],
    queryFn: async () => {
      try {
        if (queryKey !== '') {
          await axios
            .post<TProject[]>('query', {
              name: 'Resource',
              filters: convertReportFilterDataToCondition(reportFilter),
              options: { saveQuery: false },
              validateStatus(status: number) {
                return status < 500 // Resolve only if the status code is less than 500
              }
            })
            .then((res) => {
              const obj: TProject[] = res.data
              const lists: TValueOption[] = []
              // *****************************************************************************
              // Build the list of resouces and service label projects components applications
              // *****************************************************************************
              obj.forEach((proj) => {
                // lists.push({ label: proj.name, value: proj.name })
                proj.applications?.forEach((app) => {
                  // lists.push({ label: app.name, value: app.name })
                  app.components?.forEach((cmp) => {
                    // const val = cleanResourceString(cmp.name)
                    // lists.push({
                    //   label: `component:${val}`,
                    //  value: `component:${val}`
                    // })
                    cmp.traits?.forEach((trait) => {
                      if (trait.properties) {
                        const val = cleanResourceString(
                          trait.properties?.resourceId
                        )
                        lists.push({
                          label: `${val}`,
                          value: `${val}`
                        })
                      }
                    })
                  })
                })
              })
              // ************************************************
              // Add the available resources to the resource list
              // ************************************************
              setResources(lists)
            })
            .catch((error) => {
              throw new Error(`Error code ${error.status}`)
            })
        }
      } catch (error) {
        throw new Error(`Error code ${error}`)
      }
    }
  }) */

  if (error) return <div>An error has occurred</div>
  return (
    <div>
      <div className="flex items-center justify-between">
        <span className="px-5 text-base font-medium">Resource Filter</span>
      </div>
      <div className="mx-2 flex bg-white border border-dark-grey rounded-md">
        <div className="w-full">
          <div>
            <div className="flex flex-col gap-1 p-1">
              {reportFilter.map((filter) => (
                <div
                  key={filter.id}
                  className="relative flex flex-col gap-1 p-1 pt-2 border border-dark-grey rounded-md"
                >
                  {filter.type !== REPORT_FILTER_TYPE.DEFAULT && (
                    <div className="flex -top-3.5 left-3 bg-white px-2 max-w-[calc(100%-24px)]">
                      <span>
                        {filter.type === REPORT_FILTER_TYPE.LABEL
                          ? 'Labels'
                          : 'Annotations'}
                      </span>
                    </div>
                  )}
                  <DragDropContext
                    onDragEnd={(result) => handleDragEnd(filter.id, result)}
                  >
                    <Droppable droppableId={filter.id}>
                      {({ innerRef, droppableProps, placeholder }) => (
                        <div ref={innerRef} {...droppableProps}>
                          {filter.conditions.map((condition, index) => (
                            <FilterCondition
                              key={condition.id}
                              labels={labelList}
                              fields={fields}
                              fieldInitialValues={filter.type === 'default' ? initialFieldValues : []}
                              index={index}
                              type={filter.type}
                              condition={condition}
                              onInputChanged={(newCondition) => {
                                handleEditCondition(filter, newCondition, false)
                              }}
                              onChange={(newCondition, isText, setReloadCallback) => {
                                setEditedCondition(newCondition)
                                if (filter.type === 'default') {
                                  setFieldsValuesProcessed(false)
                                  setCurrentFieldViewed(newCondition.field)
                                } else {
                                  setLabelsProcessed(false)
                                  setCurrentLabelViewed(newCondition.field)
                                }
                                setCurrentCallback(() => () => setReloadCallback())
                                handleEditCondition(
                                  filter,
                                  newCondition,
                                  isText
                                )
                              }}
                              onDelete={(newCondition) => {
                                handleDeleteCondtion(filter, newCondition)
                              }}
                            />
                          ))}
                          {placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>

                  <div className="flex justify-between gap-2 text-primary-text-color">
                    <button
                      type="button"
                      className="flex gap-1 items-center cursor-pointer hover:bg-primary-color hover:bg-opacity-10 rounded-lg px-1 py-1"
                      onClick={() => handleAddCondition(filter)}
                    >
                      <AiOutlinePlus />
                      <span>Add</span>
                      <span>
                        {filter.type === REPORT_FILTER_TYPE.DEFAULT
                          ? 'condition'
                          : filter.type}
                      </span>
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="flex flex-col sm:flex-row gap-4 px-5 py-3 text-primary-text-color">
            <button
              type="button"
              className="flex gap-1 items-center cursor-pointer hover:bg-primary-color hover:bg-opacity-10 disabled:bg-transparent disabled:text-gray-300 disabled:cursor-not-allowed rounded-lg px-2 py-1"
              disabled={reportFilter.some(
                (filter) => filter.type === REPORT_FILTER_TYPE.DEFAULT
              )}
              onClick={() => handleAddFilter(REPORT_FILTER_TYPE.DEFAULT)}
            >
              <TiFolderAdd />
              <span>Add condition filter</span>
            </button>

            <button
              type="button"
              className="flex gap-1 items-center cursor-pointer hover:bg-primary-color hover:bg-opacity-10 disabled:bg-transparent disabled:text-gray-300 disabled:cursor-not-allowed rounded-lg px-2 py-1"
              disabled={reportFilter.some(
                (filter) => filter.type === REPORT_FILTER_TYPE.LABEL
              )}
              onClick={() => handleAddFilter(REPORT_FILTER_TYPE.LABEL)}
            >
              <TiFolderAdd />
              <span>Add label filter</span>
            </button>
          </div>
        </div>
      </div>
      <div className="px-2">
        <div className="flex bg-white border border-dark-grey rounded-md">
          <div className="p-2 w-1/2 border-r">
            <span className="flex text-gray-500 text-xs font-normal mb-1">
              Window
            </span>
            <DateRangePickerComponent
              size="sm"
              startDate={startDate}
              endDate={endDate}
              handleChange={onDateChange}
            />
          </div>
          <div className="p-2 w-1/4 border-r">
            <span className="flex text-gray-500 text-xs font-normal mb-1">
              step
            </span>
            <div className="flex w-full">
              <div className="flex flex-col w-1/2">
                <RegExInput
                  size="sm"
                  type="text"
                  pattern={re}
                  value={stepDuration}
                  onChange={(event) => {
                    setStepDuration(
                      cleanIntToString(
                        event.target.value,
                        stepUnit === 'h' ? 24 : 30
                      )
                    )
                  }}
                  errorMessage="Numeric values only"
                />
              </div>
              <Select
                name="stepUnit"
                id="stepUnit"
                size="sm"
                widthStyle="w-full px-2"
                value={stepUnit}
                options={stepPeriodOptions}
                onChange={(event) => setStepUnit(event.target.value)}
              />
            </div>
          </div>
          <div className="p-2 w-1/2">
            <span className="flex text-gray-500 text-xs font-normal mb-1">
              Chart value
            </span>
            <Select
              name="chartValue"
              id="chartValue"
              size="sm"
              options={focusFieldOptions}
              value={focusField}
              onChange={(event) => setFocusField(event.target.value)}
            />
          </div>
        </div>

        <GraphCard>
          <button
            type="button"
            className="bg-sb-button-color  rounded-lg hover:bg-blue-500  hover:bg-opacity-10  py-1 mx-10 text-white"
            onClick={handleFilter}
          >
            Filter
          </button>
        </GraphCard>
      </div>
    </div>
  )
}

export default FilterEditor
