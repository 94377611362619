import { v4 as uuid } from 'uuid'
import { TTableColumn } from 'components'
import { formatNilValue } from 'utils'
import {
  TLabelRule,
  TLabelRuleRule,
  TLabelRuleItemData,
  TLabelRuleOperatorOption,
  TLabelRuleFormData,
  TLabelRuleEffect,
  TLabelRuleDisplay,
  TLabelRuleRulesDisplay,
  TLabelRuleEffectsDisplay
} from '../../services/label.rule.interface'

// *******************************
// Blank data label rules API
// *******************************
export const BLANK_LABEL_RULE: TLabelRule = {
  name: '',
  rules: [],
  description: '',
  enabled: true,
  effects: []
}
export const BLANK_LABEL_RULE_RULE: TLabelRuleRule = {
  key: '',
  operand: 'equals',
  value: ''
}
export const BLANK_LABEL_RULE_EFFECT: TLabelRuleEffect = { key: '', value: '' }

// ***************************************
// Blank data MutationNewLabelRuleModal
// ***************************************
export const BLANK_FORM_DATA_LABEL_RULE: TLabelRuleFormData = {
  id: uuid(),
  name: '',
  rules: [],
  description: '',
  enabled: true,
  effects: []
}
export const BLANK_FORM_DATA_LABEL_RULE_RULE: TLabelRuleItemData = {
  id: uuid(),
  key: '',
  type: 'rules',
  operand: 'equals',
  value: ''
}
export const BLANK_FORM_DATA_LABEL_RULE_EFFECT: TLabelRuleItemData = {
  id: uuid(),
  key: '',
  type: 'effects',
  operand: undefined,
  value: ''
}

// **********************
// Blank data for display
// **********************
export const BLANK_DISPLAY_LABEL_RULE: TLabelRuleDisplay = {
  id: uuid(),
  name: '',
  rules: [],
  description: '',
  enabled: true,
  effects: []
}
export const BLANK_DISPLAY_LABEL_RULE_RULE_ITEM_: TLabelRuleRulesDisplay = {
  id: uuid(),
  key: '',
  operand: 'equals',
  value: ''
}
export const BLANK_DISPLAY_LABEL_RULE_EFFECT_ITEM: TLabelRuleEffectsDisplay = {
  id: uuid(),
  key: '',
  value: ''
}

export const LABEL_RULE_ELEMENT_TYPE: Record<
  'RULES' | 'EFFECTS',
  TLabelRuleItemData['type']
> = {
  RULES: 'rules',
  EFFECTS: 'effects'
}

export type TLabelRuleFocusOption = {
  label: string
  value: string
}
export const LABEL_RULES_FOCUS_OPTIONS: TLabelRuleFocusOption[] = [
  { label: 'billing_period_end', value: 'billing_period_end' },
  { label: 'billing_period_start', value: 'billing_period_start' },
  { label: 'charge_period_end', value: 'charge_period_end' },
  { label: 'charge_period_start', value: 'charge_period_start' },
  { label: 'effective_cost', value: 'effective_cost' },
  { label: 'list_cost', value: 'list_cost' },
  { label: 'list_unit_price', value: 'list_unit_price' },
  { label: 'pricing_quantity', value: 'pricing_quantity' },
  // { label: 'pricing_unit', value: 'pricing_unit' },
  { label: 'billed_cost', value: 'billed_cost' },
  { label: 'usage_quantity', value: 'usage_quantity' },
  { label: 'availability_zone', value: 'availability_zone' },
  { label: 'billing_account_id', value: 'billing_account_id' },
  { label: 'billing_account_name', value: 'billing_account_name' },
  { label: 'billing_currency', value: 'billing_currency' },
  { label: 'charge_category', value: 'charge_category' },
  { label: 'charge_description', value: 'charge_description' },
  { label: 'charge_frequency', value: 'charge_frequency' },
  { label: 'charge_subcategory', value: 'charge_subcategory' },
  {
    label: 'commitment_discount_category',
    value: 'commitment_discount_category'
  },
  { label: 'commitment_discount_id', value: 'commitment_discount_id' },
  { label: 'commitment_discount_name', value: 'commitment_discount_name' },
  { label: 'commitment_discount_type', value: 'commitment_discount_type' },
  { label: 'invoice_issuer', value: 'billing_period_end' },
  { label: 'pricing_category', value: 'invoice_issuer' },
  { label: 'provider', value: 'provider' },
  { label: 'publisher', value: 'publisher' },
  { label: 'region', value: 'region' },
  { label: 'resource_id', value: 'resource_id' },
  { label: 'resource_name', value: 'resource_name' },
  { label: 'resource_type', value: 'resource_type' },
  { label: 'service_category', value: 'service_category' },
  { label: 'service_name', value: 'service_name' },
  { label: 'sku_id', value: 'sku_id' },
  { label: 'sku_price_id', value: 'sku_price_id' },
  { label: 'sub_account_id', value: 'sub_account_id' },
  { label: 'sub_account_name', value: 'sub_account_name' },
  { label: 'usage_unit', value: 'usage_unit' }
]

export const LABEL_RULES_OPERATOR_STRING_OPTIONS: TLabelRuleOperatorOption[] = [
  { label: 'equal to', value: 'equals' },
  { label: 'not equal to', value: 'notEquals' },
  { label: 'contains', value: 'contains' },
  { label: 'starts with', value: 'startsWith' },
  { label: 'ends with', value: 'endsWith' },
]

export const LABEL_RULES_OPERATOR_NUMERIC_OPTIONS: TLabelRuleOperatorOption[] = [
  { label: 'equal to', value: 'equals' },
  { label: 'not equal to', value: 'notEquals' },
  { label: 'grater than', value: 'greaterThan' },
  { label: 'grater than equal to', value: 'greaterThanAndEqualsTo' },
  { label: 'less than', value: 'lessThan' },
  { label: 'less than equal to', value: 'lessThanAndEqualsTo' }
]

export const LABEL_RULES_FILTER_TABLE_COLUMNS: TTableColumn[] = [
  {
    name: 'labelRule',
    field: 'name',
    width: '33%',
    format: formatNilValue
  },
  {
    name: 'Description',
    field: 'description',
    width: 'auto',
    format: formatNilValue
  }
]

export const LABEL_RULE_RULES_TABLE_COLUMNS: TTableColumn[] = [
  { name: 'Key', field: 'key', width: 200 },
  {
    name: 'Operand',
    field: 'operand',
    width: 200,
    format: formatNilValue
  },
  {
    name: 'Value',
    field: 'value',
    width: 200,
    format: formatNilValue
  }
]

export const LABEL_RULE_EFFECTS_TABLE_COLUMNS: TTableColumn[] = [
  { name: 'Name', field: 'key', width: 200 },
  {
    name: 'Value',
    field: 'value',
    width: 200,
    format: formatNilValue
  }
]
