import { Link } from 'react-router-dom'

import { TTableColumn } from 'components'
import { formatCost, formatNilValue } from 'utils'

export type TFormInput = {
  label: string
  placeholder: string
  type: string
  required: boolean
  disabled: boolean
  value?: string | null
  key: string
}

export type TFormFieldSet = {
  heading: string
  inputs: TFormInput[]
}

export const PROJECT_TABLE_COLUMNS: TTableColumn[] = [
  {
    name: 'Name',
    field: 'name',
    width: '44%',
    format: formatNilValue
  },
  {
    name: 'Daily Cost',
    field: 'daily',
    width: '17%',
    format: formatCost
  },
  {
    name: 'Weekly Cost',
    field: 'weekly',
    width: '17%',
    format: formatCost
  },
  {
    name: 'Monthly Cost',
    field: 'monthly',
    width: '17%',
    format: formatCost
  },
  {
    name: '',
    field: 'link',
    width: 110,
    sortable: false,
    filterable: false,
    render: (value: string) => (
      <Link
        className="bg-primary-color text-white px-4 py-2 rounded-sm"
        to={value}
      >
        Details
      </Link>
    )
  }
]
