import { Tooltip } from '@mui/material'
import { ButtonIcon } from 'components'
import { FaRegSquareCheck } from 'react-icons/fa6'
import { MdOutlineDisabledByDefault } from 'react-icons/md'

export function createFieldLabel(el: string) {
  const split = el.split('_')
  if (split.length !== 1) {
    let res = `${split[0][0].toUpperCase()}${split[0].slice(1)}`
    split.shift()
    split.forEach((el) => {
      res += ` ${el[0].toUpperCase()}${el.slice(1)}`
    })
    return res
  }
  return `${el[0].toUpperCase()}${el.slice(1)}`
}

export const isString = (column: string) => {
  switch (column) {
    case 'effective_cost':
      return false
    case 'list_cost':
      return false
    case 'list_unit_price':
      return false
    case 'pricing_quantity':
      return false
    case 'billed_cost':
      return false
    case 'usage_quantity':
      return false
    case 'billing_account_name':
      return false
    case 'billing_account_id':
      return false
    case 'billing_period_end':
      return false
    case 'billing_period_start':
      return false
    case 'charge_period_end':
      return false
    case 'charge_period_start':
      return false
    case 'consumed_quantity':
      return false
    default:
      return true
  }
}
export function loadAutoCompleteIcon(type: string, compareType: string, tag: string) {
  if (type === compareType) {
    if (isString(tag)) {
      return (
        <span>
          AC:
          <Tooltip title="Autocomplete available">
            <ButtonIcon>
              <FaRegSquareCheck size={20} color="green" />
            </ButtonIcon>
          </Tooltip>
        </span>
      )
    } return (
      <span>
        AC:
        <Tooltip title="Autocomplete unavailable">
          <ButtonIcon>
            <MdOutlineDisabledByDefault size={20} color="red" />
          </ButtonIcon>
        </Tooltip>
      </span>
    )
  } return <div />
}
