import {
  Cell,
  LabelList,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer
} from 'recharts'
import { TPieChartData } from 'services/chart.interface'
import { TCurrency } from 'services/cost.interface'

function legendOver(str: string, chartValue: string) {
  const element = document.getElementById(str)!;
  const newstr = str.concat(' : ').concat(chartValue)
  element.innerHTML = newstr
}
function legendOut(value: string, str: string, chartValue: string) {
  const element = document.getElementById(value)!;
  const newstr = str.concat(' : ').concat(chartValue)
  element.innerHTML = newstr
}

const splitter = (value: string) => {
  let temp = value
  if (value.length > 10) {
    temp = value.substr(0, 10).concat('...')
  } else {
    temp = value
  }
  return temp
};

interface PieChartComponentProps {
  data: TPieChartData[]
  currency?: TCurrency
}

const renderLegend = (props: any) => {
  const legendPayload = props.payload;
  return (
    <ul style={{ overflow: 'auto', height: '250px', width: '250px' }}>
      {
        legendPayload.map((
          entry: {
            value: any;
            color: any;
            payload: any
          },
          index: any
        ) => (
          <li
            style={{ display: 'list-item', color: `${entry.color}`, listStyleType: 'disc', listStylePosition: 'inside' }}
            key={`item-${index}`}
            id={entry.value}
            onMouseOver={() => {
              legendOver(entry.value, entry.payload.value)
            }}
            onFocus={() => {
              legendOver(entry.value, entry.payload.value)
            }}
            onMouseOut={() => {
              legendOut(entry.value, splitter(entry.value), entry.payload.value)
            }}
            onBlur={() => {
              legendOut(entry.value, splitter(entry.value), entry.payload.value)
            }}
          >
            {splitter(entry.value)} : {entry.payload.value}
          </li>
        ))
      }
    </ul>
  );
}

function PieChartComponent({ data, currency }: PieChartComponentProps) {
  return (
    <ResponsiveContainer>
      <PieChart height={250}>

        <Pie
          data={data}
          dataKey="value"
          nameKey="name"
          innerRadius={0}
          outerRadius={100}
          fill="#82ca9d"

        >
          <LabelList
            dataKey="value"
            position="inside"
            formatter={(value: number) => `${currency?.symbol || ''}${value}`}
          />

          {data.map((entry) => (
            <Cell key={entry.color} fill={entry.color} />
          ))}
        </Pie>
        <Legend content={renderLegend} height={36} layout="vertical" align="left" verticalAlign="top" />
      </PieChart>
    </ResponsiveContainer>
  )
}

export default PieChartComponent
