import {
  useEffect,
  useMemo,
  useState
} from 'react';
import { useQuery } from 'react-query';
import { axios } from 'utils';
import GridLayout, { Responsive, WidthProvider } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import './customStyle.css';
import useAuthentication from 'hooks/useAuthentication';
import { ButtonIcon, GraphCard } from 'components';
import { MdDragIndicator } from 'react-icons/md';
import { SlOptionsVertical } from 'react-icons/sl';
import { effectData } from 'pages/label-rules/mutation-new-label-rule-model/hierarchyHelperFunctions';
import { returnElementByTag } from './widgetHelpFunctions';
import { DeletionModal } from './deletionModal';
import { createModal } from './editModal';
import { WidgetData } from './widgetStatev2';

type draggableComponent = { markup: JSX.Element, id: string }
interface DragDropProps {
  labels: string[],
  fields: string[],
  widgetObject: WidgetData
}

export function DragDropComponent({ labels, fields, widgetObject }: DragDropProps) {
  const { currency } = useAuthentication()
  const [layoutConfig, setLayoutConfig] = useState<GridLayout.Layout[]>([]);
  const [draggableComponentList, setDraggableComponentList] = useState<draggableComponent[]>([]);
  const [toUpdate, setToUpdate] = useState<boolean>(false)
  const [intitialLoad, setInitialLoad] = useState<boolean>(true)
  const [stopDrag, setStopDrag] = useState<boolean>(false)
  const [show, setShow] = useState<string>('')
  const toUpdateCallback = () => setToUpdate(!toUpdate)
  let createDraggableMarkup: () => draggableComponent[]
  const renewDraggableMarkup = () => {
    setDraggableComponentList(createDraggableMarkup())
  }
  const ResponsiveGridLayout = useMemo(() => WidthProvider(Responsive), []);
  const [deletionConfirm, setDeletionConfirm] = useState<string>('')

  const [labelRuleDetailsListProcessed, setLabelRuleDetailsListProcessed] = useState<boolean>(false)
  const [labelRuleDetailsList, setLabelRuleDetailsList] = useState<effectData[]>([])

  // ***************************************************************************
  // Will be called when a component is made larger.
  // Allows for it to be updated when the user drags the corner
  // ***************************************************************************

  useEffect(() => {
    if (toUpdate) {
      renewDraggableMarkup()
      toUpdateCallback()
    }
  }, [toUpdate])

  const stopDeleteAction = () => {
    setDeletionConfirm('')
    renewDraggableMarkup()
  }

  // ***************************************************************************
  // The API call to labels, this to be able to pass it to the widgets that need it
  // We do this here since we don't want to call this for each widget individually.
  // PERFORMANCE!
  // ***************************************************************************
  const { error } = useQuery({
    queryKey: ['query?GetTheWidgetLabels'],
    queryFn: async () => {
      try {
        if (!labelRuleDetailsListProcessed) {
          await axios
            .get('/labels/labelRuleDetails')
            .then((res) => {
              setLabelRuleDetailsList(res.data.labelRuleDetails)
              setLabelRuleDetailsListProcessed(true)
            })
            .catch((error) => {
              throw new Error(`Error code ${error.status}`)
            })
        }
      } catch (error) {
        throw new Error(`Error code ${error}`)
      }
    }
  })

  // ***************************************************************************
  // Make draggable markup (components that can be dragged) for a widget that will
  // be returned by returnElementByTag when it is given tag.
  // ***************************************************************************

  function draggableMarkup(tag: string, id: string) {
    const editCallback = () => {
      if (show === id) {
        setShow('')
      } else setShow(id)
      renewDraggableMarkup()
    }
    const turorialEditCloseCallback = () => {
      setShow('')
    }
    const deleteWidget = () => {
      setDeletionConfirm(id)
      renewDraggableMarkup()
    }
    const child =
      returnElementByTag(
        tag,
        id,
        currency,
        labelRuleDetailsList,
        fields,
        editCallback,
        turorialEditCloseCallback,
        deleteWidget,
        widgetObject,
        labels
      )
    return (
      <div key={id} className="border-2 border-[#668957] rounded-lg bg-white shadow-2xl">
        <div
          style={{ width: '100%', display: 'inline-flex' }}
        >
          {!stopDrag && (
            <ButtonIcon className="dragIcon" style={{ width: '30px' }}>
              <MdDragIndicator size={20} />
            </ButtonIcon>
          )}
          {tag === 'projectTable' && (
            <span className="w-full text-sm px-2"> Aggregated cost graph </span>
          )}
          {tag === 'labeledDataChart' && (
            <span className="w-full text-sm px-2"> Grouped cost graph </span>
          )}
          {tag === 'cumulativeCost' && (
            <span className="w-full text-sm px-2"> Grouped cost graph </span>
          )}
          <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
            <button
              type="button"
              data-tour={`theOptionsButtonFor${id}`}
              className="text-sb-button-color"
              style={{ paddingTop: '2px' }}
              onClick={editCallback}
            >
              <SlOptionsVertical size={15} />
              <div
                key={`dropDownMenuOptions${id}`}
                className={`dropdown-body w-48 absolute border-2 border-gray-200 bg-icon-color rounded ${show === id && 'dropdown-open'} top-7 right-0`}
                style={{ zIndex: 999 }}
              >
                {createModal(widgetObject.getOptions(id), id)}
              </div>
              <div
                key={`deletionModalContainer${id}`}
                className={`dropdown-body w-48 absolute border-2 border-gray-200 bg-icon-color rounded ${deletionConfirm === id && 'dropdown-open'} top-7 right-0`}
                style={{ zIndex: 999 }}
              >
                <DeletionModal
                  key={`theWidgetDeletionModal${id}`}
                  isOpen
                  id={deletionConfirm}
                  changeId={stopDeleteAction}
                  widgetObject={widgetObject}
                />
              </div>
            </button>
          </div>
        </div>
        <div
          className="grow shrink basis-0 min-w-0 w-full flex px-4 py-3"
          style={{ gap: 10, minHeight: '95%' }}
        >
          <GraphCard className="overflow-x-auto w-full" isOutline={false}>
            {child}
          </GraphCard>
        </div>
      </div>

    )
  }

  // ***************************************************************************
  // Make draggable markup (components that can be dragged) for a widget that will
  // be returned by returnElementByTag when it is given tag.
  // ***************************************************************************

  createDraggableMarkup = () => {
    const layout: GridLayout.Layout[] = []
    if (widgetObject.widgets) {
      const draggableComponents: draggableComponent[] = widgetObject.widgets.map((el) => {
        layout.push({
          i: el.state.id,
          x: el.state.x,
          y: el.state.y,
          w: el.state.w,
          h: el.state.h,
          minW: 4,
          minH: 12,
          maxH: 18,
          static: false
        })
        return { markup: draggableMarkup(el.tag, el.state.id), id: el.state.id }
      })
      setLayoutConfig(layout)
      return draggableComponents
    } return []
  }

  // ***************************************************************************
  // Once the labels are fetched reload the widgets.
  // ***************************************************************************
  useEffect(() => {
    renewDraggableMarkup()
  }, [stopDrag, show])

  // ***************************************************************************
  // when there are more widgets than draggable components, you have added a widget
  // add it as a component to the drag context.
  // widgetList only has the data for the widgets (which will be fetched in the future , now is in
  // localstorage) draggable components are the actual widgets people can see.
  // ***************************************************************************
  useEffect(() => {
    if (widgetObject.widgets) {
      if (draggableComponentList.length !== widgetObject.widgets.length) {
        renewDraggableMarkup()
      }
    }
  })
  useEffect(() => {
    setInitialLoad(false)
  }, [layoutConfig])
  // Define the layout configuration for each grid item
  function layoutTranslation(layoutConfig: GridLayout.Layout[], cols: number) {
    let shift = 0
    const res: GridLayout.Layout[] = []
    layoutConfig.forEach((el) => {
      res.push({
        i: el.i,
        x: 0,
        y: shift,
        w: cols,
        h: el.h,
        minW: 4,
        minH: 12,
        maxH: 18,
        static: true
      })
      if (el.x === 0 || el.y === 0) {
        shift += el.h + 1
      } else {
        shift += el.h
      }
    })
    return res
  }

  if (error) return <div>An error has occurred</div>
  return (
    <div key="theMainDragFunctionalityLayout">
      <div>
        {!intitialLoad && (
          <ResponsiveGridLayout
            className="layout"
            layouts={{
              md: layoutConfig,
              sm: layoutTranslation(layoutConfig, 6),
            }}
            rowHeight={30}
            breakpoints={{ md: 996, sm: 768 }}
            cols={{ md: 12, sm: 6 }}
            draggableHandle=".dragIcon"
            onBreakpointChange={(event) => {
              if (event === 'md') setStopDrag(false)
              if (event === 'sm') setStopDrag(true)
            }}
            onDrag={(layout) => {
              layout.forEach((el) => {
                const { i, x, y, w, h } = el
                widgetObject.editXYWH(i, x, y, w, h)
              })
            }}
            onResize={(layout) => {
              layout.forEach((el) => {
                const { i, x, y, w, h } = el
                widgetObject.editXYWH(i, x, y, w, h)
              })
            }}
          >
            {draggableComponentList.map((el) => {
              return el.markup
            })}
          </ResponsiveGridLayout>
        )}
      </div>
    </div>
  );
}
