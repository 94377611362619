import { TTableColumn } from 'components'
import { formatCost, formatNilValue } from 'utils'

export const APPLICATION_TABLE_COLUMNS: TTableColumn[] = [
  {
    name: 'Name',
    field: 'name',
    width: '40%',
    format: formatNilValue
  },
  {
    name: 'Component',
    field: 'component',
    width: '15%',
    format: formatNilValue
  },
  {
    name: 'Daily Cost',
    field: 'daily',
    width: '15%',
    format: formatCost
  },
  {
    name: 'Weekly Cost',
    field: 'weekly',
    width: '15%',
    format: formatCost
  },
  {
    name: 'Monthly Cost',
    field: 'monthly',
    width: '15%',
    format: formatCost
  }
]
