import { GraphCardContent, Select, Table } from 'components'
import { useEffect, useMemo, useState } from 'react'
import { TCurrency } from 'services/cost.interface'
import { ResponsiveContainer, PieChart, Pie, Cell, Legend } from 'recharts'
import { CHART_COLORS } from 'constants/color.constants'
import { BsTable } from 'react-icons/bs'
import { FaChartPie } from 'react-icons/fa'
import { OptimizationTableColumns } from './constants'
import { editOptions, resetOptions } from '../project-widget/widgetState'
import { renderLabel } from './helper'
// import { renderLegend } from '../project-labeled-data-widget/chartHelperFunctions'

interface OptiminzationWidgetProps {
  id: string
  currency: TCurrency
  editCallBack: () => void
  closeCallBack: () => void
  deleteWidget: () => void
}

export function OptiminzationWidget({ id,
  currency,
  editCallBack,
  closeCallBack,
  deleteWidget
}: OptiminzationWidgetProps) {
  resetOptions(id)
  const formatOptions = useMemo(() => ({ currency }), [currency])
  const [editBool, setEditBool] = useState<boolean>(false)
  const [pieData, setPieData] = useState<{ name: string, value: number, color: string }[]>([])
  const [chosenValue, setChosenValue] = useState<string>('MonthlyCost')
  const [typesOfValues] = useState<{ label: string, value: string }[]>([
    { label: 'Monthly costs', value: 'MonthlyCost' },
    { label: 'Monthly savings', value: 'MonthlySaving' }
    // , 'SavingPercentage'
  ])
  const [chosenSymbol, setChosenSymbol] = useState<string>(currency.symbol)
  const changeEditBool = () => {
    setEditBool(!editBool)
  }
  // ***************************************************************************
  // Add the Edit widget to the options of this widget.
  // ***************************************************************************
  editOptions(id, 'Change view', changeEditBool)
  editOptions(id, 'Delete widget', deleteWidget)

  type optimizationData = {
    [index: string]: any
    done: boolean,
    name: string,
    Action: string,
    MonthlyCost: number,
    MonthlySaving: number,
    SavingPercentage: number,
    restart: string,
    rollback: string
  }
  const data: optimizationData[] = useMemo(() => [{
    done: true,
    name: 'EbsVolume',
    Action: 'updgrade',
    MonthlyCost: 2.2,
    MonthlySaving: 0.44,
    SavingPercentage: 20,
    restart: 'no',
    rollback: 'yes'
  }, {
    done: false,
    name: 'Ec2Instance',
    Action: 'MigrateToGraviton',
    MonthlyCost: 9.171,
    MonthlySaving: 2.475,
    SavingPercentage: 27,
    restart: 'yes',
    rollback: 'yes'
  }].sort((a, b) => b.MonthlySaving - a.MonthlySaving), [])

  useEffect(() => {
    console.log(editCallBack)
    console.log(closeCallBack)
    console.log(setChosenValue)
    let ctr = -1
    setPieData(data.map((el) => {
      ctr++
      const value: number = el[chosenValue]
      return {
        name: el.name,
        value,
        color: CHART_COLORS[ctr]
      }
    }))
  }, [data, chosenValue])

  useEffect(() => {
    console.log(pieData)
  }, [pieData])
  return (
    <div>
      {!editBool && (
        <div className="h-full">
          <div className="flex flex-row">
            <BsTable size={20} />
            <span className="px-2 w-1/2">Table of possible optimizations</span>
          </div>
          <div>
            <GraphCardContent minHeight={250} className=" py-2 h-3/4 w-7/8">
              <Table
                columns={OptimizationTableColumns}
                data={data}
                formatOptions={formatOptions}
              />
            </GraphCardContent>
          </div>
        </div>
      )}
      {editBool && (
        <div>
          <div className="flex flex-row">
            <FaChartPie size={20} />
            <span className="px-2 w-1/2">Chart of {chosenValue}</span>
          </div>
          <Select
            options={typesOfValues}
            onChange={(event) => {
              const val = event.target.value
              setChosenValue(val)
              if (val !== 'SavingPercentage') {
                setChosenSymbol(currency.symbol)
              } else {
                setChosenSymbol('%')
              }
            }}
          />
        </div>
      )}
      {editBool && (
        <GraphCardContent minHeight={350} className="h-3/4">
          <ResponsiveContainer>
            <PieChart>
              <Pie
                data={pieData}
                dataKey="value"
                nameKey="name"
                innerRadius={0}
                labelLine={false}
                cx="50%"
                cy="50%"
                fill="#82ca9d"
                label={(value) => {
                  return renderLabel(value, chosenSymbol)
                }}
              >
                {pieData.map((entry) => (
                  <Cell key={entry.color} fill={entry.color} />
                ))}
              </Pie>
              <Legend
                //  content={renderLegend(pieData, id, currency)}
                height={36}
                layout="horizontal"
                verticalAlign="top"
              />
            </PieChart>
          </ResponsiveContainer>
        </GraphCardContent>
      )}
    </div>
  )
}
