import { useMemo } from 'react'

import { TCurrency } from 'services/cost.interface'
import { TProjectEntityNames } from 'services/project.interface'
import {
  PieChartComponent,
  FetchStatus,
  GraphCardContent
} from 'components'
import useProject from 'hooks/useProject'
import { mapBarChartData } from 'utils'
import { PROJECT_ENTITY_NAMES } from 'constants/project.constants'

interface ProjectAllocationCostCardProps {
  type: TProjectEntityNames
  currency: TCurrency
}

export function ProjectAllocationCostCard({
  type,
  currency,
}: ProjectAllocationCostCardProps) {
  const {
    fetchProjectsStatus,
    projectList,
    applicationList,
    componentList,
    fetchProjects
  } = useProject()

  const labelName = useMemo(
    () => `${type.slice(0, 1).toUpperCase()}${type.slice(1)}`,
    [type]
  )

  const chartData = useMemo(() => {
    if (type === PROJECT_ENTITY_NAMES.PROJECT) {
      return mapBarChartData(projectList, 'monthlyCost')
    }
    if (type === PROJECT_ENTITY_NAMES.APPLICATION) {
      return mapBarChartData(applicationList, 'monthlyCost')
    }
    if (type === PROJECT_ENTITY_NAMES.COMPONENT) {
      return mapBarChartData(componentList, 'monthlyCost')
    }
    return []
  }, [type, projectList, applicationList, componentList])

  return (
    <div>
      <span className="text-lg font-medium text-gray-800">
        {labelName} monthly cost allocation
      </span>
      <GraphCardContent minHeight={400}>
        <FetchStatus status={fetchProjectsStatus} retry={fetchProjects}>
          <PieChartComponent data={chartData} currency={currency} />
        </FetchStatus>
      </GraphCardContent>
    </div>
  )
}
