import {
  useEffect, useState, useMemo, useRef,
} from 'react'
import {
  Bar,
  BarChart,
  Cell,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts'

import { TBarChartData } from 'services/chart.interface'
import { TCurrency } from 'services/cost.interface'

import { formatChartDateLabel, getTimstampList } from 'utils'

interface BarChartProps {
  data: TBarChartData[]
  type?: 'date'
  startDate?: Date | null
  endDate?: Date | null
  currency?: TCurrency
}

function BarChartComponent({
  data, type, startDate, endDate, currency,
}: BarChartProps) {
  const containerRef = useRef<HTMLDivElement | null>(null)
  const keepRef = useRef<{ displayData: TBarChartData[] }>({
    displayData: []
  })

  const [containerWidth, setContainerWidth] = useState(0)

  const displayData = useMemo(() => {
    if (type !== 'date') return data

    if (!startDate || !endDate) return keepRef.current.displayData

    const labelList = getTimstampList(startDate, endDate).map(
      (timestamp) => formatChartDateLabel(timestamp)
    )

    keepRef.current.displayData = labelList.map((label) => {
      const item = data.find(({ name }) => name === label)
      return {
        name: label,
        value: item?.value || 0,
        color: item?.color || ''
      }
    })

    return keepRef.current.displayData
  }, [type, data, startDate, endDate])

  const tickFormatter = (value: string) => {
    if (!value || !displayData.length) return ''
    const singleWidth = Math.floor(containerWidth / displayData.length)

    let limit = Math.floor(singleWidth / 10)
    if (displayData.length === 1) {
      limit = Math.floor(singleWidth / 15)
    }

    if (value.length <= limit) return value
    return `${value.substring(0, limit - 3).trim()}...`
  }

  // watch container's width
  useEffect(() => {
    const containerElement = containerRef.current
    const observer = new ResizeObserver(([entry]) => {
      if (entry.contentBoxSize) {
        const contentBoxSize = entry.contentBoxSize[0]
        setContainerWidth(contentBoxSize.inlineSize)
      }
    })

    if (containerElement) {
      observer.observe(containerElement)
    }
    return () => {
      observer.disconnect()
    }
  }, [])

  return (
    <div ref={containerRef} className="w-full h-full">
      <ResponsiveContainer>
        <BarChart data={displayData}>
          <XAxis dataKey="name" interval={0} tickFormatter={tickFormatter} />
          <YAxis dataKey="value" tickFormatter={(value) => `${currency?.symbol || ''}${value}`} />
          {/* set "string" type for value to avoid typescript error */}
          <Tooltip formatter={(value: string) => `${currency?.symbol || ''}${value}`} />
          <Bar dataKey="value" fill="#009bff">
            {displayData.map((entry) => (
              <Cell key={entry.color} fill={entry.color} />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  )
}

export default BarChartComponent
