export const formatChartDateLabel = (time: number | string) => {
  let timestamp = 0;
  if (typeof time === 'number') {
    timestamp = time * 1000
  }
  return new Date(timestamp || time).toLocaleDateString('en-US', {
    day: 'numeric',
    month: 'short',
    year: 'numeric'
  })
}
