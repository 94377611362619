import { useEffect, useState } from 'react'
import { FaUser } from 'react-icons/fa'
import './index.scss'
import { useQuery } from 'react-query';
import { axios } from 'utils/axios';
import LogOutKeyCloakButton from 'components/key-cloak-logout-button'
// import Search from './search'
import { ShowProfile } from './show-profile'

export type profile = { name: string, lastName: string, company: string, email: string }

function TopBar() {
  const [isOpen, setOpen] = useState(false)
  const [showProfileBool, setShowProfileBool] = useState(false)
  const [profileData, setProfileData] = useState<profile>()
  const [dataProcessed, setDataProcessed] = useState<boolean>(false)
  const [name, setName] = useState<string>('')
  const [lastName, setLastName] = useState<string>('')
  const toggleDropdown = () => {
    if (!showProfileBool) {
      setOpen(!isOpen)
    }
  }
  const toggleShowProfile = () => {
    setShowProfileBool(!showProfileBool)
  }

  const { error } = useQuery({
    queryKey: ['query?'],
    queryFn: async () => {
      try {
        if (!dataProcessed) {
          await axios
            .get('auth/profile')
            .then((res) => {
              setProfileData(res.data)
              setDataProcessed(true)
            })
            .catch((error) => {
              throw new Error(`Error code ${error.status}`)
            })
        }
      } catch (error) {
        throw new Error(`Error code ${error}`)
      }
    }
  })

  useEffect(() => {
    if (profileData !== undefined) {
      setName(profileData.name)
      setLastName(profileData.lastName)
    }
  })

  if (error) return <div>An error has occurred</div>
  return (
    <div className="flex phone:justify-end items-center w-full p-4 phone:py-4 phone:px-5 tablet:px-10 bg-top-bar-color">
      <div className="flex items-center justify-between phone:justify-center w-full phone:w-auto">
        {/* <Search /> */}
        <LogOutKeyCloakButton />
        <div
          className="pl-3 phone:px-3 flex flex-col items-center cursor-pointer"
          onClick={toggleDropdown}
        >
          <div className="w-8 h-8 object-contain border border-icon-color rounded-full">
            <FaUser size={22} className="mt-1 ml-1 text-icon-color" />
          </div>
          <span className="text-sm font-medium text-icon-color" style={{ textAlign: 'center' }}>
            {`${name} ${lastName}`}
          </span>
          <div
            className={`dropdown-body w-24 absolute border-2 border-gray-200 top-20 right-5 bg-icon-color rounded z-10 ${isOpen && 'dropdown-open'
            }`}
          >
            <div
              className="border-b border-gray-100 hover:bg-gray-50 py-2 px-2"
              onClick={() => {
                setOpen(false)
                toggleShowProfile()
              }}
            >
              Profile
            </div>
            {showProfileBool && (
              <div>
                <ShowProfile showProfileBool data={profileData} onClose={toggleShowProfile} />
              </div>
            )}
            <p className="py-2 px-2 hover:bg-gray-50">Setting</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TopBar
