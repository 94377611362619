const DAY_SECONDS = 24 * 60 * 60

export const getTimstampList = (startDate: Date, endDate: Date) => {
  const list: number[] = []
  const startTime = Math.floor(new Date(startDate).getTime() / 1000)
  const endTime = Math.floor(new Date(endDate).getTime() / 1000)

  for (let time = startTime; time <= endTime; time += DAY_SECONDS) {
    list.push(time)
  }

  return list
}
