import { FaAngleDown, FaAngleUp, FaCheck, FaPlus } from 'react-icons/fa'
import { FaXmark } from 'react-icons/fa6'
import { MdDelete } from 'react-icons/md'

function Component(
  place: JSX.Element,
  tag: string,
  bordercolor: string,
  bgColor: string,
  textColor: string,
  icon: JSX.Element,
  deleteButton: JSX.Element
) {
  return (
    <div
      className={`border-2 border-${bordercolor} rounded-lg text-lg
      text-center text-${textColor} bg-${bgColor} max-w-full`}
    >
      <div className=" flex place-items-center justify-between max-w-full">
        {place}
        {tag}
        <div className="flex place-items-center">
          <div className="px-3">
            {deleteButton}
          </div>
          <div>
            {icon}
          </div>
        </div>
      </div>
    </div>
  )
}
export function selectedComponent(tag: string) {
  return Component(<div />, tag, '[#668957]', '[#668957]', 'white', <FaCheck size={12} color="white" />, <div />)
}
export function selectionComponent(tag: string) {
  return Component(<div />, tag, '[#668957]', 'white', 'black', <FaPlus size={12} color="black" />, <div />)
}
export function notAllowedComponent(tag: string) {
  return Component(<div />, tag, 'gray-400', 'gray-400', 'gray-400', <FaXmark size={12} color="grey-400" />, <div />)
}
export function componentWithNumber(
  tag: string,
  place: number,
  maxNumber: number,
  upCallback: (el: string) => void,
  downCallback: (el: string) => void,
  deleteCallback: () => void
) {
  const upButton = <button type="button" onClick={() => { upCallback(tag) }}><FaAngleUp /></button>
  const downButton = <button type="button" onClick={() => { downCallback(tag) }}><FaAngleDown /></button>
  const deleteButton = <button type="button" onClick={() => { deleteCallback() }}><MdDelete color="red" /></button>
  let Element = <div className="grid">{upButton}{downButton}</div>
  if (place === 0) Element = <div>{downButton}</div>
  if (place === maxNumber) Element = <div>{upButton}</div>
  return Component(<div>#{place}: </div>, tag, '[#668957]', 'white', 'black', Element, deleteButton)
}
