import { Link, useParams } from 'react-router-dom'

import { SkeletonWrapper } from 'components'
import useProject from 'hooks/useProject'

export function ProjectBreadcrumb() {
  const { projectId, applicationId, componentId } = useParams()
  const { initialLoading } = useProject()

  if (!projectId && !applicationId && !componentId) return null

  return (
    <div>
      <SkeletonWrapper
        isLoading={initialLoading}
        inline
        height={20}
        width="50%"
      >
        <div className="text-base font-medium text-gray-400 leading-7">
          {projectId && (
            <>
              <Link to="/projects" className="pr-1 hover:underline">
                projects
              </Link>
              <span className="px-1">/</span>
              <Link
                to={`/projects/${projectId}`}
                className="px-1 hover:underline"
              >
                {projectId}
              </Link>
            </>
          )}
          {projectId && applicationId && (
            <>
              <span className="px-1">/</span>
              <Link
                to={`/projects/${projectId}`}
                className="px-1 hover:underline"
              >
                application
              </Link>
              <span className="px-1">/</span>
              <Link
                to={`/projects/${projectId}/applications/${applicationId}`}
                className="px-1 hover:underline"
              >
                {applicationId}
              </Link>
            </>
          )}
          {projectId && applicationId && componentId && (
            <>
              <span className="px-1">/</span>
              <Link
                to={`/projects/${projectId}/applications/${applicationId}`}
                className="px-1 hover:underline"
              >
                component
              </Link>
              <span className="px-1">/</span>
              <span className="pl-1">{componentId}</span>
            </>
          )}
        </div>
      </SkeletonWrapper>
    </div>
  )
}
