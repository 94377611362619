import { useState, useEffect, useCallback } from 'react'
import { v4 as uuid } from 'uuid'

import {
  TReport,
  TReportFilter,
  TReportFilterDisplay
} from 'services/report.interface'
import {
  FETCH_STATUS,
  GraphCardGroup,
  GraphCardWrapper,
  SkeletonWrapper
} from 'components'
import useAuthentication from 'hooks/useAuthentication'
import useReport from 'hooks/useReport'
import ReportTableCard from './ReportTableCard'
import MutationNewReportModal from './mutation-new-report-modal'
import ReportFiltersTableCard from './ReportFiltersTableCard'

const defaultSelectedReportFilter: TReportFilterDisplay = {
  id: uuid(),
  name: 'All',
  description: 'Get all projects, applications and components',
  _isFetched: true
}

function Reports() {
  const { currency } = useAuthentication()
  const { getReportFilters } = useReport()

  const [initialLoading, setInitialLoading] = useState(true)
  const [fetchReportFiltersStatus, setFetchReportFiltersStatus] = useState(
    FETCH_STATUS.START
  )
  const [showMutationModal, setShowMutationModal] = useState(false)
  const [reportFilters, setReportFilters] = useState<TReportFilterDisplay[]>([])
  const [editingReportFilter, setEditingReportFilter] = useState<
    TReportFilterDisplay | undefined
  >(undefined)
  const [selectedReportFilter, setSelectedReportFilter] =
    useState<TReportFilterDisplay>(defaultSelectedReportFilter)
  const [reports, setReports] = useState<TReport[]>([])

  const fetchReportFilters = useCallback(async () => {
    const newReportFilters = await getReportFilters(
      undefined,
      setFetchReportFiltersStatus
    )
    setReportFilters(newReportFilters.map((item) => ({ ...item, id: uuid() })))
    setInitialLoading(false)
  }, [getReportFilters])

  const handleSelectReportFilter = (id: string) => {
    if (selectedReportFilter?.id === id) return
    const newSelected = reportFilters.find((filter) => filter.id === id)
    if (newSelected) setSelectedReportFilter(newSelected)
  }

  const handleMutatedReportFilter = (
    { name, description }: TReportFilter,
    newReports: TReport[]
  ) => {
    // to avoid calling [fetchReportByFilter], don't set [filters]
    setSelectedReportFilter({ id: uuid(), name, description })
    setReports(newReports)
    fetchReportFilters()
  }

  useEffect(() => {
    setInitialLoading(true)
    fetchReportFilters()
  }, [fetchReportFilters])

  return (
    <GraphCardWrapper>
      <SkeletonWrapper
        isLoading={initialLoading}
        count={2}
        inline
        height={40}
        className="w-48"
        containerClassName="flex justify-between"
      >
        <div className="flex items-center justify-between">
          <span className="text-xl font-medium">Reports</span>
          <button
            type="button"
            onClick={() => setShowMutationModal(true)}
            className="bg-primary-color text-white rounded-md py-2.5 px-7"
          >
            Create new report
          </button>
        </div>
      </SkeletonWrapper>

      <GraphCardGroup grow>
        <ReportFiltersTableCard
          initialLoading={initialLoading}
          currency={currency}
          reportFilters={reportFilters}
          fetchStatus={fetchReportFiltersStatus}
          onFetch={fetchReportFilters}
          onEdit={setEditingReportFilter}
          onSelect={handleSelectReportFilter}
        />
      </GraphCardGroup>

      {!!selectedReportFilter && (
        <GraphCardGroup grow>
          <ReportTableCard
            initialLoading={initialLoading}
            reportFilter={selectedReportFilter}
            currency={currency}
            reports={reports}
            onReportChange={setReports}
          />
        </GraphCardGroup>
      )}

      {(showMutationModal || editingReportFilter) && (
        <MutationNewReportModal
          isOpen
          data={editingReportFilter}
          onMutated={handleMutatedReportFilter}
          onClose={() => {
            setShowMutationModal(false)
            setEditingReportFilter(undefined)
          }}
        />
      )}
    </GraphCardWrapper>
  )
}

export default Reports
