import {
  GraphCard,
  GraphCardGroup,
  GraphCardWrapper
} from 'components/graph-card'
import 'react-vis/dist/style.css'
import { TPlotSeries } from 'services/costhistory.interface'
import Spinner from 'components/spinner'
import { AreaChart, BarChart, AreaChartSeries, LineChart } from '@mantine/charts'

interface BarLineChartProps {
  data: TPlotSeries[] | object[]
  dataSeries: AreaChartSeries[]
  isLoading?: boolean
  chartType?: 'line' | 'bar' | 'area'
  // height?: number | undefined
}

function BarLineChart({
  data,
  dataSeries,
  chartType,
  isLoading
}: // height
BarLineChartProps) {
  if (isLoading) {
    return (
      <GraphCardWrapper>
        <GraphCardGroup
          className="flex justify-center items-center h-full py-20"
          compact
          spacing={20}
        >
          <Spinner size={40} />
        </GraphCardGroup>
      </GraphCardWrapper>
    )
  }
  return (
    <div className="h-full">
      <GraphCard isOutline={false} spacing={5}>
        {chartType === 'bar' && (
          <BarChart
            h={300}
            data={data}
            dataKey="date"
            type="stacked"
            series={dataSeries}
          />
        )}
        {chartType === 'line' && (
          <LineChart
            h={300}
            data={data}
            dataKey="date"
            curveType="linear"
            series={dataSeries}
          />
        )}
        {chartType === 'area' && (
          <AreaChart
            h={300}
            data={data}
            dataKey="date"
            series={dataSeries}
            type="stacked"
            curveType="linear"
          />
        )}
      </GraphCard>
    </div>
  )
}

export default BarLineChart
