import { useRef, useEffect } from 'react'
import { addDays, format } from 'date-fns'
import { DateRangePicker } from 'react-date-range'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import usePopup from 'hooks/usePopup'
import { mapClasses } from 'utils'

interface DatePickerComponentProps {
  startDate: Date | undefined
  endDate: Date | undefined
  size?: 'sm' | 'md' | 'lg' | 'h' | 'q'
  handleChange: (
    dates: { startDate?: Date; endDate?: Date; key?: string }[]
  ) => void
  onClosed?: (startDate?: Date, endDate?: Date) => void
}

function DateRangePickerComponent({
  startDate,
  endDate,
  size,
  handleChange,
  onClosed
}: DatePickerComponentProps) {
  const triggerDatePickerRef = useRef<HTMLDivElement | null>(null)
  const { isOpen, openPopup } = usePopup(triggerDatePickerRef)

  useEffect(() => {
    if (!isOpen) {
      if (onClosed) {
        onClosed(startDate, endDate)
      }
    }
  }, [isOpen])

  return (
    <div
      className={mapClasses(
        'flex flex-col  items-center border cursor-pointer relative rounded-lg',
        size === 'lg' && 'h- w-full px-3 py-1.5',
        size === 'md' && 'px-3 w-full py-1',
        size === 'sm' && 'px-2 w-full py-1 text-sm',
        size === 'h' && 'px-2 w-1/2 py-1 text-sm',
        size === 'q' && 'px-2 w-1/3 py-1 text-sm'
      )}
      ref={triggerDatePickerRef}
      onClick={openPopup}
    >
      {format(startDate || addDays(new Date(), 7), 'MMM dd, yyyy')} -{' '}
      {format(endDate || new Date(), 'MMM dd, yyyy')}
      {isOpen && (
        <div className="absolute left-0 top-8 border shadow z-20 bg-white">
          <DateRangePicker
            className="absolute"
            onChange={(item) => handleChange([item.selection])}
            months={1}
            ranges={[
              {
                startDate: startDate || addDays(new Date(), 7),
                endDate: endDate || new Date(),
                key: 'selection'
              }
            ]}
            direction="horizontal"
          />
        </div>
      )}
    </div>
  )
}

export default DateRangePickerComponent
