/* eslint-disable @typescript-eslint/no-empty-function */
import React, {
  createContext,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'
import { useParams } from 'react-router-dom'

import { TProject, TProjectQueryResponse } from 'services/project.interface'
import { TApplication } from 'services/application.interface'
import { TComponent, TComponentGraphNode } from 'services/component.interface'
import { TCreateProjectFunction, TFetchStatus } from 'services/fetch.interface'
import { FETCH_STATUS } from 'components/fetch-status'
import { apiErrorHandler, axios } from 'utils'

interface ProjectContextType {
  isFetched: boolean
  initialLoading: boolean
  fetchProjectsStatus: TFetchStatus
  fetchApplicationGraphsStatus: TFetchStatus
  fetchProjects: () => Promise<void>
  fetchApplicationGraphs: () => Promise<void>
  projectList: TProject[]
  setProjectList: React.Dispatch<React.SetStateAction<TProject[]>>
  applicationGraphNodeList: TComponentGraphNode[]
  project?: TProject
  applicationList: TApplication[]
  application?: TApplication
  componentList: TComponent[]
  component?: TComponent
  createProject: TCreateProjectFunction
}

interface ProjectContextProviderProps {
  children: ReactElement
}

const ProjectContext = createContext<ProjectContextType>({
  isFetched: false,
  initialLoading: false,
  fetchProjectsStatus: { isLoading: false, isError: false },
  fetchApplicationGraphsStatus: { isLoading: false, isError: false },
  fetchProjects: () => Promise.resolve(),
  fetchApplicationGraphs: () => Promise.resolve(),
  projectList: [],
  setProjectList: () => {},
  applicationGraphNodeList: [],
  applicationList: [],
  componentList: [],
  createProject: () => Promise.resolve({ _type: 'project', name: '' })
})

export function ProjectContextProvider({
  children
}: ProjectContextProviderProps) {
  const { projectId, applicationId, componentId } = useParams()

  const [isFetched, setFeteched] = useState(false)
  const [fetchProjectsStatus, setFetchProjectsStatus] = useState(
    FETCH_STATUS.START
  )
  const [fetchApplicationGraphsStatus, setFetchApplicationGraphsStatus] =
    useState(FETCH_STATUS.SUCCESS)
  const [projectList, setProjectList] = useState<TProject[]>([])
  const [applicationGraphNodeList, setApplicationGraphNodeList] = useState<
    TComponentGraphNode[]
  >([])

  const initialLoading = useMemo(() => {
    return (
      (!isFetched && fetchProjectsStatus.isLoading) ||
      fetchApplicationGraphsStatus.isLoading
    )
  }, [isFetched, fetchProjectsStatus, fetchApplicationGraphsStatus])

  const project = useMemo<TProject | undefined>(
    () => projectList.find(({ name }) => name === projectId),
    [projectList, projectId]
  )

  const applicationList = useMemo<TApplication[]>(
    () => project?.applications || [],
    [project]
  )

  const application = useMemo<TApplication | undefined>(
    () => applicationList.find(({ name }) => name === applicationId),
    [applicationList, applicationId]
  )

  const componentList = useMemo<TComponent[]>(
    () => application?.components || [],
    [application]
  )

  const component = useMemo<TComponent | undefined>(
    () => componentList.find(({ name }) => name === componentId),
    [componentList, componentId]
  )

  const fetchProjects = useCallback(async () => {
    const body = {
      name: 'All',
      description: 'Get all projects, applications and components',
      filters: [],
      options: { saveQuery: true }
    }

    try {
      setFetchProjectsStatus(FETCH_STATUS.START)
      const response = await axios.post<TProjectQueryResponse>('/query', body)

      setProjectList(response.data.data || [])
      setFetchProjectsStatus(FETCH_STATUS.SUCCESS)
    } catch (error) {
      apiErrorHandler(error)
      setFetchProjectsStatus(FETCH_STATUS.FAIL)
      setProjectList([])
    } finally {
      setFeteched(true)
    }
  }, [projectId, applicationId, componentId])

  const fetchApplicationGraphs = useCallback(async () => {
    // const params = { project: projectId, application: applicationId }

    try {
      setFetchApplicationGraphsStatus(FETCH_STATUS.START)
      /* const { data } = await axios.get<{
        resources: TComponentGraphNode[]
      }>('/graph', { params }) */

      setFetchApplicationGraphsStatus(FETCH_STATUS.SUCCESS)
      // setApplicationGraphNodeList(data?.resources || [])
    } catch (error) {
      apiErrorHandler(error)
      setFetchApplicationGraphsStatus(FETCH_STATUS.FAIL)
      setApplicationGraphNodeList([])
    }
  }, [projectId, applicationId])

  const createProject = useCallback<TCreateProjectFunction>(
    async (body, updateStatus) => {
      try {
        updateStatus?.(FETCH_STATUS.START)
        const { data } = await axios.post<TProject>('/projects', body)
        updateStatus?.(FETCH_STATUS.SUCCESS)
        return data || { _type: 'project', name: '' }
      } catch (error) {
        updateStatus?.(FETCH_STATUS.FAIL)
        apiErrorHandler(error)
        return { _type: 'project', name: '' }
      }
    },
    [projectId, applicationId]
  )

  const value = useMemo<ProjectContextType>(
    () => ({
      isFetched,
      initialLoading,
      fetchProjectsStatus,
      fetchApplicationGraphsStatus,
      fetchProjects,
      fetchApplicationGraphs,
      projectList,
      setProjectList,
      applicationGraphNodeList,
      project,
      applicationList,
      application,
      componentList,
      component,
      createProject
    }),
    [
      isFetched,
      initialLoading,
      fetchProjectsStatus,
      fetchApplicationGraphsStatus,
      fetchProjects,
      fetchApplicationGraphs,
      projectList,
      applicationGraphNodeList,
      project,
      applicationList,
      application,
      componentList,
      component,
      createProject
    ]
  )

  useEffect(() => {
    if (!isFetched) {
      fetchProjects()
    }
  }, [isFetched, fetchProjects])

  useEffect(() => {
    if (projectId && applicationId) {
      fetchApplicationGraphs()
    }
  }, [projectId, applicationId, fetchApplicationGraphs])

  return (
    <ProjectContext.Provider value={value}>{children}</ProjectContext.Provider>
  )
}

export default ProjectContext
