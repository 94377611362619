import { TTableColumn } from 'components'
import { formatCost, formatNilValue } from 'utils'

export type TFormInput = {
  label: string
  placeholder: string
  type: string
  required: boolean
  disabled: boolean
  value?: string | null
  key: string
}
export type TFormFieldSet = {
  heading: string
  inputs: TFormInput[]
}

export const COMPONENT_TABLE_COLUMNS: TTableColumn[] = [
  {
    name: 'Name',
    field: 'name',
    width: '50%',
    format: formatNilValue
  },
  {
    name: 'Provider',
    field: 'provider',
    width: '10%',
    format: formatNilValue
  },
  {
    name: 'Service name',
    field: 'serviceName',
    width: '10%',
    format: formatNilValue
  },
  {
    name: 'Daily Cost',
    field: 'daily',
    width: '10%',
    format: formatCost
  },
  {
    name: 'Weekly Cost',
    field: 'weekly',
    width: '10%',
    format: formatCost
  },
  {
    name: 'Monthly Cost',
    field: 'monthly',
    width: '10%',
    format: formatCost
  }
]
