import { axios } from 'utils'
import {
  // GraphCardContent,
  GraphCardGroup,
  SpinnerLoader,
} from 'components'
import { v4 as uuid } from 'uuid'
import LabeledDataComponent from 'components/LabeledDataRepresenation'
import { useEffect, useState } from 'react'
import 'chart.js/auto';
// import { Bar } from 'react-chartjs-2'
import { useQuery } from 'react-query'
// import autocolors from 'chartjs-plugin-autocolors';
import { useElementSize } from '@mantine/hooks'
import useAuthentication from 'hooks/useAuthentication'
// import autocolors from 'chartjs-plugin-autocolors';
import { TCostHistoryQueryResponse, TFocusGroup, newTFocusGroup } from 'services/costhistory.interface'
import { TPieChartData } from 'services/chart.interface'
import { useTour } from '@reactour/tour'
// import autocolors from 'chartjs-plugin-autocolors';
import {
  Cell,
  // LabelList,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer
} from 'recharts'
import DateRangePickerComponent from './dateRangePicker'
import { parseAccumulatedFalseData, parseAccumulatedTrueData, renderLabel, renderLegend } from './chartHelperFunctions'
import {
  createWidgetAggregatedAPIFieldCall,
  createWidgetAggregatedAPILabelCall,
  createWidgetAPIFieldCall,
  createWidgetAPILabelCall,
  dataNameAndColor,
  projectAndTheirApps,
  query
} from '../project-widget/widgetHelpFunctions'
import { tourConfig } from './tour'
import { WidgetData } from '../project-widget/widgetStatev2'
import { TutorialContainer } from '../project-tutorial'

// import { tourConfig } from './tour'
export type chartJsDataSets = { label: string, data: number[] }
export type chartJsData = { labels: string[], datasets: chartJsDataSets[] }

interface LabeledDataCardProps {
  id: string,
  labels: string[],
  fields: string[],
  initialType: string,
  editCallBack: () => void,
  closeCallBack: () => void,
  deleteWidget: () => void,
  widgetObject: WidgetData
}

export function LabeledDataCard({
  id,
  labels,
  fields,
  initialType,
  editCallBack,
  closeCallBack,
  deleteWidget,
  widgetObject
}: LabeledDataCardProps) {
  widgetObject.resetOptions(id)
  const [tutorialUpdater, setTutorialUpdater] = useState<boolean>(false)

  const changeTutorialUpdater = () => {
    setTutorialUpdater(true)
  }
  // console.log(autocolors)
  const { currency } = useAuthentication()
  const [label, setLabel] = useState<string>(widgetObject.getLabel(id) === '' && labels.length !== 0 ? fields[0] : widgetObject.getLabel(id))
  const [toTutorial, setTutorial] = useState<boolean>(false)
  const [startDate, setStartDate] = useState<Date>(widgetObject.getWindow(id) === 'lastmonth' ? new Date() : new Date(widgetObject.getWindow(id).split(',')[0]))
  const [workStartDate, setWorkStartDate] = useState<Date>(widgetObject.getWindow(id) === 'lastmonth' ? new Date() : new Date(widgetObject.getWindow(id).split(',')[0]))
  const { setIsOpen, setSteps, setCurrentStep } = useTour()

  const [amountOfMonths, setAmountOfMonths] = useState<number>(30)
  useEffect(() => {
    if (widgetObject.getWindow(id) === 'lastmonth') {
      startDate.setDate(startDate.getDate() - amountOfMonths)
      workStartDate.setDate(workStartDate.getDate() - amountOfMonths)
    }
    setAmountOfMonths(0)
  })
  const [endDate, setEndDate] = useState<Date>(widgetObject.getWindow(id) === 'lastmonth' ? new Date() : new Date(widgetObject.getWindow(id).split(',')[1]))
  const [workEndDate, setWorkEndDate] = useState<Date>(widgetObject.getWindow(id) === 'lastmonth' ? new Date() : new Date(widgetObject.getWindow(id).split(',')[1]))

  const [window, setWindow] = useState<string>(widgetObject.getWindow(id))
  const [dateChanged, setDateChanged] = useState<boolean>(false)
  const [type, setType] = useState<string>(widgetObject.getType(id) === '' ? initialType : widgetObject.getType(id))

  const [stepDuration] = useState<string>(widgetObject.getStepDuration(id))
  const [loading, setLoading] = useState<boolean>(false)
  const [chartDataValues, setChartDataValues] = useState<projectAndTheirApps[]>([])
  const [chartDataNames, setChartDataNames] = useState<dataNameAndColor[]>([])
  const [accumulatedChartData, setAccumulatedChartData] = useState<TPieChartData[]>([])
  const {
    ref,
    width,
    height
  } = useElementSize();

  function fieldToQuery(field: string) {
    const lst = field.split(' ')
    let res = ''
    lst.forEach((el) => {
      if (res === '') {
        res = el.toLowerCase()
      } else {
        res = `${res}${el[0].toUpperCase() + el.slice(1)}`
      }
    })
    return res
  }
  function getQuery(): query {
    if (type === 'accumulateFalseChart') {
      if (labels.findIndex((el) => el === label) !== -1) {
        return createWidgetAPILabelCall(
          label,
          window,
          stepDuration
        )
      }
      return createWidgetAPIFieldCall(
        fieldToQuery(label),
        window,
        stepDuration
      )
    }

    if (labels.findIndex((el) => el === label) !== -1) {
      return createWidgetAggregatedAPILabelCall(
        label
      )
    }
    return createWidgetAggregatedAPIFieldCall(
      fieldToQuery(label)
    )
  }

  const [queryRequest, setQueryRequest] = useState<query>(getQuery())
  const [queryKey, setQueryKey] = useState<string>(JSON.stringify(queryRequest))
  // Used to trigger query of filters
  const [dataProcessed, setDataProcessed] = useState<boolean>(false)
  const [focusData, setFocusData] = useState<TFocusGroup[] | newTFocusGroup[]>([])
  const [path, setPath] = useState<string[]>(widgetObject.getChartPath(id))
  const [legendUpdate, setLegendUpdate] = useState<boolean>(false)
  // const [edit, setEdit] = useState<boolean>(false)

  const changeToAccumulateFalseChart = () => {
    if (type !== 'accumulateFalseChart') {
      setType('accumulateFalseChart')
      widgetObject.setType(id, 'accumulateFalseChart')
      setFocusData([])
      setPath([])
    }
  }

  const changeToAccumulateTrueChart = () => {
    if (type !== 'accumulateTrueChart') {
      setType('accumulateTrueChart')
      widgetObject.setType(id, 'accumulateTrueChart')
      setFocusData([])
      setPath([])
    }
  }
  const tutorialChangeToAccumulateFalseChart = () => {
    setType('accumulateFalseChart')
    widgetObject.setType(id, 'accumulateFalseChart')
    setFocusData([])
    setPath([])
  }

  const tutorialChangeToAccumulateTrueChart = () => {
    setType('accumulateTrueChart')
    widgetObject.setType(id, 'accumulateTrueChart')
    setFocusData([])
    setPath([])
  }

  // ***************************************************************************
  // Add the Edit widget to the options of this widget.
  // ***************************************************************************

  widgetObject.editOptions(id, 'Add date range', changeToAccumulateFalseChart)
  widgetObject.editOptions(id, 'Accumulate', changeToAccumulateTrueChart)
  widgetObject.editOptions(id, 'Help', () => {
    setCurrentStep(0)
    setIsOpen(true)
    setTutorial(false)
  })
  widgetObject.editOptions(id, 'Delete widget', deleteWidget)

  useEffect(() => {
    setQueryRequest(getQuery())
    setPath([])
    setQueryKey(JSON.stringify(uuid()))
    setDataProcessed(false)
    setLoading(false)
  }, [type, label, window])

  useEffect(() => {
    if (!dataProcessed) {
      setQueryRequest(getQuery())
      const testQuery = JSON.stringify(queryRequest)
      if (testQuery === queryKey) {
        setDataProcessed(true)
      } else {
        setQueryKey(JSON.stringify(getQuery()))
      }
    }
  })

  const { error } = useQuery({
    queryKey: [`query?${queryKey}`],
    queryFn: async () => {
      try {
        if (!dataProcessed) {
          setDataProcessed(true)
          await axios
            .post<TCostHistoryQueryResponse>('/query', queryRequest)
            .then((res) => {
              setFocusData(res.data.data)
              setLoading(true)
              if (toTutorial) {
                if (type === 'accumulateFalseChart') {
                  if (setSteps) {
                    setSteps(
                      tourConfig(
                        id,
                        type,
                        [
                          editCallBack,
                          tutorialChangeToAccumulateTrueChart,
                          closeCallBack,
                          tutorialChangeToAccumulateFalseChart
                        ]
                      )
                    )
                  }
                } else if (setSteps) {
                  setSteps(
                    tourConfig(
                      id,
                      type,
                      [
                        editCallBack,
                        tutorialChangeToAccumulateFalseChart,
                        closeCallBack,
                        tutorialChangeToAccumulateTrueChart
                      ]
                    )
                  )
                }
                setIsOpen(true)
                setTutorial(false)
              }
              // editData(id, res.data.data)
            })
            .catch((error) => {
              if (error.response.data.data) {
                setFocusData([])
                setLoading(true)
                setDataProcessed(true)
              }
              // throw new Error(`Error code ${error.status}`)
            })
        }
      } catch (error) {
        throw new Error(`Error code ${error}`)
      }
    }
  })

  useEffect(() => {
    if (type === 'accumulateFalseChart') {
      const res = parseAccumulatedFalseData(focusData as TFocusGroup[])
      setChartDataValues(res.values)
      setChartDataNames(res.names)
      // setLoading(true)
    } else {
      const res = parseAccumulatedTrueData(focusData as newTFocusGroup[], path)
      setAccumulatedChartData(res.sort((a, b) => b.value - a.value))
      // setLoading(true)
    }
    setLegendUpdate(true)
  }, [focusData, path])

  useEffect(() => {
    const window = `${startDate?.toISOString().replaceAll('.000Z', 'Z')},${endDate?.toISOString().replaceAll('.000Z', 'Z')}`
    setWindow(window)
    widgetObject.editWindow(id, window)
  }, [startDate, endDate])
  // function fieldFilter(el){
  //   el !=== 'monthly cost'
  // }
  function getTour() {
    if (type === 'accumulateFalseChart') {
      return tourConfig(
        id,
        type,
        [
          editCallBack,
          tutorialChangeToAccumulateTrueChart,
          closeCallBack,
          tutorialChangeToAccumulateFalseChart,
          changeTutorialUpdater
        ]
      )
    }
    return tourConfig(
      id,
      type,
      [
        editCallBack,
        tutorialChangeToAccumulateFalseChart,
        closeCallBack,
        tutorialChangeToAccumulateTrueChart,
        changeTutorialUpdater
      ]
    )
  }

  if (error) return <div>An error has occurred</div>
  return (
    <div className="h-full" ref={ref}>

      <TutorialContainer
        tag="CumulativeWidget"
        updater={tutorialUpdater}
        tourConfig={
          getTour()
        }
      >
        <GraphCardGroup className="h-full">
          <div
            data-tour={`LabeledDataChart${id}`}
            className="block h-full w-full"
          >
            <SpinnerLoader isLoading={!loading}>
              <div
                className="py-2 flex flex-row h-fit place-items-center"
              >
                <span className="px-2 flex text-gray-500 text-xs font-normal">
                  Group cost by:
                </span>
                <select
                  className="py-2 border rounded-lg bg-white"
                  value={label}
                  name="Labels"
                  data-tour={`labelSelectForLabeledDataChart${id}`}
                  id={`labelSelectForLabeledDataChart${id}`}
                  onChange={(newLabel) => {
                    setLabel(newLabel.target.value)
                    widgetObject.editLabel(id, newLabel.target.value)
                    // setPath([])
                  }}
                >
                  <optgroup label="Labels">
                    {labels.map((el) => {
                      return (
                        <option
                          key={`SelectionOptionOf${id}FromLabelsWithValue${el}`}
                          className="bg-white"
                        >
                          {el}
                        </option>
                      )
                    })}
                  </optgroup>
                  <optgroup label="Fields">
                    {fields.filter((field) => {
                      const parts = field.split(' ')
                      const index = parts.findIndex((el) => {
                        return el.toLowerCase() === 'cost'
                      })
                      return (
                        index === -1
                        && field !== 'Updated flag'
                        && field !== 'Data date'
                      )
                    }).sort((a, b) => {
                      return a.localeCompare(b)
                    }).map((el) => {
                      return (
                        <option
                          key={`SelectionOptionOf${id}FromFieldsWithValue${el}`}
                          className="bg-white"
                        >
                          {el}
                        </option>
                      )
                    })}
                  </optgroup>
                </select>
                {type === 'accumulateFalseChart' && (
                  <div
                    className="flex flex-row"
                    data-tour={`windowSelectForLabeledDataChart${id}`}
                  >
                    <span className="px-2 flex text-gray-500 text-xs font-normal items-center">
                      Date range:
                    </span>
                    <div className="w-full">
                      <DateRangePickerComponent
                        // size="lg"
                        startDate={workStartDate}
                        endDate={workEndDate}
                        onClosed={() => {
                          if (dateChanged) {
                            setDateChanged(false)
                            setStartDate(workStartDate)
                            setEndDate(workEndDate)
                          }
                        }}
                        handleChange={(event) => {
                          if (event[0].startDate) {
                            setWorkStartDate(event[0].startDate)
                            setDateChanged(true)
                            // editStartDate(id, event[0].startDate)
                          }
                          if (event[0].endDate) {
                            setWorkEndDate(event[0].endDate)
                            setDateChanged(true)
                            // editEndDate(id, event[0].endDate)
                          }
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
              {type === 'accumulateFalseChart' && (
                <div
                  className=" h-7/8"
                  data-tour={`accumulateFalseChart${id}`}
                >

                  <div style={{ display: 'flex', height: '75%' }}>
                    <LabeledDataComponent
                      currency={currency}
                      data={chartDataValues}
                      series={chartDataNames}
                      height={(height / 8) * 6}
                      width={width}
                    />
                  </div>
                </div>
              )}

              {type === 'accumulateTrueChart' && (
                <div style={{ height: (height * 4) / 5 }}>
                  <div
                    data-tour={`accumulateTrueChartPath${id}`}
                  >
                    {[''].concat(path).map((el, idx) => {
                      if (el !== '') {
                        return (
                          <span
                            key={`spanElementOfTheLabeledDataWidget${id}WithValue${el}`}
                            className="text-sm text-gray-500 font-semibold px-0 cursor-pointer hover:underline text-wrap"
                            onClick={() => {
                              setPath(path.slice(0, idx))
                              setLegendUpdate(false)
                            }}
                          >
                            {el}/
                          </span>
                        )
                      }
                      return (
                        <span
                          key={`spanElementOfTheLabeledDataWidget${id}WithValueHome`}
                          className="text-sm text-gray-500 font-semibold px-0 cursor-pointer hover:underline text-wrap"
                          onClick={() => {
                            setPath([])
                            setLegendUpdate(false)
                          }}
                        >
                          Home/
                        </span>
                      )
                    })}
                  </div>
                  <div
                    className="h-full"
                    data-tour={`accumulateTrueChart${id}`}
                  >
                    <ResponsiveContainer>
                      <PieChart>

                        <Pie
                          data={accumulatedChartData}
                          dataKey="value"
                          nameKey="name"
                          innerRadius={0}
                          labelLine={false}
                          cx="50%"
                          cy="50%"
                          fill="#82ca9d"
                          onClick={(event) => {
                            if (event) {
                              const nextEl = event.name
                              let isEnd = false
                              let tempData = focusData[0] as newTFocusGroup
                              path.forEach((pathPiece) => {
                                if (Object.getOwnPropertyNames(tempData[pathPiece].children).length
                                  !== 0) {
                                  tempData = tempData[pathPiece].children
                                } else {
                                  isEnd = true
                                }
                              })
                              if (!isEnd) {
                                setPath(path.concat(nextEl))
                                widgetObject.setChartPath(id, path.concat(nextEl))
                                setLegendUpdate(false)
                              }
                            }
                          }}
                          label={(value) => {
                            return renderLabel(value, currency, accumulatedChartData[0].value)
                          }}
                        >
                          {accumulatedChartData.map((entry) => (
                            <Cell key={entry.color} fill={entry.color} />
                          ))}
                        </Pie>
                        {legendUpdate && (
                          <Legend content={renderLegend(accumulatedChartData, id, currency)} height={36} layout="vertical" align="left" verticalAlign="top" />
                        )}
                      </PieChart>
                    </ResponsiveContainer>
                  </div>
                </div>
              )}
            </SpinnerLoader>
          </div>
        </GraphCardGroup>
      </TutorialContainer>
    </div>
  )
}
