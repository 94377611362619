import { TTableColumn } from 'components'
import { formatCost, formatNilValue, formatPercent } from 'utils'

export const OptimizationTableColumns: TTableColumn[] = [
  {
    name: 'Done',
    field: 'done',
    width: '6%',
    sortable: false,
    filterable: false,
    render: (value: boolean) => (
      <input type="checkbox" id="myCheck" checked={value} onClick={() => { console.log('checked') }} />
    )
  },
  {
    name: 'Name of resource',
    field: 'name',
    width: '20%',
    format: formatNilValue
  },
  {
    name: 'Action to take',
    field: 'Action',
    width: '20%',
    format: formatNilValue
  },
  {
    name: 'Monthly Cost',
    field: 'MonthlyCost',
    width: '13%',
    format: formatCost
  },
  {
    name: 'Monthly savings',
    field: 'MonthlySaving',
    width: '13%',
    format: formatCost
  },
  {
    name: 'Saving percentage',
    field: 'SavingPercentage',
    width: '13%',
    format: formatPercent
  },
  {
    name: 'Restart needed',
    field: 'restart',
    width: '7%',
    filterable: false,
    format: formatNilValue
  },
  {
    name: 'Rollback possible',
    field: 'rollback',
    width: '7%',
    filterable: false,
    format: formatNilValue
  }
]
