import { v4 as uuid } from 'uuid'

// ***************************************************************************
// The data type that saves all the data a widget can have
// ***************************************************************************
export type labeledDataWidget = {
  label: string,
  type: string,
  path: string[],
  stepDuration: string,
  window: string
}
export type tableWidget = {
  aggregate: string[],
  path: string[],
  selector: string
}
export type widgetState = {
  id: string,
  key: string,
  x: number,
  y: number,
  w: number,
  h: number,
  options: { label: string, func: () => void }[]
  widgetTypeData: labeledDataWidget | tableWidget | undefined
}

// ***************************************************************************
// Load the widgetList from localstorage.
// ***************************************************************************
const temp = localStorage.getItem('widgetList')
if (temp === null) {
  localStorage.setItem('widgetList', JSON.stringify([]))
}
export const widgetList: { tag: string, state: widgetState }[] = JSON.parse(localStorage.getItem('widgetList')!)

// ***************************************************************************
// Update the localstorage
// ***************************************************************************
function updateResource() {
  localStorage.setItem('widgetList', JSON.stringify(widgetList))
}

// ***************************************************************************
// Add to the list.
// ***************************************************************************
export function addWidgetList(tag: string, state: widgetState) {
  widgetList.push({ tag, state })
  updateResource()
}
// ***************************************************************************
// Delete from the list
// ***************************************************************************
export function deleteFromWidgetList(id: string) {
  const index = widgetList.findIndex((el) => el.state.id === id)
  widgetList.splice(index, 1)
  updateResource()
}
// ***************************************************************************
// Change position of 2 widgets.
// ***************************************************************************
export function swapWidgets(id: string, newIdx: number) {
  const widget = widgetList.find((el) => el.state.id === id)
  if (widget !== undefined) {
    deleteFromWidgetList(id)
    widgetList.splice(newIdx, 0, widget);
    updateResource()
  }
}

// ***************************************************************************
// Create a new widgetstate object for a widget.
// ***************************************************************************

export function createWidget(tag: string) {
  const idKey = uuid()
  let widgetTypeData: labeledDataWidget | tableWidget | undefined;
  if (tag === 'labeledDataChart' || tag === 'cumulativeCost') {
    widgetTypeData = {
      label: '',
      type: '',
      path: [],
      // operator: 'NOTEQUALS',
      // apiFilterValue: '',
      window: 'lastmonth',
      stepDuration: '1d',
      // valueChosen: 'totalBilledCost',
      // data: [],
      // chartValue: 'serviceName'
    }
  } else if (tag === 'projectTable') {
    widgetTypeData = {
      aggregate: [],
      path: [],
      selector: 'fields'

    }
  }
  const newWidget: widgetState = {
    id: idKey,
    key: idKey,
    x: 0,
    y: 0,
    w: 6,
    h: 15,
    options: [],
    widgetTypeData
  }
  addWidgetList(tag, newWidget)
}

// ***************************************************************************
// Following are some functions that allow the mutation of the data inside
// of a widgetstate, it just needs the id.
// ***************************************************************************

// ***************************************************************************
// All functions for the widgets in general
// ***************************************************************************

export function editXYWH(id: string, x: number, y: number, w: number, h: number) {
  const widget = widgetList.find((el) => el.state.id === id)
  widget!.state.x = x
  widget!.state.y = y
  widget!.state.w = w
  widget!.state.h = h
  updateResource()
}
export function getOptions(id: string) {
  const widget = widgetList.find((el) => el.state.id === id)
  return widget!.state.options
}

export function editOptions(id: string, label: string, func: () => void) {
  const widget = widgetList.find((el) => el.state.id === id)
  widget!.state.options.push({ label, func })
}
export function resetOptions(id: string) {
  const widget = widgetList.find((el) => el.state.id === id)
  widget!.state.options = []
}

// ***************************************************************************
// All functions for the charts
// ***************************************************************************
export function editLabel(id: string, label: string) {
  console.log('we are starting the editing of the label')
  const widget = widgetList.find((el) => el.state.id === id)
  if (widget) {
    if (widget?.tag === 'labeledDataChart' || widget?.tag === 'cumulativeCost') {
      console.log('we are editing the label')
      const data: labeledDataWidget = widget!.state.widgetTypeData as labeledDataWidget
      data.label = label
    }
  }
  updateResource()
}

export function getLabel(id: string) {
  const widget = widgetList.find((el) => el.state.id === id)
  if (widget) {
    if (widget.tag === 'labeledDataChart' || widget?.tag === 'cumulativeCost') {
      const data: labeledDataWidget = widget!.state.widgetTypeData as labeledDataWidget
      return data.label
    } return ''
  } return ''
}
export function getType(id: string) {
  const widget = widgetList.find((el) => el.state.id === id)
  if (widget) {
    if (widget.tag === 'labeledDataChart' || widget?.tag === 'cumulativeCost') {
      const data: labeledDataWidget = widget!.state.widgetTypeData as labeledDataWidget
      return data.type
    } return ''
  } return ''
}
export function setType(id: string, type: string) {
  const widget = widgetList.find((el) => el.state.id === id)
  if (widget) {
    if (widget.tag === 'labeledDataChart' || widget?.tag === 'cumulativeCost') {
      const data: labeledDataWidget = widget!.state.widgetTypeData as labeledDataWidget
      data.type = type
    }
  }
  updateResource()
}
export function getChartPath(id: string) {
  const widget = widgetList.find((el) => el.state.id === id)
  if (widget) {
    if (widget.tag === 'labeledDataChart' || widget?.tag === 'cumulativeCost') {
      const data: labeledDataWidget = widget!.state.widgetTypeData as labeledDataWidget
      return data.path
    } return []
  } return []
}
export function setChartPath(id: string, path: string[]) {
  const widget = widgetList.find((el) => el.state.id === id)
  if (widget) {
    if (widget.tag === 'labeledDataChart' || widget?.tag === 'cumulativeCost') {
      const data: labeledDataWidget = widget!.state.widgetTypeData as labeledDataWidget
      data.path = path
    }
  }
  updateResource()
}

export function editWindow(id: string, window: string) {
  const widget = widgetList.find((el) => el.state.id === id)
  if (widget) {
    if (widget?.tag === 'labeledDataChart' || widget?.tag === 'cumulativeCost') {
      const data: labeledDataWidget = widget!.state.widgetTypeData as labeledDataWidget
      data.window = window
    }
  }
  updateResource()
}

export function getWindow(id: string) :string {
  const widget = widgetList.find((el) => el.state.id === id)
  if (widget) {
    if (widget.tag === 'labeledDataChart' || widget?.tag === 'cumulativeCost') {
      const data: labeledDataWidget = widget!.state.widgetTypeData as labeledDataWidget
      return data.window
    } return ''
  } return ''
}

export function editStepDuration(id: string, stepDuration: string) {
  const widget = widgetList.find((el) => el.state.id === id)
  if (widget) {
    if (widget?.tag === 'labeledDataChart' || widget?.tag === 'cumulativeCost') {
      const data: labeledDataWidget = widget!.state.widgetTypeData as labeledDataWidget
      data.stepDuration = stepDuration
    }
  }
  updateResource()
}

export function getStepDuration(id: string) {
  const widget = widgetList.find((el) => el.state.id === id)
  if (widget) {
    if (widget.tag === 'labeledDataChart' || widget?.tag === 'cumulativeCost') {
      const data: labeledDataWidget = widget!.state.widgetTypeData as labeledDataWidget
      return data.stepDuration
    } return ''
  } return ''
}

// ***************************************************************************
// All functions for the Table widgets
// ***************************************************************************

export function getTableDataAggregate(id: string): string[] {
  const widget = widgetList.find((el) => el.state.id === id)

  if (widget) {
    if (widget.tag === 'projectTable') {
      const data: tableWidget = widget!.state.widgetTypeData as tableWidget
      return data.aggregate
    } return []
  } return []
}

export function getTableDataPath(id: string): string[] {
  const widget = widgetList.find((el) => el.state.id === id)

  if (widget) {
    if (widget.tag === 'projectTable') {
      const data: tableWidget = widget!.state.widgetTypeData as tableWidget
      return data.path
    } return []
  } return []
}
export function getTableDataSelector(id: string): string {
  const widget = widgetList.find((el) => el.state.id === id)
  if (widget) {
    if (widget.tag === 'projectTable') {
      const data: tableWidget = widget!.state.widgetTypeData as tableWidget
      return data.selector
    } return 'fields'
  } return 'fields'
}

export function setTableDataAfterNewCall(id: string, aggregate: string[], selector: string) {
  const widget = widgetList.find((el) => el.state.id === id)
  if (widget) {
    if (widget.tag === 'projectTable') {
      const data: tableWidget = widget!.state.widgetTypeData as tableWidget
      data.aggregate = aggregate
      data.selector = selector
    }
  }
  updateResource()
}
export function setTableDataWhenWalkingThroughData(id: string, path: string[]) {
  const widget = widgetList.find((el) => el.state.id === id)
  if (widget) {
    if (widget.tag === 'projectTable') {
      const data: tableWidget = widget!.state.widgetTypeData as tableWidget
      data.path = path
    }
  }
  updateResource()
}
