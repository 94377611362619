import {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState
} from 'react'
import noop from 'lodash/noop'
import { toast } from 'react-toastify'
import { TUser } from 'services/user.interface'
import { TCurrency } from 'services/cost.interface'
import { CURRENCY, CURRENCY_SYMBOL } from 'constants/currency.constants'
import { apiErrorHandler, axios } from 'utils'
import { useKeycloak } from '@react-keycloak/web'

const EMPTY_USER: TUser = {
  email: '',
  fullName: '',
  firstName: '',
  lastName: ''
}

interface IAuthenticationContextState {
  isLoading: boolean
  isAuthenticated: boolean
  token: string
  refreshToken: string
  user: TUser
  currency: TCurrency
  LogIn: () => void
  LogOut: () => void
  RefreshToken: () => void
  onLoginSuccess: (params: {
    access_token: string
    refresh_token: string
  }) => void
  onLoginFailed: () => void
  onLogout: () => void
  onChangeCurrency: (currency: TCurrency['value']) => void
}

const AuthenticationContext = createContext<IAuthenticationContextState>({
  isLoading: false,
  isAuthenticated: false,
  token: '',
  refreshToken: '',
  user: EMPTY_USER,
  currency: {
    value: CURRENCY.DOLLAR,
    symbol: CURRENCY_SYMBOL.DOLLAR
  },
  LogIn: noop,
  LogOut: noop,
  RefreshToken: noop,
  onLoginSuccess: noop,
  onLoginFailed: noop,
  onLogout: noop,
  onChangeCurrency: noop
})

export function AuthenticationContextProvider({
  children
}: {
  children: ReactNode
}) {
  const [currency, setCurrency] = useState<TCurrency>({
    value: CURRENCY.DOLLAR,
    symbol: CURRENCY_SYMBOL.DOLLAR
  })
  const [isLoading, setLoading] = useState(true)
  const [token, setToken] = useState('')
  const [refreshToken, setRefreshToken] = useState('')
  const [user, setUser] = useState(EMPTY_USER)
  const isAuthenticated = useMemo(() => !!user.email, [user.email])
  const { keycloak, initialized } = useKeycloak()

  // **********************
  // Clear the access token
  // **********************
  const handleClear = useCallback(() => {
    localStorage.removeItem('access_token')
    localStorage.removeItem('refresh_token')
    setToken('')
    setRefreshToken('')
    setUser(EMPTY_USER)
  }, [])

  // **********************
  // Fetch the user profile
  // **********************
  const fetchUserProfile = useCallback(async () => {
    setLoading(true)

    try {
      const { data: userData } = await axios.get<TUser>('/auth/profile')
      setUser(userData)
    } catch (error) {
      apiErrorHandler(error)
      handleClear()
    } finally {
      setLoading(false)
    }
  }, [handleClear])

  // ***********
  // Login
  // ***********
  const LogIn = useCallback(() => {
    setLoading(true)
    handleClear()
    keycloak?.login()
  }, [handleClear, keycloak])

  // ***********
  // Logout
  // ***********
  const LogOut = useCallback(() => {
    setLoading(true)
    keycloak?.logout()
    handleClear()
  }, [handleClear, keycloak])

  // *************
  // Refresh Token
  // *************
  const RefreshToken = useCallback(() => {
    if (keycloak && initialized) {
      keycloak.onTokenExpired = () => keycloak.updateToken(600)
    }
  }, [handleClear, keycloak])

  // *************
  // Login Success
  // *************
  const onLoginSuccess = useCallback(
    (params: { access_token: string; refresh_token: string }) => {
      localStorage.setItem('access_token', params.access_token)
      localStorage.setItem('refresh_token', params.refresh_token)
      setToken(params.access_token)
      setRefreshToken(params.refresh_token)
    },
    []
  )

  // ************
  // Login failed
  // ************
  const onLoginFailed = useCallback(() => {
    setLoading(false)
    handleClear()
    toast.error('Login Failed')
  }, [handleClear])

  // ****************
  // Currency changed
  // ****************
  const onChangeCurrency = useCallback((currencyValue: TCurrency['value']) => {
    setCurrency({
      value: currencyValue,
      symbol: CURRENCY_SYMBOL[currencyValue]
    })
  }, [])

  // ************************************
  // Add the auth token to onLoginSuccess
  // ************************************
  keycloak.onAuthSuccess = () => {
    onLoginSuccess({
      access_token: keycloak.token || '',
      refresh_token: keycloak.refreshToken || ''
    })
  }

  keycloak.onAuthRefreshSuccess = () => {
    localStorage.setItem('access_token', keycloak.token || '')
    localStorage.setItem('refresh_token', keycloak.refreshToken || '')
  }

  // *******************
  // Handle login errors
  // *******************
  keycloak.onAuthError = () => {
    onLoginFailed()
  }

  keycloak.onAuthRefreshError = () => {
    toast.error('Unauthorized')
    localStorage.removeItem('access_token')
    localStorage.removeItem('refresh_token')
    if (window.location.pathname !== '/login') {
      window.location.href = '/login'
    }
    onLoginFailed()
  }

  // *************
  // Memo Handlers
  // *************
  const value = useMemo<IAuthenticationContextState>(() => {
    return {
      isAuthenticated,
      isLoading,
      token,
      refreshToken,
      user,
      currency,
      LogIn,
      LogOut,
      RefreshToken,
      onLoginSuccess,
      onLoginFailed,
      onLogout: handleClear,
      onChangeCurrency
    }
  }, [
    isAuthenticated,
    isLoading,
    token,
    refreshToken,
    user,
    currency,
    LogIn,
    LogOut,
    RefreshToken,
    onLoginSuccess,
    onLoginFailed,
    handleClear,
    onChangeCurrency
  ])

  // **********************
  // Fetch the user profile
  // **********************
  useEffect(() => {
    if (token) fetchUserProfile()
    else setLoading(false)
  }, [token, fetchUserProfile])

  // *****************************
  // Load token from local storage
  // *****************************
  useLayoutEffect(() => {
    const savedToken = localStorage.getItem('access_token') || ''
    if (savedToken) setToken(savedToken)

    const savedRefreshToken = localStorage.getItem('refresh_token') || ''
    if (savedRefreshToken) setRefreshToken(savedRefreshToken)
  }, [fetchUserProfile])

  // *******************
  // Render the provider
  // *******************
  return (
    <AuthenticationContext.Provider value={value}>
      {children}
    </AuthenticationContext.Provider>
  )
}

export default AuthenticationContext
